import React, { useEffect, useRef, useState } from 'react';
import './StickButton.css';
import { twMerge } from 'tailwind-merge';

const StickyButton = ({ children, stickyClasses, actualClasses }) => {
  const [isSticky, setIsSticky] = useState(false);

  const actualButtonRef = useRef(null);

  const handleScroll = () => {
    if (actualButtonRef.current) {
      const { top } = actualButtonRef.current.getBoundingClientRect();
      const scrollPosition = window.scrollY;

      const buttonHeightToViewport =
        window.innerHeight - top - actualButtonRef.current.clientHeight; // sticky botton is 16px from bottom + height of the button

      setIsSticky(buttonHeightToViewport <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      <div
        className={twMerge(
          `fade-background flex w-full justify-center items-center text-center fixed bottom-0 left-1/2 transform -translate-x-1/2 ${
            isSticky ? 'block ' : 'hidden'
          }`,
          stickyClasses
        )}>
        {children}
      </div>

      <span ref={actualButtonRef} className={twMerge('flex justify-center', actualClasses)}>
        {children}
      </span>
    </div>
  );
};

export default StickyButton;
