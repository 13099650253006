import React, { useContext } from 'react';
import { StepperContext } from '../../../../../../../providers/stepper.provider';
import HomeOwnerStep from './HomeOwnerStep';
import AppStoreAndPlayStoreStep from './AppStoreAndPlayStoreStep';

const PropertyValidation = ({ onNext, onPrevious }) => {
  const { isRentedProperty } = useContext(StepperContext);

  return (
    <>
      {!isRentedProperty ? (
        <HomeOwnerStep onNext={onNext} onPrevious={onPrevious} />
      ) : (
        <AppStoreAndPlayStoreStep />
      )}
    </>
  );
};

export default PropertyValidation;
