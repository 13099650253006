import axios from 'axios';

const KLAVIYO_API_KEY = 'YgUT3W';
const KLAVIYO_BASE_URL = 'https://a.klaviyo.com/api';
const privateKey = 'pk_a17b7d68d3107eb0d95f653957a6a757ee';

const klaviyoApi = axios.create({
  baseURL: KLAVIYO_BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  // params: {
  //   api_key: KLAVIYO_API_KEY
  // }
});

const klaviyoApiV2 = axios.create({
  baseURL: 'https://a.klaviyo.com/api',
  headers: {
    revision: '2023-07-15',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Klaviyo-API-Key ${privateKey}`
  }
});

export { klaviyoApi, klaviyoApiV2 };
