import React, { useContext } from 'react';
import InputField from '../../../../../../../components/InputFields/InputField';
import { StepperContext } from '../../../../../../../providers/stepper.provider';
import { LeadContext } from '../../../../../../../providers/lead.provider';

const CustomerNameStep = ({ onNext, onPrevious }) => {
  const { solarInquiryData, setSolarInquiryData } = useContext(StepperContext);
  const { addLeadStep } = useContext(LeadContext);

  return (
    <div className="xl:ml-[130px] sm:ml-[50px] ml-[25px] mr-[25px] h-[60vh]">
      <fieldset>
        <div className="mb-[40px] ">
          <h3 className="sm:text-[32px] text-[24px] leading-[40px] text-primary_black font-forma-djr-text-regular mb-[20px]">
            Who are we preparing this quote for?
          </h3>
          {/* <p className="text-primary text-[18px] sm:text-[24px] leading-1 font-aeonik-light sm:leading-[32px] py-0 mb-[14px] ml-[5px]">
            In just a few quick questions, we&apos;ll help you get energy solutions and upgrade that
            reduce your energy costs and increase the reliability of your system
          </p> */}
          <div className=" py-2 p-1 w-full items-center justify-center text-center">
            <InputField
              type="text"
              placeholder={'Jane'}
              className={{
                inputClasses: 'text-lg',
                labelClasses: 'font-forma-djr-mono-regular text-primary_black'
              }}
              name={'firstName'}
              variants={'static'}
              label={'First name'}
              value={solarInquiryData.firstName}
              onChange={(e) =>
                setSolarInquiryData({
                  ...solarInquiryData,
                  firstName: e.currentTarget.value.trim()
                })
              }
            />
          </div>

          <div className=" py-2 p-1 w-full items-center justify-center text-center">
            <InputField
              type="text"
              placeholder={'Deo'}
              className={{
                inputClasses: 'text-lg',
                labelClasses: 'font-forma-djr-mono-regular text-primary_black'
              }}
              name={'lastName'}
              variants={'static'}
              label={'Last name'}
              value={solarInquiryData.lastName}
              onChange={(e) =>
                setSolarInquiryData({
                  ...solarInquiryData,
                  lastName: e.currentTarget.value
                })
              }
            />
          </div>
        </div>
        <div className="text-left ml-[5px] my-8">
          <button
            className="bg-primary font-forma-djr-text-regular py-3 px-14 text-secondary_white rounded-md text-lg border-primary cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
            disabled={!solarInquiryData.firstName}
            onClick={async () => {
              const leadRes = await addLeadStep(
                {
                  firstName: solarInquiryData.firstName,
                  lastName: solarInquiryData.lastName
                },
                'customerNameStep'
              );
              onNext();
            }}>
            Next
          </button>
        </div>
      </fieldset>
    </div>
  );
};

export default CustomerNameStep;
