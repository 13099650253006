import React, { useContext } from 'react';
import { StepperContext } from '../../../../../../../providers/stepper.provider';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { LeadContext } from '../../../../../../../providers/lead.provider';
import { selectAvgMonthlyCostGTM } from '../../../../../../../utils/googleTagManager';
import { selectAvgMonthlyCostEventPixel } from '../../../../../../../utils/facebookPixelEvents';

const MonthlyElectricCost = ({ onNext, onPrevious }) => {
  const { solarInquiryData, setSolarInquiryData, isProduction } = useContext(StepperContext);
  const { addLeadStep } = useContext(LeadContext);
  return (
    // <div className="sm:ml-[130px] ml-[25px] mr-[25px]  h-[60vh]">
    <div className="xl:ml-[130px] sm:ml-[50px] ml-[25px] mr-[25px] h-[60vh]">
      <fieldset>
        <div className="mb-[40px] ">
          <h3 className="sm:text-[32px] text-[24px] leading-[40px] text-primary_black font-forma-djr-text-regular mb-[20px]">
            What’s your average monthly electricity cost?
          </h3>

          {/* <p className="text-primary text-[14px] text-left justify-start md:text-[18px] lg:text-[24px] lg:leading-[32px] leading-1 mb-[16px]">
            Drag the slider below to your average monthly electricity cost. We&apos;ll use this to
            determine how big of a system you will need for your home.
          </p> */}

          <div className="mt-[58px] w-[65%]">
            <p className="text-primary text-lg lg:text-2xl text-left">
              ${solarInquiryData.monthlyEnergyCost}
            </p>
            <input
              id="monthly-energy-cost"
              style={{
                background: `linear-gradient(to right, #FDAB2B, #FF5900 ${
                  ((parseInt(solarInquiryData.monthlyEnergyCost) - 50) * 100) / (500 - 50)
                }%, #E6DED5 0px`
              }}
              className="appearance-none bg-primary w-full rounded-full h-[10px] [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-[20px] [&::-webkit-slider-thumb]:w-[20px] [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-white [&::-webkit-slider-thumb]:border-solid [&::-webkit-slider-thumb]:border-[6px] [&::-webkit-slider-thumb]:border-primary [&::-webkit-slider-thumb]:cursor-pointer"
              type="range"
              min="50"
              max="500"
              value={solarInquiryData.monthlyEnergyCost}
              onChange={(e) =>
                setSolarInquiryData({
                  ...solarInquiryData,
                  monthlyEnergyCost: e.currentTarget.value
                })
              }
              step="10"
            />

            <ReactTooltip
              anchorSelect="#monthly-energy-cost"
              style={{
                backgroundColor: '#ffffff',
                padding: '10px 24px',
                borderRadius: '25px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
              }}
              effect="solid"
              opacity={1}>
              <div className="text-[11px] leading-[16px] text-primary font-forma-djr-mono-regular ">
                Drag the slider
              </div>
            </ReactTooltip>
            {/* <ul className="flex items-center justify-between">
                          <li className="text-tertiary text-xs lg:text-sm">Monthly energy cost </li>
                        </ul> */}
          </div>
        </div>
        <div className="text-left ml-[5px] my-8">
          <button
            className="bg-primary font-forma-djr-text-regular py-3 px-14 text-secondary_white rounded-md text-lg border-primary cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
            onClick={async () => {
              if (isProduction) {
                await selectAvgMonthlyCostGTM();
                await selectAvgMonthlyCostEventPixel();
              }
              await addLeadStep(
                { monthlyEnergyCost: solarInquiryData.monthlyEnergyCost },
                'monthlyElectricCostStep'
              );
              onNext();
            }}>
            Next
          </button>
        </div>
      </fieldset>
    </div>
  );
};

export default MonthlyElectricCost;
