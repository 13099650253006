import React from 'react';

const Thankyou = () => {
  return (
    <div className="flex flex-row justify-center items-center h-[60vh] text-primary_black text-[32px] leading-[40px]">
      Thanks for submitting!
    </div>
  );
};

export default Thankyou;
