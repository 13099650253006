import React, { useContext } from 'react';
import { StepperContext } from '../../../../../../../providers/stepper.provider';
import AppStoreAndPlayStoreStep from './AppStoreAndPlayStoreStep';
import ServiceNotAvailableStep from './ServiceNotAvailableStep';
import Thankyou from './Thankyou';

const ServiceAndPlayStore = () => {
  const { isBelongsToStateArray, showAppPage } = useContext(StepperContext);

  return <>{!isBelongsToStateArray && showAppPage ? <Thankyou /> : <ServiceNotAvailableStep />}</>;
};

export default ServiceAndPlayStore;
