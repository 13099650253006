import React, { useEffect, useRef, useState } from 'react';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';
import { twMerge } from 'tailwind-merge';

// const options = [
//   { id: 1, value: '1', name: 'Electric Utility-1' },
//   { id: 2, value: '2', name: 'Electric Utility-2' },
//   { id: 3, value: '3', name: 'Electric Utility-3' },
//   { id: 4, value: '4', name: 'Electric Utility-4' },
//   { id: 5, value: '5', name: 'Electric Utility-5' },
//   { id: 6, value: '6', name: 'Electric Utility-6' },
//   { id: 7, value: '7', name: 'Electric Utility-7' },
//   { id: 8, value: '8', name: 'Electric Utility-8' }
// ];

const Dropdown2 = ({
  onChange,
  label = 'Select Option',
  placeholder,
  className,
  variants = 'static',
  onInput,
  defaultSelectedOption,
  options
}) => {
  const varientType = {
    static: {
      lableClasses: `bg-tertiary p-4 w-full flex items-center text-primary justify-between text-center cursor-pointer text-[18px] leading-[16px] h-[50px] border-solid border-[1px] border-primary_black rounded-[4px] font-forma-djr-text-regular`,

      optionsClasses:
        'flex py-4 px-4 cursor-pointer hover:bg-gray-100 w-full border border-1 border-solid border-primary border-b-0 text-primary text-md lg:text-lg h-[50px] items-center',
      optionContainer: `bg-tertiary absolute top-[50px] flex flex-col items-start w-full max-h-[300px] overflow-y-auto border-b-1 border-t-1 border-1 border-solid border-primary  [&>*:last-child]:border-b-1 [&>*:last-child]:border-primary [&>*:last-child]:border-solid [&>*:last-child]:border-1 !z-10`
    },
    standard: {
      lableClasses:
        'peer h-full w-full border-b border-primary bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-primary outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-primary focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50',
      optionsClasses:
        "after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-primary after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-primary peer-focus:after:scale-x-100 peer-focus:after:border-primary peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500",
      optionContainer: `bg-quaternary absolute top-[50px] flex flex-col items-start w-full max-h-[300px] overflow-y-auto border-b-1 border-t-1 border-1 border-solid border-primary  [&>*:last-child]:border-b-1 [&>*:last-child]:border-primary [&>*:last-child]:border-solid [&>*:last-child]:border-1  [&>*:last-child]:border !z-10`
    },
    outlined: {
      lableClasses:
        'peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-primary outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-primary focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50',
      optionsClasses:
        "before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-primary peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-primary peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-primary peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500",
      optionContainer: `bg-quaternary absolute top-[50px] flex flex-col items-start w-full max-h-[300px] overflow-y-auto border-b-1 border-t-1 border-1 border-solid border-primary  [&>*:last-child]:border-b-1 [&>*:last-child]:border-primary [&>*:last-child]:border-solid [&>*:last-child]:border-1  [&>*:last-child]:border !z-10`
    },
    plain: {
      lableClasses:
        'px-4 bg-quaternary w-full text-lg lg:text-xl h-[50px] !text-primary focus:!ring-0  !outline-none placeholder:text-primary placeholder:text-lg lg:placeholder:text-xl  [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
    }
  };

  // console.log(options);
  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption || '');
  const [isOpen, setIsOpen] = useState(false);
  const [visibleItems, setVisibleItems] = useState([]);
  const dropdownRef = useRef(null);

  const handleOptionClick = (selectedOption) => {
    console.log('sdsdsdsdsdee ::', selectedOption);
    setSelectedOption(selectedOption);
    onChange(selectedOption);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleScroll = () => {
    // console.log('ssasas');
    const dropdown = dropdownRef.current;
    // console.log('sssq :', dropdown.scrollHeight);
    if (dropdown.scrollTop + dropdown.clientHeight === dropdown.scrollHeight) {
      // Load more items when scrolled to the bottom
      const nextVisibleItems = options.slice(0, visibleItems.length + 4);
      setVisibleItems(nextVisibleItems);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    // Initialize visible items
    setVisibleItems(options.slice(0, 4));
  }, []);

  return (
    <div className="relative flex flex-col items-center w-full rounded-lg" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        // className="bg-quaternary p-4 w-full flex items-center text-primary justify-between cursor-pointer text-lg lg:text-xl h-[50px] "
        className={twMerge(
          varientType[variants].lableClasses,
          className && className.lableClasses
        )}>
        <span></span>
        {selectedOption.name || label}
        {!isOpen ? <SlArrowDown className="h-3" /> : <SlArrowUp className="h-3" />}
      </button>
      {isOpen && (
        <div
          className={twMerge(
            varientType[variants].optionContainer,
            className && className.optionContainer
          )}
          // onScroll={handleScroll}
        >
          {options.map((item, i) => (
            <div
              key={item.id}
              className={twMerge(
                varientType[variants].optionsClasses,
                className && className.optionsClasses
              )}
              //   className="flex py-4 cursor-pointer hover:bg-gray-100 w-full border border-1 border-solid border-primary border-b-0 text-primary text-md lg:text-lg h-[50px] items-center"
              onClick={() => handleOptionClick(item)}>
              {/* <img className="mx-4 w-[12px] h-[18px]" src={thunderIcon} /> */}
              <h3>{item.name}</h3>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown2;
