import React, { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import './GoogleSunroofMap.scss';

const googleMapApi = process.env.REACT_APP_GOOGLE_MAP_API;

const GoogleSunroofMap = ({ lat, lng }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: googleMapApi,
      version: 'weekly'
    });

    loader.load().then(() => {
      const initMap = async () => {
        const { Map } = await window.google.maps.importLibrary('maps');

        const map = new Map(mapRef.current, {
          center: { lat, lng },
          zoom: 19,
          mapTypeControl: false,
          streetViewControl: false
          //   fullscreenControl: false, // Hide the full-screen button
          //   zoomControl: false // Hide the zoom buttons
          //   draggable: false // Disable map movement
        });

        const layerID = 'sunroof';

        const layer = new window.google.maps.ImageMapType({
          name: layerID,
          getTileUrl: (coords, zoom) =>
            `https://storage.googleapis.com/solar_tiles_all_2018q2/tile_${coords.x}_${coords.y}_${zoom}.png`,
          tileSize: new window.google.maps.Size(256, 256),
          minZoom: 19,
          maxZoom: 20
        });

        map.mapTypes.set(layerID, layer);
        map.setMapTypeId(layerID);

        // eslint-disable-next-line no-new
        new window.google.maps.Marker({
          position: map.center,
          map
        });

        // const captureSunroofImage = () => {
        //   const mapCanvas = mapRef.current.querySelector(
        //     '.gm-style > div:first-child > div:first-child > div'
        //   );

        //   if (mapCanvas) {
        //     const { width, height } = mapCanvas.getBoundingClientRect();
        //     const center = map.getCenter();
        //     const zoom = map.getZoom();

        //     const imageSrc = `https://maps.googleapis.com/maps/api/staticmap?center=${center.lat()},${center.lng()}&zoom=${zoom}&size=${600}x${600}&maptype=satellite`;
        //     console.log('imageSrc :', imageSrc);
        //     // Send the image data to the backend API
        //     // axios
        //     //   .post('/api/sunroof', {
        //     //     image: imageSrc
        //     //   })
        //     //   .then((response) => {
        //     //     // Handle the API response
        //     //     console.log(response.data);
        //     //   })
        //     //   .catch((error) => {
        //     //     // Handle the API error
        //     //     console.error(error);
        //     //   });
        //   }
        // };

        // Wait for a small delay to ensure the map is rendered and available in the DOM
        // setTimeout(captureSunroofImage, 100);
      };

      initMap();
    });
  }, [lat, lng]);

  // return <div ref={mapRef} style={{ width: '100%', height: '500px' }} />;
  return (
    <div className="relative">
      <div ref={mapRef} style={{ width: '100%', height: '486px', borderRadius: '4px' }} />
      <div className="address-legend">
        <div className="legend-gradient" />
        <span className="legend-gradient-label left pt-caption">Shady</span>
        <span className="legend-gradient-label right pt-caption">Sunny</span>
      </div>
    </div>
  );
};

export default GoogleSunroofMap;
