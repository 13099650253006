export const enterAddressEventPixel = (data) => {
  window.fbq('track', 'addressEvent', data);
};

export const selectAvgMonthlyCostEventPixel = () => {
  window.fbq('track', 'avgMonthlyCostEvent');
};

export const enterPropertyTypeEventPixel = (data) => {
  window.fbq('track', 'propertyTypeEvent', data);
};

export const enterPhoneNumberEventPixel = () => {
  window.fbq('track', 'phoneNumberEvent');
};

export const socialLoginEventPixel = (data) => {
  window.fbq('track', 'socialLoginEvent', data);
};

export const enterEmailEventPixel = (data) => {
  window.fbq('track', 'emailEvent', data);
};

export const utilityConnectionEventPixel = () => {
  window.fbq('track', 'utilityConnection');
};

export const skipUtilityConnectionEventPixel = () => {
  window.fbq('track', 'skipUtilityConnection');
};

export const viewsInitialProposalOrSchedulesCallEventPixel = (data) => {
  window.fbq('track', 'viewsInitialProposalOrSchedulesCallEvent', data);
};

export const leadEventPixel = (data) => {
  // fb_integration_tracking
  // The fb_integration_tracking parameter allows you to include additional information with your Facebook Pixel events. You can use this parameter to send custom data along with your lead event, which can be helpful for tracking and optimizing your campaigns. Here's how you can add the fb_integration_tracking parameter to your leadEventPixel
  window.fbq('track', 'Lead', data);
};
