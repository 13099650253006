import React, { useContext, useState, useEffect } from 'react';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { StepperContext } from '../../providers/stepper.provider';

// const publicKey = process.env.REACT_APP_KLAVIYO_PUBLIC_KEY;
const calendlyLink = process.env.REACT_APP_CLENDLY_LINK;

const CalendlyWidget = ({ type, text, className, amount }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setAmountForGTMandPixel } = useContext(StepperContext);

  useEffect(() => {
    setAmountForGTMandPixel(amount);
  }, [amount]);

  return (
    <>
      {type === 'Link' ? (
        <span
          className="dark:text-primary underline underline-offset-1 cursor-pointer font-aeonik-bold"
          onClick={() => setIsOpen(true)}>
          {text}
        </span>
      ) : (
        <button
          className="rounded font-aeonik-bold text-primary text-bold bg-yellow-200 p-2 px-4 my-2 mb-4 hover:opacity-80 text-[16px]"
          onClick={() => setIsOpen(true)}>
          {text}
        </button>
      )}
      <PopupModal
        url={calendlyLink}
        // pageSettings={''}
        // utm={''}
        // prefill={''}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        rootElement={document.getElementById('root')}
      />
    </>
  );
};

export default CalendlyWidget;
