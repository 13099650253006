import React from 'react';
import { twMerge } from 'tailwind-merge';

const InputField = ({
  type = 'text',
  name,
  value,
  onChange,
  label,
  placeholder,
  className,
  variants,
  onInput,
  min,
  max,
  positiveInteger = false
}) => {
  const varientType = {
    static: {
      inputClasses:
        'peer h-full w-full border-b-2 border-primary bg-transparent pt-[16px] pb-[12px] text-[22px] font-normal text-primary leading-[28px] outline outline-0 transition-all placeholder-shown:border-quaternary placeholder:text-quaternary focus:border-primary focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50',
      lableClasses:
        "text-primary after:content[' '] pointer-events-none absolute left-0 -top-[16px] flex h-full w-full select-none text-[16px] font-normal leading-[24px] transition-all after:absolute after:-bottom-[16px] after:block after:w-full after:scale-x-0 after:border-b-2 after:border-primary after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[14px] peer-focus:leading-[24px] peer-focus:text-primary peer-focus:after:scale-x-100 peer-focus:after:border-primary peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
    },
    standard: {
      inputClasses:
        'peer h-full w-full border-b border-primary bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-primary outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-primary focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50',
      lableClasses:
        "after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-primary after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-primary peer-focus:after:scale-x-100 peer-focus:after:border-primary peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
    },
    outlined: {
      inputClasses:
        'peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-primary outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-primary focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50',
      lableClasses:
        "before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-primary peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-primary peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-primary peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
    },
    plain: {
      inputClasses:
        'px-4 bg-quaternary w-full text-lg lg:text-xl h-[50px] !text-primary focus:!ring-0  !outline-none placeholder:text-primary placeholder:text-lg lg:placeholder:text-xl  [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
    }
  };

  const handleInputChange = (event) => {
    let inputValue = event.target.value;

    if (positiveInteger) {
      inputValue = inputValue.replace(/\D/g, '');
      // const intValue = parseInt(inputValue);
      const intValue = inputValue;
      if (!isNaN(intValue) && parseInt(intValue) >= 0) {
        event.target.value = intValue.toString();
      } else {
        event.target.value = '';
      }
    }

    if (max && inputValue.length > max) {
      event.target.value = inputValue.slice(0, max);
    }

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className="relative h-[50px] my-1 w-full min-w-[200px]">
      <input
        id={`id-${name}`}
        name={name}
        value={value}
        type={type}
        onChange={handleInputChange}
        onInput={onInput}
        min={min}
        max={max}
        placeholder={
          variants === 'static' || variants === 'plain' || variants === 'outlined'
            ? placeholder
            : ''
        }
        className={twMerge(varientType[variants].inputClasses, className.inputClasses)}
      />
      {label && variants !== 'plain' && (
        <label className={twMerge(varientType[variants].lableClasses, className.labelClasses)}>
          {label}
        </label>
      )}
    </div>
  );
};

export default InputField;
