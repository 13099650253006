import React, { useContext, useState } from 'react';
import AddressStep from './AddressStep';
import { StepperContext } from '../../../../../../../providers/stepper.provider';
import ServiceAndPlayStore from './ServiceAndPlayStore';

const AddressVerification = ({ onNext, onPrevious }) => {
  //   const [isBelongsToStateArray, setIsBelongsToStateArray] = useState(false);
  const { isBelongsToStateArray } = useContext(StepperContext);
  console.log('Fffff :', isBelongsToStateArray);

  return (
    <>
      {isBelongsToStateArray ? (
        <AddressStep onNext={onNext} onPrevious={onPrevious} />
      ) : (
        <ServiceAndPlayStore />
      )}
    </>
  );
};

export default AddressVerification;
