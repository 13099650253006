import { signOut } from 'firebase/auth';
import { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState('');
  const [isLogin, setIsLogin] = useState(false);
  const [isManualSignUp, setIsManualSignUp] = useState(false);
  const [isFirebasePasswordLessSignInLinkClicked, setIsFirebasePasswordLessSignInLinkClicked] =
    useState(false);
  const [requestTokenResponse, setRequestTokenResponse] = useState('');
  const [ownerId, setOwnerId] = useState('');
  const [urjanetIntegrationId, setUrjanetIntegrationId] = useState('');

  const firebaseLogout = async (auth) => {
    await signOut(auth);
    setUser(null);
    setIsLogin(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isLogin,
        setIsLogin,
        firebaseLogout,
        isManualSignUp,
        setIsManualSignUp,
        setIsFirebasePasswordLessSignInLinkClicked,
        isFirebasePasswordLessSignInLinkClicked,
        requestTokenResponse,
        setRequestTokenResponse,
        ownerId,
        setOwnerId,
        urjanetIntegrationId,
        setUrjanetIntegrationId
      }}>
      {children}
    </AuthContext.Provider>
  );
};
