import React, { useContext } from 'react';
import { LeadContext } from '../../../../../../../providers/lead.provider';

const WelcomeStep = ({ onNext, onPrevious }) => {
  const { leadId, addLeadStep, updateUserLead, generateLeadId } = useContext(LeadContext);

  const generateNewLeadId = async () => {
    try {
      return await generateLeadId();
    } catch (error) {
      console.error('Error generating lead ID:', error);
    }
  };

  const startSolarProposal = async () => {
    if (!leadId) {
      const res = await generateNewLeadId();
      if (res && res.data.createLead && res.data.createLead.lead && res.data.createLead.lead.id) {
        await addLeadStep(
          { type: 'Sounds great Button' },
          'solarProposalStartStep',
          res.data.createLead.lead.id
        );
      }
    } else {
      await addLeadStep({ type: 'Sounds great Button' }, 'solarProposalStartStep');
    }

    onNext();
  };

  return (
    <div className="xl:ml-[130px] sm:ml-[50px] ml-[25px] mr-[25px] h-[60vh]">
      <fieldset>
        <div className="mb-[40px] ">
          <h3 className="sm:text-[32px] text-[24px] leading-[40px] text-primary_black font-forma-djr-text-regular mb-[20px]">
            &#8220; Welcome to Daylight
          </h3>
          <p className="text-primary_black text-[18px] sm:text-[24px] leading-1 font-forma-djr-text-regular sm:leading-[32px] py-0 mb-[14px] ml-[5px]">
            Just answer a few quick questions to see your solar proposal!
          </p>
        </div>
        <div className="text-left ml-[5px] my-8">
          <button
            className="bg-primary font-forma-djr-text-regular py-3 px-14 text-secondary_white rounded-md text-lg border-[#F1D972] cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
            onClick={async () => {
              await startSolarProposal();
            }}>
            Sounds great
          </button>
        </div>
      </fieldset>
    </div>
  );
};

export default WelcomeStep;
