import React, { useContext, useState } from 'react';
import WelcomeStep from '../Steps/WelcomeStep';
import ProgressBar from '../../../../../../../components/ProgressBar/ProgressBar';
import useMultistepForm from '../Hooks/useMultistepForm';
import AddressStep from '../Steps/AddressStep';
import { StepperContext } from '../../../../../../../providers/stepper.provider';
import ServiceNotAvailableStep from '../Steps/ServiceNotAvailableStep';
import AppStoreAndPlayStoreStep from '../Steps/AppStoreAndPlayStoreStep';
import CustomerNameStep from '../Steps/CustomerNameStep';
import HomeOwnerStep from '../Steps/HomeOwnerStep';
import MonthlyElectricCost from '../Steps/MonthlyElectricCost';
import PhoneNumberStep from '../Steps/PhoneNumberStep';
import OtpVerificationStep from '../Steps/OtpVerificationStep';
import EmailStep from '../Steps/EmailStep';

// const INITIAL_DATA = {
//   firstName: '',
//   lastName: '',
//   age: '',
//   street: '',
//   city: '',
//   state: '',
//   zip: '',
//   email: '',
//   password: ''
// };

const StepperForm = ({ children, currentStepData }) => {
  // const [data, setData] = useState(INITIAL_DATA);
  // const { stepperStepsV2 } = useContext(StepperContext);
  // function updateFields(fields) {
  //   setData((prev) => {
  //     return { ...prev, ...fields };
  //   });
  // }
  // const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
  //   useMultistepForm(stepperStepsV2);

  // function onSubmit(e) {
  //   e.preventDefault();
  //   if (!isLastStep) return next();
  //   alert('Successful Account Creation');
  // }

  const { redirectToWebsite } = useContext(StepperContext);

  return (
    <>
      {/* <div className="bg-gray-300 w-1/2">
        StepperForm <span>y</span>
      </div> */}
      {/* overflow-x-hidden overflow-y-auto */}

      <div className="sm:w-1/2 w-full sm:min-h-[100%] min-h-auto h-auto  relative">
        <ProgressBar />
        {/* <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: '25%' }}></div>
        </div> */}
        <div className="flex justify-between item-center mt-[37px] sm:pb-[130px] pb-[25px] mr-[52px] sm:ml-[68px] ml-[15px]">
          {currentStepData.stepName === 'WelcomeStep' ? (
            <span className="font-forma-djr-text-regular py-[15px] px-[25px] text-black rounded-full text-[14px] border-[##F1ECA5] float-left  w-[155px] h-[51px]">
              {`Takes < 1 minute`}
            </span>
          ) : (
            <span></span>
          )}
          <button
            className="text-primary_black text-[20px] float-right hidden sm:block"
            onClick={async () => await redirectToWebsite()}>
            Exit
          </button>
        </div>
        <div>
          {children}
          {/* {step} */}

          {/* <div>
            <fieldset>
              <div className="form-card home-address">
                <h3 className="">What’s your current home address?</h3>
                <p className="text-primary text-[18px] md:text-[24px] lg:text-[32px] leading-1 lg:leading-[40px] py-0 mb-[14px]">
                  <span className="mr-[24px]">1.</span>
                  What’s your current home address?
                </p>
                <input
                  className="form-control"
                  type="text"
                  name="text"
                  placeholder="45 Sunnyside Ave"
                />
              </div>
              <input type="button" name="next" className="next action-button" value="Next" />
            </fieldset>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default StepperForm;
