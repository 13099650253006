import React, { useContext, useRef, useState } from 'react';
import { StepperContext } from '../../../../../../../providers/stepper.provider';
import PhoneInput from 'react-phone-input-2';
import { PhoneNumberContext } from '../../../../../../../providers/phoneNumber.provider';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../../../../../../../firebase.config';
import toast from 'react-hot-toast';
import { APIService } from '../../../../../../../shared/api-client/api.services';
import { CgSpinner } from 'react-icons/cg';
import { enterPhoneNumberEventPixel } from '../../../../../../../utils/facebookPixelEvents';
import { LeadContext } from '../../../../../../../providers/lead.provider';

const WEBSITE_URL = process.env.REACT_APP_WEBSITE_LINK;
const PhoneNumberStep = () => {
  // const [phoneNumber, setPhoneNumber] = useState('');
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState(false);
  const { solarInquiryData, setSolarInquiryData, isProduction } = useContext(StepperContext);
  const {
    setIsOtpSent,
    setResendDisabled,
    startTimer,
    setSendOTPCounter,
    sendOTPCounter,
    setPhoneNumber,
    phoneNumber,
    recaptchaContainerRef,
    onSignup,
    loading,
    setLoading
  } = useContext(PhoneNumberContext);
  const { addLeadStep } = useContext(LeadContext);

  // const recaptchaContainerRef = useRef(null);

  const handlePhoneNumberChange = (value) => {
    const inputValue = value;
    const formattedPhoneNumber = inputValue.startsWith('1') ? inputValue : `1${inputValue}`;
    const formattedPhoneNumberWithPlus = `+` + formattedPhoneNumber;
    // console.log('formattedPhoneNumber :', formattedPhoneNumber);
    // console.log('formattedPhoneNumberWithPlus :', formattedPhoneNumberWithPlus);
    setPhoneNumber(formattedPhoneNumberWithPlus);
    setSolarInquiryData({ ...solarInquiryData, phoneNumber: formattedPhoneNumberWithPlus });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Backspace' && phoneNumber === '1') {
      // event.preventDefault();
    }
  };

  const createLog = async (logData) => {
    try {
      const created = await APIService.LOGGER.POST(logData);
      if (created) {
        console.log('created :', created);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const destroyRecaptchaVerifier = () => {
  //   // Create a new empty container for Recaptcha
  //   const newRecaptchaContainer = document.createElement('div');

  //   // Check if recaptchaContainerRef.current has a parent node before replacing it
  //   if (recaptchaContainerRef.current.parentNode) {
  //     recaptchaContainerRef.current.parentNode.insertBefore(
  //       newRecaptchaContainer,
  //       recaptchaContainerRef.current
  //     );
  //     recaptchaContainerRef.current.parentNode.removeChild(recaptchaContainerRef.current);
  //     recaptchaContainerRef.current = newRecaptchaContainer;
  //   }
  // };

  // const onSignup = async () => {
  //   if (phoneNumber.length >= 10) {
  //     setLoading(true);
  //     //   destroyRecaptchaVerifier();
  //     //   let appVerifier = recaptchaVerifier.current;
  //     const appVerifier = new RecaptchaVerifier(
  //       recaptchaContainerRef.current,
  //       {
  //         size: 'invisible',
  //         callback: (response) => {
  //           // reCAPTCHA verification success callback
  //           // console.log('reCAPTCHA verification success');
  //         },
  //         'expired-callback': () => {
  //           // reCAPTCHA verification expired callback
  //           console.log('reCAPTCHA verification expired');
  //         }
  //       },
  //       auth
  //     );

  //     console.log(phoneNumber);
  //     // const formatPh = '+' + phoneNumber;
  //     const formatPh = phoneNumber;

  //     signInWithPhoneNumber(auth, formatPh, appVerifier)
  //       .then((confirmationResult) => {
  //         window.confirmationResult = confirmationResult;
  //         // setVerificationId(confirmationResult.verificationId);
  //         setLoading(false);
  //         setIsOtpSent(true);
  //         appVerifier.clear();
  //         // toast.success("OTP sended successfully!");
  //         console.log('OTP sended successfully!');
  //         setSendOTPCounter(sendOTPCounter + 1);
  //         setResendDisabled(true);
  //         startTimer();
  //       })
  //       .catch(async (error) => {
  //         console.log('error', error.message);

  //         const logData = {
  //           url: 'https://firebase.com',
  //           method: 'GET',
  //           headers: [],
  //           payload: { phoneNumber: formatPh },
  //           response_status: 400,
  //           response_headers: '[]',
  //           response_body: error && error.message ? error.message : '',
  //           client: 'Web',
  //           client_os: navigator.platform ? navigator.platform : 'browser'
  //         };
  //         createLog(logData);

  //         setLoading(false);
  //         await destroyRecaptchaVerifier();
  //         // recaptchaVerifier.current.render();

  //         switch (error.code) {
  //           case 'auth/quota-exceeded':
  //             toast.error('Exceeded Quota');
  //             // navigate(0);
  //             break;
  //           case 'auth/captcha-check-failed':
  //             toast.error('Captcha check failed');
  //             break;
  //           case 'auth/timeout':
  //             toast.error('TimeOut');
  //             break;
  //           case 'auth/internal-error':
  //             toast.error('Internal Error');
  //             // navigate(0);
  //             break;
  //           case 'auth/invalid-phone-number':
  //             toast.error('Invalid Phone Number');
  //             break;
  //           default:
  //         }
  //       });
  //   }
  // };

  return (
    // <div className="sm:ml-[130px] ml-[25px] mr-[25px] h-[60vh]">
    <div className="xl:ml-[130px] sm:ml-[50px] ml-[25px] mr-[25px] h-[60vh]">
      <fieldset>
        <div className="mb-[40px] ">
          <h3 className="sm:text-[32px] text-[24px] leading-[40px] text-primary_black font-forma-djr-text-regular mb-[20px]">
            What’s your phone number?
          </h3>

          <p className="text-primary_black text-[14px] text-left justify-start md:text-[18px] lg:text-[24px] lg:leading-[32px] leading-1 mb-[16px]">
            We will text you a one-time code to log in to your account.
          </p>

          <div className="mt-8 p-2  w-full items-center justify-center text-center">
            <div>
              <PhoneInput
                country={'us'}
                onlyCountries={['us']}
                preserveOrder={['onlyCountries', 'preferredCountries']}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter phone number"
                disableDropdown
                autoFormat
                className="peer my-0 [&>.flag-dropdown]:!hidden [&>input]:!pl-0 [&>input]:!bg-transparent [&>input]:py-0 h-full w-full border-b-2 [&>input]:!w-full [&>input]:!text-lg [&>input]:lg:text-xl [&>input]:!h-[50px] [&>input]:!text-primary  border-primary bg-transparent text-[22px] leading-[28px] font-normal text-primary outline outline-0 transition-all [&>input]:focus:!ring-0 [&>input]:!outline-none [&>input]:placeholder-shown:!border-quaternary [&>input]:placeholder:!text-quaternary focus:border-primary focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 [&>input]:placeholder:!text-lg [&>input]:lg:placeholder:!text-xl [&>input]:[appearance:textField] [&>input]:[&::-webkit-outer-spin-button]:appearance-none [&>input]:[&::-webkit-inner-spin-button]:appearance-none [&>input]:!border-none [&>input]:!rounded-none [&>div]:!border-none [&>div]:!rounded-none [&>div]:!bg-quaternary"
                //   className="phone-number my-0 [&>input]:!bg-quaternary [&>input]:!w-full [&>input]:!text-lg [&>input]:lg:text-xl [&>input]:!h-[50px] [&>input]:!text-primary [&>input]:focus:!ring-0 [&>input]:!outline-none [&>input]:placeholder:!text-primary [&>input]:placeholder:text-lg [&>input]:lg:placeholder:text-xl [&>input]:[appearance:textField] [&>input]:[&::-webkit-outer-spin-button]:appearance-none [&>input]:[&::-webkit-inner-spin-button]:appearance-none [&>input]:!border-none [&>input]:!rounded-none [&>div]:!border-none [&>div]:!rounded-none [&>div]:!bg-quaternary"
              />
            </div>

            <div className="flex items-center mr-4 mt-8">
              <input
                checked={isTermsAndConditionsChecked}
                id="terms-checkbox"
                type="checkbox"
                value="terms"
                onChange={(e) => {
                  // console.log('e :', e.currentTarget.checked);
                  setIsTermsAndConditionsChecked(e.currentTarget.checked);
                }}
                className="sm:!w-[30px] !w-[25px] sm:!h-[30px] !h-[25px] sm:scale-100 scale-150 !text-primary !accent-primary bg-gray-100 border-gray-300 rounded !focus:shadow-none"
              />

              <label
                htmlFor="terms-checkbox"
                className="ml-2 sm:text-[12px] text-[11px] leading-[16px] tracking-[0.1px] text-primary_black checkbox-container text-left font-forma-djr-mono-regular">
                By signing up, you agree to receive communication from Daylight Energy for
                reminders, promotions, alerts, or company updates. Message frequency varies.
                Messages and data rates may apply. Reply STOP to cancel. View our{' '}
                <a
                  href={WEBSITE_URL + 'legal/terms/'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cursor-pointer">
                  <u className="font-forma-djr-mono-regular"> Terms & Conditions</u>
                </a>{' '}
                and{' '}
                <a
                  href={WEBSITE_URL + 'legal/privacy/'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cursor-pointer">
                  <u className="font-forma-djr-mono-regular"> Privacy Policy</u>
                </a>
              </label>
            </div>
          </div>
        </div>
        <div className="text-left ml-[5px] my-8">
          <button
            className="flex items-center bg-primary font-forma-djr-text-regular py-3 px-14 text-secondary_white rounded-md text-lg border-primary cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
            disabled={!(phoneNumber.length === 12 && isTermsAndConditionsChecked)}
            onClick={async () => {
              if (isProduction) {
                await enterPhoneNumberEventPixel();
              }
              await addLeadStep({ phoneNumber }, 'phoneNumberStep');

              onSignup();
              // setIsOtpSent(true);
            }}>
            {loading && <CgSpinner size={20} className="animate-spin mr-3 items-center" />}
            Next
          </button>
        </div>
      </fieldset>
    </div>
  );
};

export default PhoneNumberStep;
