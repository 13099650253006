import React from 'react';
import DayLightLogo from '../../../../../../../resources/images/DayLightLogo';
import SolarPanelInstallation from '../../../../../../../resources/images/SolarPanelInstallation';

const StepperDiscription = ({ content, image, source }) => {
  return (
    <div className="bg-tertiary sm:w-1/2 w-full sm:min-h-[100%] min-h-auto h-auto">
      <div className="sm:mx-[61px] mx-[20px] sm:my-[53px] my-[20px]">
        <div className="sm:[&>svg]:w-auto [&>svg]:w-[150px] h-auto">
          <DayLightLogo />
        </div>

        <div className="sm:mt-[150px] mt-[20px] flex flex-col justify-center items-center text-center">
          <h6 className="text-[16px] font-forma-djr-text-regular leading-[24px] mb-[15px]">
            Did you know:
          </h6>
          {content && (
            <p className="sm:text-[24px] text-[18px] font-forma-djr-text-regular sm:leading-[32px] leading-[26px] py-2">
              {content}
            </p>
          )}
        </div>

        {source && (
          <div className="mt-[5px] flex flex-col justify-center items-center text-center">
            <h6 className="flex justify-center items-center text-[16px] font-aeonik-regular leading-[24px] mb-[15px]">
              Source:{'  '}
              <p className="text-[16px] font-forma-djr-text-regular leading-[24px] py-2 px-[5px]">
                {source}
              </p>
            </h6>
          </div>
        )}

        {/* {image && (
          <div className=" sm:pt-[20px] pt-[10px] sm:pb-[10px] pb-[10px] m-auto xl:[&>svg]:w-[75%] lg:[&>svg]:w-[100%] [&>svg]:mx-auto [&>svg]:h-auto [&>svg]:w-[220px] h-auto">
            {image}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default StepperDiscription;
