import { useLazyQuery } from '@apollo/client';
import { createContext, useEffect, useState } from 'react';
import { SOLAR_BOOKING_AMOUNT_QUERY } from '../GraphQL/Queries';

export const StripeContext = createContext();

export const StripeProvider = ({ children }) => {
  const [bookingAmount, setBookingAmount] = useState({
    cash: '99',
    finance: '99'
  });
  const [
    solarBookingAmountQuery,
    { loading: solarBookingAmountQueryLoading, data: solarBookingAmountQueryData }
  ] = useLazyQuery(SOLAR_BOOKING_AMOUNT_QUERY);

  useEffect(() => {
    const fetchSolarBookingAmount = async () => {
      try {
        const { data } = await solarBookingAmountQuery();
        console.log('data :', data);
        if (data) {
          setBookingAmount({
            cash: data.solarBookingAmount.cash,
            finance: data.solarBookingAmount.finance
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchSolarBookingAmount();
  }, []);
  console.log('bookingAmount :', bookingAmount);
  return (
    <StripeContext.Provider
      value={{
        bookingAmount,
        setBookingAmount
      }}>
      {children}
    </StripeContext.Provider>
  );
};
