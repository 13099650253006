import React, { useContext, useEffect, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { StepperContext } from '../../../../../../../providers/stepper.provider';
import { useLazyQuery } from '@apollo/client';
import { UTILITY } from '../../../../../../../GraphQL/Queries';
import { useLocation } from 'react-router-dom';
import { enterAddressGTM } from '../../../../../../../utils/googleTagManager';
import { enterAddressEventPixel } from '../../../../../../../utils/facebookPixelEvents';
import { LeadContext } from '../../../../../../../providers/lead.provider';
import { encryptionAES } from '../../../../../../../utils/utils';

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API;
const ACTIVE_STATES = ['NJ', 'FL', 'PA', 'NY', 'TX'];

const AddressStep = ({ onNext, onPrevious }) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const stateData = JSON.parse(urlParams.get('state'));

  // console.log('stateData :', stateData);
  // const stateData1 = {
  //   solarInquiryData: {
  //     formatedAddress: '1600 Edgmont Ave, Chester, PA 19013, USA',
  //     address: '1600 Edgmont Avenue',
  //     city: 'Chester',
  //     state: 'PA',
  //     zipCode: '19013',
  //     addressLatLng: {
  //       lat: 39.8604384,
  //       lng: -75.36365599999999
  //     },
  //     electricityCost: 0.14,
  //     phoneNumber: '+19898989898'
  //   },
  //   is_redirected_from_app: true
  // };

  // const baseUrl = 'http://localhost:3001/';
  // const stateParam = encodeURIComponent(JSON.stringify(stateData1));
  // const redirectUrl = `${baseUrl}?state=${stateParam}`;
  // console.log('redirectUrl :', redirectUrl);

  // const enc = encodeURIComponent(encryptionAES(JSON.stringify(stateData1)));

  // const redirectUrl1 = `${baseUrl}?state=${enc}`;

  // console.log('encrypted :', redirectUrl1);

  const [autoCompleteInput, setAutoCompleteInput] = useState(
    stateData && stateData.solarInquiryData.formatedAddress
      ? stateData.solarInquiryData.formatedAddress
      : ''
  );

  const [address, setAddress] = useState(
    stateData && stateData.solarInquiryData.formatedAddress
      ? stateData.solarInquiryData.formatedAddress
      : ''
  );

  const { solarInquiryData, setSolarInquiryData, isProduction, setIsBelongsToStateArray } =
    useContext(StepperContext);
  const { leadId, addLeadStep, updateUserLead, generateLeadId } = useContext(LeadContext);

  const [fetchUtility, { loading: utilityLoading, data: utilityData }] = useLazyQuery(UTILITY);

  const fetchUtilityData = async (state, zipcode) => {
    try {
      const utilityRes = await fetchUtility({
        variables: {
          state,
          zipCode: ''
        }
      });

      return utilityRes;
    } catch (error) {
      console.log(error);
    }
  };

  const googleAutoCompletePlaceSelected = async (place) => {
    if (place && place.address_components) {
      const addressComponents = place.address_components;
      const formattedAddress = place.formatted_address;
      const { geometry } = place;
      const lat = geometry.location.lat();
      const lng = geometry.location.lng();

      let address = '';
      let city = '';
      let state = '';
      let zipCode = '';

      // Extract the required address components
      for (let i = 0; i < addressComponents.length; i++) {
        const types = addressComponents[i].types;
        if (types.includes('street_number')) {
          address += addressComponents[i].long_name + ' ';
        } else if (types.includes('route')) {
          address += addressComponents[i].long_name;
        } else if (types.includes('locality')) {
          city = addressComponents[i].long_name;
          //   console.log('city :', city);
        } else if (types.includes('administrative_area_level_1')) {
          state = addressComponents[i].short_name;
          //   console.log('state :', state);
          // state = addressComponents[i].long_name;
        } else if (types.includes('postal_code')) {
          zipCode = addressComponents[i].long_name;
        }
      }

      setSolarInquiryData({
        ...solarInquiryData,
        address,
        city,
        state,
        zipCode,
        formatedAddress: place.formatted_address,
        addressLatLng: { lat, lng }
      });

      const utilityRes = await fetchUtilityData(state.toUpperCase(), '');

      if (utilityRes) {
        setSolarInquiryData({
          ...solarInquiryData,
          address,
          city,
          state,
          zipCode,
          formatedAddress: place.formatted_address,
          addressLatLng: { lat, lng },
          electricityCost: parseFloat(utilityRes.data.utility[0].avgResidentialRate)
          // electricityCost: 0.2122
        });
      }

      setAddress(place.formatted_address);
      setAutoCompleteInput(place.formatted_address);
    }
  };

  const handleAutoCompleteValue = (value) => {
    setAutoCompleteInput(value);
  };

  const checkStateIsBlongsTOActiveStateArray = () => {
    return ACTIVE_STATES.includes(solarInquiryData.state);
  };

  useEffect(() => {
    console.log('solarInquiryData :', solarInquiryData);
  }, [solarInquiryData]);

  return (
    <div className="xl:ml-[130px] sm:ml-[50px] ml-[25px] mr-[25px] h-[60vh]">
      <fieldset>
        <div className="mb-[40px] ">
          <h3 className="sm:text-[32px] text-[24px] leading-[40px] text-primary_black font-forma-djr-text-regular mb-[20px]">
            What’s your current home address?
          </h3>

          <Autocomplete
            apiKey={GOOGLE_MAP_API_KEY}
            onPlaceSelected={(place) => googleAutoCompletePlaceSelected(place)}
            options={{ types: ['geocode'], componentRestrictions: { country: 'us' } }}
            // className="text-center bg-quaternary w-full px-1 text-md lg:text-2xl h-[50px] !text-primary focus:!ring-0  !outline-none placeholder:text-primary placeholder:text-center placeholder:text-lg lg:placeholder:text-2xl  [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            className="peer h-full w-full border-b-2 border-primary bg-transparent pt-[16px] pb-[12px] text-[22px] leading-[28px] font-normal text-primary outline outline-0 transition-all placeholder-shown:border-tertiary placeholder:text-tertiary focus:border-primary focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
            placeholder="45 Sunnyside Ave"
            defaultValue={solarInquiryData.formatedAddress}
            onChange={(e) => handleAutoCompleteValue(e.currentTarget.value)}
          />
        </div>
        <div className="text-left ml-[5px] my-8">
          <button
            className="bg-primary font-aeonik-bold py-3 px-14 text-secondary_white rounded-md text-lg border-primary cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
            disabled={!(address && address === autoCompleteInput)}
            onClick={async () => {
              if (isProduction) {
                await enterAddressGTM();
                await enterAddressEventPixel({ address: autoCompleteInput });
              }
              await addLeadStep(
                { formatedAddress: autoCompleteInput, address: solarInquiryData.address },
                'addressStep'
              );

              if (await checkStateIsBlongsTOActiveStateArray()) {
                // handleStepDown();
                onNext();
              } else {
                setIsBelongsToStateArray(false);
                // animate(scope.current, { y: '50%', opacity: 0 }).then(() => {
                //   setShowExpandingPage(true);
                // });
              }
            }}>
            Next
          </button>
        </div>
      </fieldset>
    </div>
  );
};

export default AddressStep;
