import React from 'react';

const StatusBar = () => {
  return (
    <div className="bg-tertiary px-[8px]">
      <ul className="text-[#8B8B8B] flex flex-row justify-between items-center max-w-[542px] mx-auto py-[12px] border-[1px] border-solid border-[#F1F1F1] ">
        <li>
          <p className="text-[11px] font-forma-djr-text-bold uppercase leading-[16px] tracking-[0.1px] text-center text-primary_black">
            step 1
            <br />
            <span className="text-[12px] font-forma-djr-text-regular leading-[16px] tracking-[0.1px] text-center text-primary_black">
              Survey
            </span>
          </p>
        </li>
        <li>
          <p className="text-[11px] font-forma-djr-text-bold uppercase leading-[16px] tracking-[0.1px] text-center text-primary_black">
            step 2
            <br />
            <span className="text-[12px] font-forma-djr-text-bold text-primary_black leading-[16px] tracking-[0.1px] text-center ">
              Summary
            </span>
          </p>
        </li>
        <li>
          <p className="text-[11px] font-forma-djr-text-bold uppercase leading-[16px] tracking-[0.1px] text-center text-primary_black">
            step 3
            <br />
            <span className="text-[12px] font-forma-djr-text-regular leading-[16px] tracking-[0.1px] text-center text-primary_black">
              Payment
            </span>
          </p>
        </li>
      </ul>
    </div>
  );
};

export default StatusBar;
