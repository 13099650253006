import React, { useContext } from 'react';
import PhoneNumberStep from './PhoneNumberStep';
import OtpVerificationStep from './OtpVerificationStep';
import { PhoneNumberContext } from '../../../../../../../providers/phoneNumber.provider';

const PhoneNumberValidation = ({ onNext, onPrevious }) => {
  const { isOtpSent, isOtpVerified, recaptchaContainerRef } = useContext(PhoneNumberContext);

  return (
    <>
      {isOtpSent && !isOtpVerified ? (
        <OtpVerificationStep onNext={onNext} onPrevious={onPrevious} />
      ) : (
        <PhoneNumberStep onNext={onNext} onPrevious={onPrevious} />
      )}
      <div id="recaptcha-container" ref={recaptchaContainerRef}></div>
    </>
  );
};

export default PhoneNumberValidation;
