import React, { useContext, useState } from 'react';
import StepperDiscription from './Components/StepperDiscription';
import StepperForm from './Components/StepperForm';
import WelcomeStep from './Steps/WelcomeStep';
import AddressStep from './Steps/AddressStep';
import ServiceNotAvailableStep from './Steps/ServiceNotAvailableStep';
import AppStoreAndPlayStoreStep from './Steps/AppStoreAndPlayStoreStep';
import CustomerNameStep from './Steps/CustomerNameStep';
import HomeOwnerStep from './Steps/HomeOwnerStep';
import MonthlyElectricCost from './Steps/MonthlyElectricCost';
import PhoneNumberStep from './Steps/PhoneNumberStep';
import OtpVerificationStep from './Steps/OtpVerificationStep';
import EmailStep from './Steps/EmailStep';
import SolarPanelInstallation from '../../../../../../resources/images/SolarPanelInstallation';
import StickyButton from '../../../../../../components/StickyButton/StickyButton';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import AddressVerification from './Steps/AddressVerification';
import { StepperContext } from '../../../../../../providers/stepper.provider';
import { PhoneNumberContext } from '../../../../../../providers/phoneNumber.provider';
import { PropertyType } from '../../../../../../GraphQL/Types';
import WomenCombat from '../../../../../../resources/images/WomenCombat';

const Layout = () => {
  const {
    currentStepName,
    setCurrentStepName,
    // Steps,
    steps,
    isBelongsToStateArray,
    setIsBelongsToStateArray,
    showAppPage,
    setShowAppPage,
    setIsRentedProperty,
    isRentedProperty,
    solarInquiryData,
    setSolarInquiryData
  } = useContext(StepperContext);
  const { isOtpSent, setIsOtpSent } = useContext(PhoneNumberContext);

  const handleNextStep = () => {
    const currentIndex = steps.findIndex((step) => step.stepName === currentStepName);
    if (currentIndex < steps.length - 1) {
      setCurrentStepName(steps[currentIndex + 1].stepName);
    }
  };

  const handlePreviousStep = () => {
    const currentIndex = steps.findIndex((step) => step.stepName === currentStepName);
    // console.log('currentStepName :', currentStepName);
    if (currentStepName === 'AddressVerification' && !isBelongsToStateArray) {
      if (!isBelongsToStateArray && showAppPage) {
        setShowAppPage(false);
      } else {
        setIsBelongsToStateArray(true);
      }
    } else if (currentStepName === 'PhoneNumberValidation' && isOtpSent) {
      if (isOtpSent) {
        setIsOtpSent(false);
      }
    } else if (currentStepName === 'PropertyValidation' && isRentedProperty) {
      if (isRentedProperty) {
        setSolarInquiryData({
          ...solarInquiryData,
          onHome: PropertyType.Own
        });
        setIsRentedProperty(false);
      }
    } else {
      if (currentIndex > 0) {
        setCurrentStepName(steps[currentIndex - 1].stepName);
      }
    }
  };

  const playStoreAndAppStoreContent = {
    content: `We’re excited to help make your home cleaner, healthier, and more comfortable!`,
    sourceText: null,
    image: <WomenCombat />
  };

  const currentStepData = steps.find((step) => step.stepName === currentStepName);

  return (
    <div className="flex sm:flex-row flex-col justify-evenly items-start sm:h-screen h-auto">
      <StepperDiscription
        content={isRentedProperty ? playStoreAndAppStoreContent.content : currentStepData.content}
        image={isRentedProperty ? playStoreAndAppStoreContent.image : currentStepData.image}
        source={
          isRentedProperty ? playStoreAndAppStoreContent.sourceText : currentStepData.sourceText
        }
      />
      <StepperForm currentStepData={currentStepData}>
        {React.createElement(currentStepData.component, {
          onNext: handleNextStep,
          onPrevious: handlePreviousStep
        })}
        {/* <div>
          <button onClick={handlePreviousStep} disabled={currentStepName === 'WelcomeStep'}>
            Previous
          </button>
          <button onClick={handleNextStep} disabled={currentStepName === 'ServiceNotAvailableStep'}>
            Next
          </button>
        </div> */}
        <div>
          {currentStepName !== 'WelcomeStep' && (
            <StickyButton
              actualClasses={'text-right justify-end mr-[46px] mb-[10px]'}
              stickyClasses={`bottom-[10px] right-[-50px] left-auto w-[92px] !bg-none`}>
              <div className="flex gap-2">
                <button
                  className="bg-secondary_white font-forma-djr-text-regular py-3 px-3 text-primary rounded-[2px] text-lg border-primary border-solid border-[2px] cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
                  // onClick={handleStepUp} // Handle step up
                  // disabled={!isStepperStart && activeStep === 0}
                  onClick={handlePreviousStep}>
                  <BsArrowUp style={{ fontWeight: 400, strokeWidth: '1px' }} />
                </button>
                {/* <button
                className="bg-[#F1D972] font-forma-djr-text-regular py-3 px-3 text-primary rounded-[2px] text-lg border-[#F1D972] cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
                // onClick={handleStepDown} // Handle step up
                // disabled={!isNextAllow || activeStep === multiStapperSteps.length - 1}
                onClick={handleNextStep}>
                <BsArrowDown />
              </button> */}
              </div>
            </StickyButton>
          )}
        </div>
      </StepperForm>
      {/* <StepperForm>
        <WelcomeStep />
        <AddressStep />
        <ServiceNotAvailableStep />
        <AppStoreAndPlayStoreStep />
        <CustomerNameStep />
        <HomeOwnerStep />
        <MonthlyElectricCost />
        <PhoneNumberStep />
        <OtpVerificationStep />
        <EmailStep />
      </StepperForm> */}
    </div>
  );
};

export default Layout;
