import React, { useState } from 'react';

const Counter = ({ defaultValue = 0, minValue = 0, maxValue = Infinity, onChange }) => {
  const [counter, setCounter] = useState(defaultValue);

  const incrementCounter = () => {
    if (counter < maxValue) {
      setCounter((prevCounter) => {
        const newCounter = prevCounter + 1;
        if (newCounter <= maxValue) {
          if (onChange) {
            onChange(newCounter);
          }
          return newCounter;
        }
        return prevCounter;
      });
    }
  };

  const decrementCounter = () => {
    if (counter > minValue) {
      setCounter((prevCounter) => {
        const newCounter = prevCounter - 1;
        if (newCounter >= minValue) {
          if (onChange) {
            onChange(newCounter);
          }
          return newCounter;
        }
        return prevCounter;
      });
    }
  };
  return (
    <div className="flex flex-row justify-center items-center">
      <button
        className="border-[1px] rounded-full border-solid border-primary bg-tertiary w-8 h-8 p-auto disabled:opacity-75 cursor-pointer  disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
        onClick={decrementCounter}
        disabled={counter <= minValue}>
        -
      </button>
      <span className="mx-2">{counter}</span>
      <button
        className="border-[1px] rounded-full border-solid border-primary bg-tertiary w-8 h-8 p-auto disabled:opacity-75 cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
        disabled={counter >= maxValue}
        onClick={incrementCounter}>
        +
      </button>
    </div>
  );
};

export default Counter;
