// import TagManager from 'react-gtm-module';

export const accountCreatedGTM = async () => {
  //   const eventDataGTM = {
  //     event: 'conversion',
  //     send_to: 'AW-11258238281/4zIrCOzyv8YYEMmqrPgp'
  //   };
  //   TagManager.dataLayer({ dataLayer: eventDataGTM });
  window.gtag('event', 'conversion', {
    send_to: 'AW-11258238281/4zIrCOzyv8YYEMmqrPgp'
  });
};

export const enterAddressGTM = async () => {
  //   const eventDataGTM = {
  //     event: 'conversion',
  //     send_to: 'AW-11258238281/xfkECO_ppsYYEMmqrPgp'
  //   };

  //   TagManager.dataLayer({ dataLayer: eventDataGTM });

  window.gtag('event', 'conversion', {
    send_to: 'AW-11258238281/xfkECO_ppsYYEMmqrPgp'
  });
};

export const selectAvgMonthlyCostGTM = async () => {
  //   const eventDataGTM = {
  //     event: 'conversion',
  //     send_to: 'AW-11258238281/SWd8CKfsqcYYEMmqrPgp'
  //   };

  //   TagManager.dataLayer({ dataLayer: eventDataGTM });
  window.gtag('event', 'conversion', {
    send_to: 'AW-11258238281/SWd8CKfsqcYYEMmqrPgp'
  });
  // window.gtag('set', {
  //   userData: solarInquiryData
  // });
};

export const utilityConnectionGTM = async () => {
  //   const eventDataGTM = {
  //     event: 'conversion',
  //     send_to: 'AW-11258238281/cfQjCLLl1cYYEMmqrPgp'
  //   };

  //   TagManager.dataLayer({ dataLayer: eventDataGTM });
  window.gtag('event', 'conversion', {
    send_to: 'AW-11258238281/cfQjCLLl1cYYEMmqrPgp'
  });
};

export const viewsInitialProposalOrSchedulesCallGTM = async (amount) => {
  //   const eventDataGTM = {
  //     event: 'conversion',
  //     send_to: 'AW-11258238281/ZiAsCN_SycYYEMmqrPgp',
  //     value: amount,
  //     currency: 'USD'
  //   };
  //   TagManager.dataLayer({ dataLayer: eventDataGTM });
  window.gtag('event', 'conversion', {
    send_to: 'AW-11258238281/ZiAsCN_SycYYEMmqrPgp',
    value: amount,
    currency: 'USD'
  });
};
