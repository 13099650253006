/* eslint-disable no-prototype-builtins */
import { useLazyQuery, useQuery } from '@apollo/client';
import { signOut } from 'firebase/auth';
import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SOLAR_CONFIGURATION, UTILITY } from '../GraphQL/Queries';
import { PropertyType } from '../GraphQL/Types';
import WelcomeStep from '../pages/Home/Components/SolarFlowV3/Components/Layout/Steps/WelcomeStep';
import AddressStep from '../pages/Home/Components/SolarFlowV3/Components/Layout/Steps/AddressStep';
import SolarPanelInstallation from '../resources/images/SolarPanelInstallation';
import AddressVerification from '../pages/Home/Components/SolarFlowV3/Components/Layout/Steps/AddressVerification';
import CustomerNameStep from '../pages/Home/Components/SolarFlowV3/Components/Layout/Steps/CustomerNameStep';
import HomeOwnerStep from '../pages/Home/Components/SolarFlowV3/Components/Layout/Steps/HomeOwnerStep';
import MonthlyElectricCost from '../pages/Home/Components/SolarFlowV3/Components/Layout/Steps/MonthlyElectricCost';
import PhoneNumberStep from '../pages/Home/Components/SolarFlowV3/Components/Layout/Steps/PhoneNumberStep';
import EmailStep from '../pages/Home/Components/SolarFlowV3/Components/Layout/Steps/EmailStep';
import WomenCombat from '../resources/images/WomenCombat';
import WomanWithLaptop from '../resources/images/WomanWithLaptop';
import SolarHome from '../resources/images/SolarHome';
import FreeTime from '../resources/images/FreeTime';
import BusyMan from '../resources/images/BusyMan';
import PropertyValidation from '../pages/Home/Components/SolarFlowV3/Components/Layout/Steps/PropertyValidation';
import PhoneNumberValidation from '../pages/Home/Components/SolarFlowV3/Components/Layout/Steps/PhoneNumberValidation';

export const StepperContext = createContext();

const defaultSolarInquiryData = {
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  email: '',
  electricityCost: '',
  energyBillSaving: 1.4,
  monthlyEnergyCost: 50,
  earnCashbackUpTo: '',
  treesPlanted: '',
  wattCost: '',
  // savingOver25Years: '',
  estSolarSystemSizekW: '',
  sizeSmoothing: '',
  systemPrice: '',
  fedralITCAmount: '',
  netSolarSystemPrice: '',
  batteryQuantity: 0,
  sunroofImageId: '',
  formatedAddress: '',
  addressLatLng: '',
  phoneNumber: '',
  progress: '',
  onHome: PropertyType.Own,
  heatPump: false,
  energyEfficiency: false
};

const additionalDetails = {
  batteryBrand: 'FranlkinWH 13.6kWh',
  futureAddOns: {
    type: 'Phono 400 watt',
    discription: 'Tier 1, all black solar panels',
    offset: '100%'
  },
  inverter: 'Enphase IQ8',
  numberOfPanels: 0,
  solarOffset: 100,
  systemCost: 0,
  federalItc: 0,
  netSystemCost: 0,
  wattCost: 0,
  financeSystemCost: 0,
  monthlyInstallment: 0,
  monthlyEnergyCost: 0,
  energyBillingSaving: 0,
  financeNetSystemCost: 0,
  financeWattCost: 0,
  estimatedAnnualIncrease: 0,
  savingOver25Years: 0,
  srecPreBuyDiscount: 0,
  federalLocalStateIncentives: 0
};

const Steps = [
  {
    primaryStep: true,
    stepName: 'WelcomeStep',
    component: WelcomeStep,
    content: `9 out of 10 homebuyers prefer homes with energy efficient upgrades than homes that cost less.`,
    sourceText: `EnergyLogic`,
    image: <SolarPanelInstallation />
  },
  {
    primaryStep: true,
    stepName: 'AddressVerification',
    component: AddressVerification,
    content: `9 out of 10 homebuyers prefer homes with energy efficient upgrades than homes that cost less.`,
    sourceText: `EnergyLogic`,
    image: <WomenCombat />
  },
  //   {
  //     primaryStep: false,
  //     stepName: 'ServiceNotAvailableStep',
  //     component: ServiceNotAvailableStep,
  //     content: `Currently, just one percent of homes in the U.S. use electric heat pump water heaters for water heating.`,
  //     image: <SolarPanelInstallation />
  //   },
  //   {
  //     primaryStep: false,
  //     stepName: 'AppStoreAndPlayStoreStep',
  //     component: AppStoreAndPlayStoreStep,
  //     content: `Currently, just one percent of homes in the U.S. use electric heat pump water heaters for water heating.`,
  //     image: <SolarPanelInstallation />
  //   },
  {
    primaryStep: true,
    stepName: 'CustomerNameStep',
    component: CustomerNameStep,
    content: `Solar panels increase the value of your home by 4.1%, on average.`,
    sourceText: `Zillow`,
    image: <WomanWithLaptop />
  },
  {
    primaryStep: true,
    stepName: 'PropertyValidation',
    component: PropertyValidation,
    content: `On average, homes lose 20% of their heated and cooled air to duct leakage.`,
    sourceText: `U.S. Department of Energy`,
    image: <SolarHome />
  },
  //   {
  //     primaryStep: false,
  //     stepName: 'AppStoreAndPlayStoreStep',
  //     component: AppStoreAndPlayStoreStep,
  //     content: `Currently, just one percent of homes in the U.S. use electric heat pump water heaters for water heating.`,
  //     image: <SolarPanelInstallation />
  //   },
  {
    primaryStep: true,
    stepName: 'MonthlyElectricCost',
    component: MonthlyElectricCost,
    content: `Water heating can make up as much as 25% of your total energy cost.`,
    sourceText: `U.S. Department of Energy`,
    image: <SolarHome />
  },
  {
    primaryStep: true,
    stepName: 'PhoneNumberValidation',
    component: PhoneNumberValidation,
    content: `The price of electricity increased 14% in 2022.`,
    sourceText: `UtilityDive`,
    image: <FreeTime />
  },
  //   {
  //     primaryStep: false,
  //     stepName: 'OtpVerificationStep',
  //     component: OtpVerificationStep,
  //     content: `Currently, just one percent of homes in the U.S. use electric heat pump water heaters for water heating.`,
  //     image: <SolarPanelInstallation />
  //   },
  {
    primaryStep: true,
    stepName: 'EmailStep',
    component: EmailStep,
    content: `Currently, five percent of homes in the U.S. have rooftop solar.`,
    sourceText: `PV Magazine`,
    image: <BusyMan />
  }
];

const stepperSteps = [
  'addressStep',
  'customerNameStep',
  'propertyTypeStep',
  'monthlyElectricCostStep',
  'phoneNumberStep',
  'emailStep',
  // 'urjanetStep',
  'customDesignStep'
];

// const stepperStepsV2 = [<WelcomeStep key={'welcome_step'} />, <AddressStep key={'address'} />];
const CURRENT_ENV = process.env.REACT_APP_NODE_ENV;
export const StepperProvider = ({ children }) => {
  const isProduction = CURRENT_ENV === 'production';
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const stateData = JSON.parse(urlParams.get('state'));
  const stepperEssentialKeys = [`is_redirected_from_website`, 'solarInquiryData', 'updateCred'];
  const isAnyKeyPresent =
    stateData && stepperEssentialKeys.some((key) => stateData.hasOwnProperty(key));

  const [multiStapperSteps, setMultiStapperSteps] = useState(stepperSteps);
  const [propertyType, setPropertyType] = useState('Own');

  const [activeStep, setActiveStep] = useState(multiStapperSteps.indexOf('addressStep'));
  // here !!stateData or !!isAnyKeyPresent prevent millisec homepage rendering if it is directly redirect to the perticular step
  // const [isStepperStart, setIsStepperStart] = useState(!!isAnyKeyPresent);
  const [isStepperStart, setIsStepperStart] = useState(false);
  const [isGoogleSunroofLoading, setIsGoogleSunroofLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showExpandingPage, setShowExpandingPage] = useState(false);
  const [showAppPage, setShowAppPage] = useState(false);
  // const [solarFlowData, setSolarFlowData] = useState();
  const [totalSteps, setTotalSteps] = useState(0);
  const [solarInquiryData, setSolarInquiryData] = useState(
    stateData && stateData.hasOwnProperty('is_redirected_from_website' || 'solarInquiryData')
      ? stateData.solarInquiryData
      : defaultSolarInquiryData
  );

  const [isExistingProposal, setIsExistingProposal] = useState(false);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [currentStepName, setCurrentStepName] = useState('WelcomeStep');
  const [isBelongsToStateArray, setIsBelongsToStateArray] = useState(true);
  const [isRentedProperty, setIsRentedProperty] = useState(false);
  const [productQuantity, setProductQuantity] = useState(0);
  const [updatedCalculationBasedOnBattery, setUpdatedCalculationBasedOnBattery] = useState(null);
  const [proposalId, setProposalId] = useState({ customerAddressId: '', solarProposalId: '' });
  const [loading, setLoading] = useState(false);
  const [solarProposalAdditionalDetails, setSolarProposalAdditionalDetails] =
    useState(additionalDetails);

  const [amountForGTMandPixel, setAmountForGTMandPixel] = useState(0);

  const [solarConfigDataQuery, { loading: solarConfigLoading, data: solarConfigData }] =
    useLazyQuery(SOLAR_CONFIGURATION);
  const [fetchUtility, { loading: utilityLoading, data: utilityData }] = useLazyQuery(UTILITY);

  const [solarConfiguration, setSolarConfiguration] = useState(null);

  const [steps, setSteps] = useState(Steps);

  const redirectToAppStore = () => {
    window.location.href = process.env.REACT_APP_APP_STORE_LINK;
    // window.open(process.env.REACT_APP_APP_STORE_LINK, '_blank');
  };

  const redirectToPlayStore = () => {
    window.location.href = process.env.REACT_APP_PLAY_STORE_LINK;
    // window.open(process.env.REACT_APP_PLAY_STORE_LINK, '_blank');
  };

  const redirectToWebsite = (websitePage, newTab = false) => {
    // window.location.href = websitePage
    //   ? process.env.REACT_APP_WEBSITE_LINK + websitePage
    //   : process.env.REACT_APP_WEBSITE_LINK;
    const websiteLink = websitePage
      ? process.env.REACT_APP_WEBSITE_LINK + websitePage
      : process.env.REACT_APP_WEBSITE_LINK;
    newTab ? window.open(websiteLink, '_blank') : (window.location.href = websiteLink);
  };

  useEffect(() => {
    const fetchSolarConfigData = async () => {
      setIsLoading(true);
      try {
        const { data } = await solarConfigDataQuery();

        if (data) {
          setIsLoading(false);
          setSolarConfiguration(data.solarConfiguration);
        }
      } catch (error) {
        // Handle any errors
        // eslint-disable-next-line no-console
        console.error(error);
        setIsLoading(false);
      }
    };

    const fetchUtilityData = async (state, zipcode) => {
      try {
        const utilityRes = await fetchUtility({
          variables: {
            state,
            zipCode: ''
          }
        });

        if (utilityRes) {
          // console.log('utilityRes :', utilityRes.data.utility[0]);
          setIsLoading(false);
          setSolarInquiryData({
            ...solarInquiryData,
            electricityCost: utilityRes.data.utility.length
              ? parseFloat(utilityRes.data.utility[0].avgResidentialRate)
              : ''
          });
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    if (stateData && stateData.is_redirected_from_website) {
      setIsLoading(true);
      fetchUtilityData(stateData.solarInquiryData.state, '');
    }

    fetchSolarConfigData();
  }, []);

  useEffect(() => {
    setTotalSteps(steps.length);
    const currentIndex = steps.findIndex((step) => step.stepName === currentStepName);
    setActiveStep(currentIndex);
    setIsStepperStart(true);
  }, [currentStepName]);

  useEffect(() => {
    console.log('solarInquiryData from provider :', solarInquiryData);
  }, [solarInquiryData]);

  return (
    <StepperContext.Provider
      value={{
        activeStep,
        isStepperStart,
        // solarFlowData,
        totalSteps,
        solarInquiryData,
        isLoading,
        setSolarInquiryData,
        // setSolarFlowData,
        setIsStepperStart,
        setActiveStep,
        setTotalSteps,
        setIsLoading,
        isGoogleSunroofLoading,
        setIsGoogleSunroofLoading,
        redirectToAppStore,
        redirectToPlayStore,
        redirectToWebsite,
        solarConfiguration,
        solarConfigLoading,
        multiStapperSteps,
        setMultiStapperSteps,
        setAmountForGTMandPixel,
        amountForGTMandPixel,
        propertyType,
        setPropertyType,
        showExpandingPage,
        setShowExpandingPage,
        setShowAppPage,
        showAppPage,
        Steps,
        currentStepName,
        setCurrentStepName,
        isBelongsToStateArray,
        setIsBelongsToStateArray,
        isProduction,
        isRentedProperty,
        setIsRentedProperty,
        isExistingProposal,
        setIsExistingProposal,
        productQuantity,
        setProductQuantity,
        updatedCalculationBasedOnBattery,
        setUpdatedCalculationBasedOnBattery,
        proposalId,
        setProposalId,
        loading,
        setLoading,
        solarProposalAdditionalDetails,
        setSolarProposalAdditionalDetails,
        steps,
        setSteps
      }}>
      {children}
    </StepperContext.Provider>
  );
};
