import { encryptionSecretKey } from '../Constants';
import CryptoJS from 'crypto-js';

export const validateEmail = (email) => {
  // Regular expression to check email format
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const calculatePMT = (rate, periods, presentValue, futureValue = 0, type = 0) => {
  // rate         - intrest rate value
  // periods      -  Number of years
  // presentValue - System Cost
  // futureValue  - Replace 0 with your futureValue value, if applicable
  // type         - Replace 0 with your type value, if applicable
  const denominator = Math.pow(1 + rate, periods) - 1;
  const numerator = rate * (futureValue + presentValue * Math.pow(1 + rate, periods));
  let payment = -numerator / denominator;

  if (type === 1) {
    payment /= 1 + rate;
  }

  return payment;
};

export const calculateSavings = (
  solarDegradationFactor,
  inflationRate,
  totalTimePeriod,
  electricityCost,
  annualUsage
) => {
  // inflationRate = 3.5;
  // console.log('solarDegradationFactor :', solarDegradationFactor);
  // console.log('inflationRate :', inflationRate);
  // console.log('totalTimePeriod :', totalTimePeriod);
  // console.log('electricityCost :', electricityCost);
  // console.log('annualUsage :', annualUsage);

  const utilityRateArrayForTimePeriod = [];
  const solarOutputArrayForTimePeriod = [];
  const annualSavingsArrayForTimePeriod = [];

  for (let index = 0; index < totalTimePeriod; index++) {
    if (index === 0) {
      utilityRateArrayForTimePeriod.push(electricityCost);
      solarOutputArrayForTimePeriod.push(annualUsage);
      annualSavingsArrayForTimePeriod.push(electricityCost * annualUsage);
    } else {
      utilityRateArrayForTimePeriod.push(
        utilityRateArrayForTimePeriod[index - 1] * (1 + inflationRate / 100)
      );
      solarOutputArrayForTimePeriod.push(
        solarOutputArrayForTimePeriod[index - 1] * (1 + solarDegradationFactor / 100)
      );
      annualSavingsArrayForTimePeriod.push(
        utilityRateArrayForTimePeriod[index] * solarOutputArrayForTimePeriod[index]
      );
    }
  }

  // console.log('utilityRateArrayForTimePeriod :', utilityRateArrayForTimePeriod);
  // console.log('solarOutputArrayForTimePeriod :', solarOutputArrayForTimePeriod);
  // console.log('annualSavingsArrayForTimePeriod :', annualSavingsArrayForTimePeriod);
  return annualSavingsArrayForTimePeriod.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
};

export function formatPhoneNumber(phoneNumber) {
  if (phoneNumber.length < 11) {
    return 'Invalid phone number';
  }

  const countryCode = `+${phoneNumber.slice(0, phoneNumber.length - 10)}`;
  const formattedPhoneNumber = `${countryCode} (${phoneNumber.slice(-10, -7)}) ${phoneNumber.slice(
    -7,
    -4
  )}-${phoneNumber.slice(-4)}`;

  return formattedPhoneNumber;
}

export const encryptionAES = (inputValue, secretKey = encryptionSecretKey.secretKey) => {
  const encrypted = CryptoJS.AES.encrypt(inputValue, secretKey);
  return encrypted.toString();
};

export const decryptionAES = (encryptedValue, secretKey = encryptionSecretKey.secretKey) => {
  if (encryptedValue) {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      // console.log('decrypted :', decrypted);
      return { success: true, data: decrypted };
    } catch (error) {
      return { success: false, error };
    }
  }
};

// export function redirectToExternalUrl(url, authToken, encryption = false, paramName = 'authToken') {
//   let redirectUrl;
//   if (encryption) {
//     redirectUrl = `${url}?${paramName}=${encodeURIComponent(encryptionAES(authToken))}&source=web`;
//   } else {
//     redirectUrl = `${url}?${paramName}=${authToken}&source=web`;
//   }

//   return (window.location.href = redirectUrl);
// }
