import React, { useContext } from 'react';
import DaylightLogo from '../../../../../../resources/icons/DaylightLogo';
import { useMutation } from '@apollo/client';
import { GET_PROPOSAL_BOOKING_PAYMENT_URL } from '../../../../../../GraphQL/Mutations';
import { StepperContext } from '../../../../../../providers/stepper.provider';

const ProposalNavBar = () => {
  const { proposalId } = useContext(StepperContext);
  const [getProposalBookingPaymentUrl, { loading: getProposalBookingPaymentUrlLoading }] =
    useMutation(GET_PROPOSAL_BOOKING_PAYMENT_URL);
  const handleStripeCheckout = async () => {
    // console.log('ffff');
    try {
      const proposalBookingPaymentUrl = await getProposalBookingPaymentUrl({
        variables: {
          solarProposalId: proposalId.solarProposalId
        }
      });

      // console.log(
      //   'proposalBookingPaymentUrl :',
      //   proposalBookingPaymentUrl.data.getProposalBookingPaymentUrl.url
      // );
      // return (window.location.href =
      //   proposalBookingPaymentUrl.data.getProposalBookingPaymentUrl.url);
      const paymentUrl = proposalBookingPaymentUrl.data.getProposalBookingPaymentUrl.url;
      return window.open(paymentUrl, '_blank');
    } catch (error) {}
  };

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="[&>svg]:max-w-[181px] [&>svg]:max-h-[52px]">
        <DaylightLogo />
      </div>

      <div className="text-left ml-[5px] my-0 hidden md:block">
        <button
          className="bg-primary max-w-[207px] max-h-[50px] font-forma-djr-text-regular py-3 px-14 text-secondary_white rounded-md text-lg border-primary cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
          onClick={async () => handleStripeCheckout()}>
          Checkout
        </button>
      </div>
    </div>
  );
};

export default ProposalNavBar;
