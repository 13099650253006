import { createContext, useState } from 'react';

export const PopoverContext = createContext();

const defaultObject = {
  title: 'Title',
  content: 'Content',
  icon: 'icon',
  closeButtonText: 'Close',
  redirectTo: () => {}
};

export const PopoverProvider = ({ children }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [popoverParams, setPopoverParams] = useState(defaultObject);
  const [isCloseButton, setIsCloseButton] = useState(false);

  const openPopover = () => {
    setIsPopoverOpen(true);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
    popoverParams.redirectTo && popoverParams.redirectTo();
  };
  return (
    <PopoverContext.Provider
      value={{
        isPopoverOpen,
        openPopover,
        closePopover,
        popoverParams,
        setPopoverParams,
        isCloseButton,
        setIsCloseButton
      }}>
      {children}
    </PopoverContext.Provider>
  );
};
