import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../../../components/InputFields/InputField';
import { StepperContext } from '../../../../../../../providers/stepper.provider';
import { useNavigate } from 'react-router-dom';
import { calculateSavings, validateEmail } from '../../../../../../../utils/utils';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_PROPOSAL_BOOKING_PAYMENT_URL,
  INITIALIZE_SOLAR_PROPOSAL_MUTATION,
  UPDATE_SOLAR_PROPOSAL_MUTATION
} from '../../../../../../../GraphQL/Mutations';
import { SOLAR_PROPOSAL_QUERY } from '../../../../../../../GraphQL/Queries';
import { FamilyType } from '../../../../../../../GraphQL/Types';
import { LeadContext } from '../../../../../../../providers/lead.provider';
import { PopoverContext } from '../../../../../../../providers/popover.provider';
import { CgSpinner } from 'react-icons/cg';
import { accountCreatedGTM } from '../../../../../../../utils/googleTagManager';
import { enterEmailEventPixel } from '../../../../../../../utils/facebookPixelEvents';
import { klaviyoSocialLogin } from '../../../../../../../utils/klaviyo';

const EmailStep = () => {
  const {
    solarInquiryData,
    setSolarInquiryData,
    solarConfiguration,
    isExistingProposal,
    setIsExistingProposal,
    isProduction,
    productQuantity,
    setProductQuantity,
    updatedCalculationBasedOnBattery,
    setUpdatedCalculationBasedOnBattery,
    proposalId,
    setProposalId,
    loading,
    setLoading,
    setSolarProposalAdditionalDetails,
    solarProposalAdditionalDetails
  } = useContext(StepperContext);
  const { leadId, addLeadStep, updateUserLead, generateLeadId } = useContext(LeadContext);
  const { openPopover, setPopoverParams, setTitle, setIcon, setContent, setCloseButtonText } =
    useContext(PopoverContext);

  const navigate = useNavigate();

  // For monthly Rate
  const FINANCE_RATE = parseFloat(solarConfiguration.financeRate); // 2.99%;
  const TERM_IN_YEARS = parseInt(solarConfiguration.termInYears);
  const PER_BATTERY_COST = parseFloat(solarConfiguration.batteryCostPerBattery);
  const PER_BATTERY_PAYMENT = parseFloat(solarConfiguration.batteryPaymentPerBattery);
  const BATTERY_FEDERAL_INCENTIVE = parseFloat(solarConfiguration.batteryItcRate); // 30%
  const PER_BATTERY_CASHBACK_RATE = parseFloat(solarConfiguration.daylightCashbackRate); // 12.50%

  const INFLATION_RATE_PERCENTAGE = parseFloat(solarConfiguration.inflationRate); // increase by 3% per year
  const SOLAR_DEGRADATION_FACTOR_PERCENTAGE = parseFloat(solarConfiguration.solarDegradationFactor); // decrease 0.05% per year
  // const TOTAL_YEARS = parseInt(solarConfiguration.termInYears);
  const TOTAL_YEARS = 25;
  const DAYLIGHT_CASHBACK_PPW = parseFloat(solarConfiguration.daylightCashbackPpw);
  const SYSTEM_PRICE_RATE_PPW = parseFloat(solarConfiguration.systemPriceRate);
  const FEDERAL_ITC_RATE = parseFloat(solarConfiguration.federalItcRate); // 30%;

  // const [loading, setLoading] = useState(false);
  // Mutations
  const [getProposalBookingPaymentUrl, { loading: getProposalBookingPaymentUrlMutationLoading }] =
    useMutation(GET_PROPOSAL_BOOKING_PAYMENT_URL);
  const [initializeSolarProposalMutation, { loading: initializeSolarProposalMutationLoading }] =
    useMutation(INITIALIZE_SOLAR_PROPOSAL_MUTATION);
  const [updateSolarProposalMutation, { loading: updateSolarProposalMutationLoading }] =
    useMutation(UPDATE_SOLAR_PROPOSAL_MUTATION);

  // Queries
  // const [fetchUtility, { loading: utilityLoading, data: utilityData }] = useLazyQuery(UTILITY);
  const [
    fetchSolarProposalData,
    { loading: solarProposalQueryLoading, data: solarProposalQueryData }
  ] = useLazyQuery(SOLAR_PROPOSAL_QUERY);

  const handleClick = () => {
    navigate('/proposal');
  };

  const annualEnergyUsage = (electricityRate, monthlyEnergyCost) => {
    const annualEnergyUsage = Math.ceil(monthlyEnergyCost / electricityRate) * 12;
    return annualEnergyUsage;
  };

  const calculateBatteryCost = (quantity) => {
    return quantity * PER_BATTERY_COST + quantity * PER_BATTERY_PAYMENT;
  };

  const calculateBatteryCashBack = (quantity) => {
    return quantity * ((PER_BATTERY_COST * PER_BATTERY_CASHBACK_RATE) / 100);
  };

  const checkProposalIsExist = async (email) => {
    const getSolarInquiry = await fetchSolarInquiry(email);
    // console.log('getSolarInquiry :', getSolarInquiry);
    if (!getSolarInquiry) {
      console.log('generate');
      await generateProposal();
    }
  };

  const continueWithExistingAddress = async (solarInquiryResData, solarInquiryData) => {
    console.log('solarInquiryResData1 :', solarInquiryResData);
    console.log('solarInquiryData1 :', solarInquiryData);
    // console.log('continueWithExistingAddress');
    // await addLeadStep({ solarInquiryData: solarInquiryResData }, 'continueWithExistingAddress');
    const updatedSolarInquiryData = {
      address: solarInquiryResData.customerAddress.address,
      city: solarInquiryResData.customerAddress.city,
      state: solarInquiryResData.customerAddress.state,
      zipCode: solarInquiryResData.customerAddress.zipCode,
      email: solarInquiryResData.customerAddress.email,
      electricityCost: parseFloat(solarInquiryResData.customerAddress.electricityCost),
      energyBillSaving: 1.4,
      monthlyEnergyCost: solarInquiryResData.customerAddress.avgMonthlyElectricityCost,
      earnCashbackUpTo:
        parseInt(solarInquiryResData.earnCashbackUpTo) -
        calculateBatteryCashBack(solarInquiryResData.batteryQuantity),
      treesPlanted: parseInt(solarInquiryResData.treesPlanted),
      wattCost: solarInquiryResData.wattCost,
      savingOver25Years:
        parseInt(solarInquiryResData.savingOver25Years) +
        (solarInquiryData.netSolarSystemPrice +
          solarInquiryResData.batteryQuantity * PER_BATTERY_COST -
          (BATTERY_FEDERAL_INCENTIVE / 100) *
            solarInquiryResData.batteryQuantity *
            PER_BATTERY_COST +
          solarInquiryResData.batteryQuantity * PER_BATTERY_PAYMENT -
          calculateBatteryCashBack(solarInquiryResData.batteryQuantity)),
      estSolarSystemSizekW: parseFloat(solarInquiryResData.estSolarSystemSizeKw),
      sizeSmoothing: parseFloat(solarInquiryResData.sizeSmoothing),
      systemPrice:
        parseInt(solarInquiryResData.systemCost) -
        calculateBatteryCost(solarInquiryResData.batteryQuantity),
      fedralITCAmount:
        parseInt(solarInquiryResData.federalItc) -
        (BATTERY_FEDERAL_INCENTIVE / 100) * solarInquiryResData.batteryQuantity * PER_BATTERY_COST,
      netSolarSystemPrice:
        parseInt(solarInquiryResData.netSystemCost) -
        (solarInquiryResData.batteryQuantity * PER_BATTERY_COST -
          (BATTERY_FEDERAL_INCENTIVE / 100) *
            solarInquiryResData.batteryQuantity *
            PER_BATTERY_COST +
          solarInquiryResData.batteryQuantity * PER_BATTERY_PAYMENT -
          calculateBatteryCashBack(solarInquiryResData.batteryQuantity)),
      batteryQuantity: solarInquiryResData.batteryQuantity,
      sunroofImageId: '',
      formatedAddress: '',
      addressLatLng: {
        lat: Number(solarInquiryResData.customerAddress.latitude),
        lng: Number(solarInquiryResData.customerAddress.longitude)
      },
      phoneNumber: solarInquiryResData.customerAddress.phoneNumber,
      progress: solarInquiryResData.customerAddress.progress
    };

    // TODO: update Additional fields

    const updatedAdditionalValues = {
      batteryBrand: solarInquiryResData.batteryBrand,
      // futureAddOns: JSON.parse(responseData.futureAddOnsString),
      inverter: solarInquiryResData.inverter,
      solarOffset: parseInt(solarInquiryResData.solarOffset),
      systemCost: parseInt(solarInquiryResData.systemCost),
      federalItc: parseInt(solarInquiryResData.federalItc),
      netSystemCost: parseInt(solarInquiryResData.netSystemCost),
      wattCost: parseFloat(solarInquiryResData.wattCost),
      financeSystemCost: parseFloat(solarInquiryResData.financeSystemCost),
      monthlyInstallment: parseFloat(solarInquiryResData.monthlyInstallment),
      monthlyEnergyCost: parseFloat(solarInquiryResData.monthlyEnergyCost),
      energyBillingSaving: parseFloat(solarInquiryResData.energyBillingSaving),
      financeNetSystemCost: parseFloat(solarInquiryResData.financeNetSystemCost),
      financeWattCost: parseFloat(solarInquiryResData.financeWattCost),
      estimatedAnnualIncrease: parseInt(solarInquiryResData.estimatedAnnualIncrease),
      numberOfPanels: parseInt(solarInquiryResData.numberOfPanels),
      savingOver25Years: parseInt(solarInquiryResData.savingOver25Years),
      srecPreBuyDiscount: parseInt(solarInquiryResData.srecPreBuyDiscount),
      federalLocalStateIncentives: parseInt(solarInquiryResData.federalLocalStateIncentives)
    };

    setSolarProposalAdditionalDetails({
      ...updatedAdditionalValues
    });

    // setUpdatedCalculationBasedOnBattery({
    //   ...updatedSolarInquiryData,
    //   earnCashbackUpTo: parseInt(solarInquiryResData.earnCashbackUpTo),
    //   systemPrice: parseInt(solarInquiryResData.systemCost),
    //   fedralITCAmount: parseInt(solarInquiryResData.federalItc),
    //   netSolarSystemPrice: parseInt(solarInquiryResData.netSystemCost),
    //   savingOver25Years: parseInt(solarInquiryResData.savingOver25Years)
    // });

    setProductQuantity(solarInquiryResData.batteryQuantity);

    setProposalId({
      customerAddressId: solarInquiryResData.customerAddress.id,
      solarProposalId: solarInquiryResData.id
    });

    const prevSolarInquiryData = { ...solarInquiryData };

    if (!updatedSolarInquiryData.phoneNumber && !updatedSolarInquiryData.progress) {
      setIsExistingProposal(true);
      setSolarInquiryData({
        ...updatedSolarInquiryData,
        phoneNumber: prevSolarInquiryData.phoneNumber,
        addressLatLng: !(
          solarInquiryResData.customerAddress.latitude &&
          solarInquiryResData.customerAddress.longitude
        )
          ? prevSolarInquiryData.addressLatLng
          : {
              lat: Number(solarInquiryResData.customerAddress.latitude),
              lng: Number(solarInquiryResData.customerAddress.longitude)
            }
        // electricityCost: 0.2122,
        // monthlyEnergyCost: 150
      });

      // setActiveStep(multiStapperSteps.indexOf('emailStep') + 1);
      navigate('/proposal');
      // setIsManualSignUp(false);
      setLoading(false);
    } else {
      setIsExistingProposal(true);
      // console.log('updatedSolarInquiryData :', updatedSolarInquiryData);
      setSolarInquiryData({
        ...updatedSolarInquiryData,
        addressLatLng: !(
          solarInquiryResData.customerAddress.latitude &&
          solarInquiryResData.customerAddress.longitude
        )
          ? prevSolarInquiryData.addressLatLng
          : updatedSolarInquiryData.addressLatLng
      });

      // setActiveStep(multiStapperSteps.indexOf(updatedSolarInquiryData.progress));
      // setActiveStep(multiStapperSteps.indexOf('emailStep') + 1);
      navigate('/proposal');
      // setIsManualSignUp(false);
      setLoading(false);
    }
  };

  const generateProposal = async () => {
    // console.log('solarInquiryData :', solarInquiryData);
    setLoading(true);
    try {
      const solarInquiryResponse = await initializeSolarProposalMutation({
        variables: {
          addressInput: {
            firstName: solarInquiryData.firstName,
            lastName: solarInquiryData.lastName,
            email: solarInquiryData.email,
            address: solarInquiryData.address,
            city: solarInquiryData.city,
            state: solarInquiryData.state,
            zipCode: solarInquiryData.zipCode,
            phoneNumber: solarInquiryData.phoneNumber,
            familyType: FamilyType.Single,
            longitude: solarInquiryData.addressLatLng.lng.toString(),
            latitude: solarInquiryData.addressLatLng.lat.toString(),
            electricityCost: solarInquiryData.electricityCost.toString(),
            avgMonthlyElectricityCost: parseFloat(solarInquiryData.monthlyEnergyCost),
            progress: '',
            fullAddress: solarInquiryData.formatedAddress,
            onHome: solarInquiryData.onHome
          },
          solarProposalInput: {
            // earnCashbackUpTo: solarInquiryData.earnCashbackUpTo,
            // estSolarSystemSizeKw: solarInquiryData.estSolarSystemSizekW,
            // federalItc: parseFloat(solarInquiryData.fedralITCAmount.toFixed(2)),
            // savingOver25Years: solarInquiryData.savingOver25Years,
            // sizeSmoothing: solarInquiryData.sizeSmoothing,
            // // systemSize: solarInquiryData.earnCashbackUpTo,
            // treesPlanted: solarInquiryData.treesPlanted,
            // wattCost: solarInquiryData.wattCost,
            // systemCost: parseFloat(solarInquiryData.systemPrice.toFixed(2)),
            // netSystemCost: parseFloat(solarInquiryData.netSolarSystemPrice.toFixed(2)),
            batteryQuantity: solarInquiryData.batteryQuantity,
            heatPump: solarInquiryData.heatPump,
            energyEfficiency: solarInquiryData.energyEfficiency
          }
        }
      });

      console.log('solarInquiryResponse :::: :', solarInquiryResponse);

      // if (solarInquiryResponse.data.solarInquiry.solarInquiry.id) {
      if (solarInquiryResponse.data.initializeSolarProposal.success) {
        const responseData = solarInquiryResponse.data.initializeSolarProposal.success;

        const updatedSolarInquiryData = {
          address: responseData.customerAddress.address,
          city: responseData.customerAddress.city,
          state: responseData.customerAddress.state,
          zipCode: responseData.customerAddress.zipCode,
          email: responseData.customerAddress.email,
          electricityCost: parseFloat(responseData.customerAddress.electricityCost),
          energyBillSaving: 1.4,
          monthlyEnergyCost: responseData.customerAddress.avgMonthlyElectricityCost,
          earnCashbackUpTo: parseInt(responseData.earnCashbackUpTo),
          treesPlanted: parseInt(responseData.treesPlanted),
          wattCost: responseData.wattCost,
          // savingOver25Years: parseInt(responseData.savingOver25Years),
          // estSolarSystemSizekW: parseFloat(responseData.estSolarSystemSizeKw),
          sizeSmoothing: parseFloat(responseData.sizeSmoothing),
          systemPrice: parseInt(responseData.systemCost),
          // fedralITCAmount: parseInt(responseData.federalItc),
          batteryQuantity: responseData.batteryQuantity,
          addressLatLng: {
            lat: Number(responseData.customerAddress.latitude),
            lng: Number(responseData.customerAddress.longitude)
          },
          phoneNumber: responseData.customerAddress.phoneNumber,
          progress: responseData.customerAddress.progress,
          heatPump: responseData.heatPump,
          energyEfficiency: responseData.energyEfficiency,
          sunroofImageId: '',
          formatedAddress: solarInquiryData.formatedAddress
          // netSolarSystemPrice: responseData.netSystemCost
        };

        const updatedAdditionalValues = {
          batteryBrand: responseData.batteryBrand,
          // futureAddOns: JSON.parse(responseData.futureAddOnsString),
          inverter: responseData.inverter,
          solarOffset: parseInt(responseData.solarOffset),
          systemCost: parseInt(responseData.systemCost),
          federalItc: parseInt(responseData.federalItc),
          netSystemCost: parseInt(responseData.netSystemCost),
          wattCost: parseFloat(responseData.wattCost),
          financeSystemCost: parseFloat(responseData.financeSystemCost),
          monthlyInstallment: parseFloat(responseData.monthlyInstallment),
          monthlyEnergyCost: parseFloat(responseData.monthlyEnergyCost),
          energyBillingSaving: parseFloat(responseData.energyBillingSaving),
          financeNetSystemCost: parseFloat(responseData.financeNetSystemCost),
          financeWattCost: parseFloat(responseData.financeWattCost),
          estimatedAnnualIncrease: parseInt(responseData.estimatedAnnualIncrease),
          numberOfPanels: parseInt(responseData.numberOfPanels),
          savingOver25Years: parseInt(responseData.savingOver25Years),
          srecPreBuyDiscount: parseInt(responseData.srecPreBuyDiscount),
          federalLocalStateIncentives: parseInt(responseData.federalLocalStateIncentives)
        };

        // console.log('updatedAdditionalValues :', updatedAdditionalValues);

        setSolarProposalAdditionalDetails({
          ...updatedAdditionalValues
        });

        setSolarInquiryData({ ...updatedSolarInquiryData });

        setProposalId({
          customerAddressId:
            solarInquiryResponse.data.initializeSolarProposal.success.customerAddress.id,
          solarProposalId: solarInquiryResponse.data.initializeSolarProposal.success.id
        });
        // setActiveStep(multiStapperSteps.indexOf('emailStep') + 1);
        navigate('/proposal');
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      // redirectToWebsite();
    } finally {
      setLoading(false);
    }
  };

  const updateSolarProposalValues = async (existingSolarRes) => {
    const updatedSolarInquiryData = {
      address: existingSolarRes.customerAddress.address,
      city: existingSolarRes.customerAddress.city,
      state: existingSolarRes.customerAddress.state,
      zipCode: existingSolarRes.customerAddress.zipCode,
      email: existingSolarRes.customerAddress.email,
      electricityCost: parseFloat(existingSolarRes.customerAddress.electricityCost),
      energyBillSaving: 1.4,
      monthlyEnergyCost: existingSolarRes.customerAddress.avgMonthlyElectricityCost,
      earnCashbackUpTo: parseInt(existingSolarRes.earnCashbackUpTo),
      treesPlanted: parseInt(existingSolarRes.treesPlanted),
      wattCost: existingSolarRes.wattCost,
      // savingOver25Years: parseInt(responseData.savingOver25Years),
      // estSolarSystemSizekW: parseFloat(responseData.estSolarSystemSizeKw),
      sizeSmoothing: parseFloat(existingSolarRes.sizeSmoothing),
      systemPrice: parseInt(existingSolarRes.systemCost),
      // fedralITCAmount: parseInt(responseData.federalItc),
      batteryQuantity: existingSolarRes.batteryQuantity,
      addressLatLng: {
        lat: Number(existingSolarRes.customerAddress.latitude),
        lng: Number(existingSolarRes.customerAddress.longitude)
      },
      phoneNumber: existingSolarRes.customerAddress.phoneNumber,
      progress: existingSolarRes.customerAddress.progress,
      heatPump: existingSolarRes.heatPump,
      energyEfficiency: existingSolarRes.energyEfficiency,
      sunroofImageId: '',
      formatedAddress: solarInquiryData.formatedAddress
    };

    const updatedAdditionalValues = {
      batteryBrand: existingSolarRes.batteryBrand,
      // futureAddOns: JSON.parse(existingSolarRes.futureAddOnsString),
      inverter: existingSolarRes.inverter,
      solarOffset: parseInt(existingSolarRes.solarOffset),
      systemCost: parseInt(existingSolarRes.systemCost),
      federalItc: parseInt(existingSolarRes.federalItc),
      netSystemCost: parseInt(existingSolarRes.netSystemCost),
      wattCost: Number(existingSolarRes.wattCost),
      financeSystemCost: parseFloat(existingSolarRes.financeSystemCost),
      monthlyInstallment: parseFloat(existingSolarRes.monthlyInstallment),
      monthlyEnergyCost: parseFloat(existingSolarRes.monthlyEnergyCost),
      energyBillingSaving: parseFloat(existingSolarRes.energyBillingSaving),
      financeNetSystemCost: parseFloat(existingSolarRes.financeNetSystemCost),
      financeWattCost: parseFloat(existingSolarRes.financeWattCost),
      estimatedAnnualIncrease: parseInt(existingSolarRes.estimatedAnnualIncrease),
      numberOfPanels: parseInt(existingSolarRes.numberOfPanels),
      savingOver25Years: parseInt(existingSolarRes.savingOver25Years),
      srecPreBuyDiscount: parseInt(existingSolarRes.srecPreBuyDiscount),
      federalLocalStateIncentives: parseInt(existingSolarRes.federalLocalStateIncentives)
    };

    setSolarProposalAdditionalDetails({
      ...updatedAdditionalValues
    });

    setSolarInquiryData({ ...updatedSolarInquiryData });
  };

  const updateAddress = async (solarInquiryResData) => {
    // console.log('update Address');
    // await addLeadStep({ solarInquiryData: solarInquiryResData }, 'updateAddress');
    setProposalId({
      customerAddressId: solarInquiryResData.customerAddress.id,
      solarProposalId: solarInquiryResData.id
    });

    // TODO: update Address using  update api
    // await updateBatterySlider(solarInquiryResData.batteryQuantity, {
    //   customerAddressId: solarInquiryResData.customerAddress.id,
    //   solarProposalId: solarInquiryResData.id
    // });

    await updateProposalNew({
      addressInput: {
        id: solarInquiryResData.customerAddress.id,
        firstName: solarInquiryData.firstName,
        lastName: solarInquiryData.lastName,
        address: solarInquiryData.address,
        city: solarInquiryData.city,
        state: solarInquiryData.state,
        zipCode: solarInquiryData.zipCode,
        phoneNumber: solarInquiryData.phoneNumber,
        longitude: solarInquiryData.addressLatLng.lng,
        latitude: solarInquiryData.addressLatLng.lat,
        electricityCost: solarInquiryData.electricityCost,
        avgMonthlyElectricityCost: parseFloat(solarInquiryData.monthlyEnergyCost),
        onHome: solarInquiryData.onHome
      },
      solarProposalInput: {
        id: solarInquiryResData.id
      }
    });

    if (
      solarInquiryResData.customerAddress.progress === null ||
      solarInquiryResData.customerAddress.progress === ''
    ) {
      navigate('/proposal');
      // setActiveStep(multiStapperSteps.indexOf('emailStep') + 1);
    } else {
      navigate('/proposal');
      // setActiveStep(multiStapperSteps.indexOf('emailStep') + 1);
      // setActiveStep(multiStapperSteps.indexOf(solarInquiryResData.customerAddress.progress));
    }

    // setIsManualSignUp(false);
    setLoading(false);
  };

  const updateProposalNew = async (data) => {
    try {
      const solarInquiryResponse = await updateSolarProposalMutation({
        variables: data
      });

      await addLeadStep(
        { solarInquiryData: solarInquiryResponse.data.updateSolarProposal.success },
        'updateProposal'
      );

      await updateSolarProposalValues(solarInquiryResponse.data.updateSolarProposal.success);

      return solarInquiryResponse;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSolarInquiry = async (email) => {
    try {
      setLoading(true);
      const solarInquiryRes = await fetchSolarProposalData({
        // context: {
        //   headers: {
        //     Authorization: `Bearer ${authToken.token}`
        //   }
        // }
        variables: {
          email
        }
      });
      if (solarInquiryRes) {
        const solarInquiryResData = { ...solarInquiryRes.data.solarProposal[0] };

        console.log('gggggg : ', solarInquiryResData);

        if (Object.keys(solarInquiryResData).length) {
          if (solarInquiryData && Object.keys(solarInquiryResData).length) {
            // check solarProposal address is same or not
            if (
              solarInquiryData.address === solarInquiryResData.customerAddress.address &&
              solarInquiryData.city === solarInquiryResData.customerAddress.city &&
              solarInquiryData.state === solarInquiryResData.customerAddress.state &&
              solarInquiryData.zipCode === solarInquiryResData.customerAddress.zipCode &&
              (solarInquiryData.electricityCost !==
                parseFloat(solarInquiryResData.customerAddress.electricityCost) ||
                solarInquiryData.monthlyEnergyCost !==
                  String(parseInt(solarInquiryResData.customerAddress.avgMonthlyElectricityCost)))
            ) {
              setProposalId({
                customerAddressId: solarInquiryResData.customerAddress.id,
                solarProposalId: solarInquiryResData.id
              });

              // TODO: Need To update electricityCost and monthlyEnergyCost

              await updateProposalNew({
                addressInput: {
                  id: solarInquiryResData.customerAddress.id,
                  electricityCost: String(solarInquiryData.electricityCost),
                  avgMonthlyElectricityCost: parseFloat(solarInquiryData.monthlyEnergyCost)
                },
                solarProposalInput: {
                  id: solarInquiryResData.id
                }
              });
              // await updateBatterySlider(solarInquiryResData.batteryQuantity, {
              //   customerAddressId: solarInquiryResData.customerAddress.id,
              //   solarProposalId: solarInquiryResData.id
              // });

              // console.log('dddd3333 :');
              // await updateSolarProposalValues(solarInquiryResData);
              if (
                solarInquiryResData.customerAddress.progress === null ||
                solarInquiryResData.customerAddress.progress === ''
              ) {
                // setActiveStep(multiStapperSteps.indexOf('emailStep') + 1);
                console.log('dddd1111 :');
                navigate('/proposal');
              } else {
                navigate('/proposal');
                // setActiveStep(
                //   multiStapperSteps.indexOf(solarInquiryResData.customerAddress.progress)
                // );
                navigate('/proposal');
              }

              // setIsManualSignUp(false);
              setLoading(false);
            } else if (
              solarInquiryData.address === solarInquiryResData.customerAddress.address &&
              solarInquiryData.city === solarInquiryResData.customerAddress.city &&
              solarInquiryData.state === solarInquiryResData.customerAddress.state &&
              solarInquiryData.zipCode === solarInquiryResData.customerAddress.zipCode &&
              solarInquiryData.electricityCost ===
                parseFloat(solarInquiryResData.customerAddress.electricityCost) &&
              solarInquiryData.monthlyEnergyCost ===
                String(parseInt(solarInquiryResData.customerAddress.avgMonthlyElectricityCost))
            ) {
              console.log('dddd2222 :');
              // toast.success('Your solar proposal already exist.');

              const updatedSolarInquiryData = {
                firstName: solarInquiryResData.customerAddress.firstName,
                lastName: solarInquiryResData.customerAddress.lastName,
                address: solarInquiryResData.customerAddress.address,
                city: solarInquiryResData.customerAddress.city,
                state: solarInquiryResData.customerAddress.state,
                zipCode: solarInquiryResData.customerAddress.zipCode,
                email: solarInquiryResData.customerAddress.email,
                electricityCost: parseFloat(solarInquiryResData.customerAddress.electricityCost),
                energyBillSaving: 1.4,
                monthlyEnergyCost: solarInquiryResData.customerAddress.avgMonthlyElectricityCost,
                earnCashbackUpTo:
                  parseInt(solarInquiryResData.earnCashbackUpTo) -
                  calculateBatteryCashBack(solarInquiryResData.batteryQuantity),
                treesPlanted: parseInt(solarInquiryResData.treesPlanted),
                wattCost: solarInquiryResData.wattCost,
                savingOver25Years:
                  parseInt(solarInquiryResData.savingOver25Years) +
                  (solarInquiryData.netSolarSystemPrice +
                    solarInquiryResData.batteryQuantity * PER_BATTERY_COST -
                    (BATTERY_FEDERAL_INCENTIVE / 100) *
                      solarInquiryResData.batteryQuantity *
                      PER_BATTERY_COST +
                    solarInquiryResData.batteryQuantity * PER_BATTERY_PAYMENT -
                    calculateBatteryCashBack(solarInquiryResData.batteryQuantity)),
                estSolarSystemSizekW: parseFloat(solarInquiryResData.estSolarSystemSizeKw),
                sizeSmoothing: parseFloat(solarInquiryResData.sizeSmoothing),
                systemPrice:
                  parseInt(solarInquiryResData.systemCost) -
                  calculateBatteryCost(solarInquiryResData.batteryQuantity),
                fedralITCAmount:
                  parseInt(solarInquiryResData.federalItc) -
                  (BATTERY_FEDERAL_INCENTIVE / 100) *
                    solarInquiryResData.batteryQuantity *
                    PER_BATTERY_COST,
                netSolarSystemPrice:
                  parseInt(solarInquiryResData.netSystemCost) -
                  (solarInquiryResData.batteryQuantity * PER_BATTERY_COST -
                    (BATTERY_FEDERAL_INCENTIVE / 100) *
                      solarInquiryResData.batteryQuantity *
                      PER_BATTERY_COST +
                    solarInquiryResData.batteryQuantity * PER_BATTERY_PAYMENT -
                    calculateBatteryCashBack(solarInquiryResData.batteryQuantity)),
                batteryQuantity: solarInquiryResData.batteryQuantity,
                sunroofImageId: '',
                formatedAddress: '',
                addressLatLng: {
                  lat: Number(solarInquiryResData.customerAddress.latitude),
                  lng: Number(solarInquiryResData.customerAddress.longitude)
                },
                phoneNumber: solarInquiryResData.customerAddress.phoneNumber,
                progress: solarInquiryResData.customerAddress.progress
              };

              await updateSolarProposalValues(solarInquiryResData);

              // setUpdatedCalculationBasedOnBattery({
              //   ...updatedSolarInquiryData,
              //   earnCashbackUpTo: parseInt(solarInquiryResData.earnCashbackUpTo),
              //   systemPrice: parseInt(solarInquiryResData.systemCost),
              //   fedralITCAmount: parseInt(solarInquiryResData.federalItc),
              //   netSolarSystemPrice: parseInt(solarInquiryResData.netSystemCost),
              //   savingOver25Years: parseInt(solarInquiryResData.savingOver25Years)
              // });

              setProductQuantity(solarInquiryResData.batteryQuantity);

              setProposalId({
                customerAddressId: solarInquiryResData.customerAddress.id,
                solarProposalId: solarInquiryResData.id
              });

              const prevSolarInquiryData = { ...solarInquiryData };

              if (!updatedSolarInquiryData.phoneNumber && !updatedSolarInquiryData.progress) {
                setIsExistingProposal(true);
                setSolarInquiryData({
                  ...updatedSolarInquiryData,
                  phoneNumber: prevSolarInquiryData.phoneNumber,
                  addressLatLng: !(
                    solarInquiryResData.customerAddress.latitude &&
                    solarInquiryResData.customerAddress.longitude
                  )
                    ? prevSolarInquiryData.addressLatLng
                    : {
                        lat: Number(solarInquiryResData.customerAddress.latitude),
                        lng: Number(solarInquiryResData.customerAddress.longitude)
                      }
                  // electricityCost: 0.2122,
                  // monthlyEnergyCost: 150
                });

                // setActiveStep(multiStapperSteps.indexOf('emailStep') + 1);
                navigate('/proposal');
                // setIsManualSignUp(false);
                setLoading(false);
              } else {
                setIsExistingProposal(true);
                setSolarInquiryData({
                  ...updatedSolarInquiryData,
                  addressLatLng: !(
                    solarInquiryResData.customerAddress.latitude &&
                    solarInquiryResData.customerAddress.longitude
                  )
                    ? prevSolarInquiryData.addressLatLng
                    : updatedSolarInquiryData.addressLatLng
                });

                // setActiveStep(multiStapperSteps.indexOf(updatedSolarInquiryData.progress));
                // setActiveStep(multiStapperSteps.indexOf('emailStep') + 1);
                navigate('/proposal');
                // setIsManualSignUp(false);
                setLoading(false);
              }
            } else {
              openPopover();
              setPopoverParams({
                // title: 'Error',
                content: (
                  <div>
                    Your existing solar proposal address is <br />
                    <p className="font-aeonik-bold my-4">
                      {solarInquiryResData.customerAddress.address}{' '}
                      {solarInquiryResData.customerAddress.city},{' '}
                      {solarInquiryResData.customerAddress.state}{' '}
                      {solarInquiryResData.customerAddress.zipCode}
                    </p>
                    <p className="text-[11px]">
                      If you would like to continue with the same address, please select{' '}
                      <span className="font-aeonik-bold">Continue with Existing Address.</span> If
                      you would like to update your existing address with the new address, please
                      select<span className="font-aeonik-bold"> Update Address. </span>
                    </p>
                  </div>
                ),
                // icon: <BiError size={24} className="fill-[red]" />,
                buttons: [
                  {
                    text: 'Continue with Existing Address',
                    action: () => continueWithExistingAddress(solarInquiryResData, solarInquiryData)
                  },
                  {
                    text: 'Update Address',
                    action: () => updateAddress(solarInquiryResData)
                  }
                ]
              });
              setLoading(false);
            }
          }
        }
      }
      return solarInquiryRes.data.solarProposal[0];
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   // console.log('solarInquiryData.electricityCost :', { solarInquiryData });
  //   if (solarInquiryData.electricityCost && solarInquiryData.monthlyEnergyCost) {
  //     const estSolarSystemSizekW = parseFloat(
  //       (
  //         annualEnergyUsage(solarInquiryData.electricityCost, solarInquiryData.monthlyEnergyCost) /
  //         1.35 /
  //         1000
  //       ).toFixed(1)
  //     );

  //     const sizeSmoothing = (Math.ceil((estSolarSystemSizekW * 1000) / 400) * 400) / 1000;
  //     const cashback = DAYLIGHT_CASHBACK_PPW * sizeSmoothing * 1000;
  //     const daylightCashback = parseInt(cashback / 3);
  //     const systemPrice = -(SYSTEM_PRICE_RATE_PPW * sizeSmoothing * 1000);
  //     // console.log('systemPrice', systemPrice);
  //     const fedralITCAmount = (-systemPrice / 100) * FEDERAL_ITC_RATE;
  //     const netSolarSystemPrice = systemPrice + cashback + fedralITCAmount;
  //     const netSystemPricePPW = -netSolarSystemPrice / sizeSmoothing / 1000;
  //     const treePlanted = ((sizeSmoothing * 1000) / 400) * 10;
  //     if (!isExistingProposal) {
  //       setSolarInquiryData({
  //         ...solarInquiryData,
  //         electricityCost: solarInquiryData.electricityCost,
  //         monthlyEnergyCost: solarInquiryData.monthlyEnergyCost,
  //         // energyBillSaving: parseInt(
  //         //   annualSavingOver25Years(
  //         //     solarInquiryData.electricityCost,
  //         //     annualEnergyUsage(solarInquiryData.electricityCost, solarInquiryData.monthlyEnergyCost),
  //         //     TOTAL_YEARS
  //         //   ) /
  //         //     (25 * 12)
  //         // ),
  //         energyBillSaving: parseInt(
  //           (calculateSavings(
  //             SOLAR_DEGRADATION_FACTOR_PERCENTAGE,
  //             INFLATION_RATE_PERCENTAGE,
  //             TOTAL_YEARS,
  //             solarInquiryData.electricityCost,
  //             annualEnergyUsage(
  //               solarInquiryData.electricityCost,
  //               solarInquiryData.monthlyEnergyCost
  //             )
  //           ) -
  //             netSolarSystemPrice * -1) /
  //             (25 * 12)
  //         ),
  //         earnCashbackUpTo: parseInt(cashback),
  //         wattCost: parseFloat(netSystemPricePPW).toFixed(2),
  //         savingOver25Years: parseInt(
  //           calculateSavings(
  //             SOLAR_DEGRADATION_FACTOR_PERCENTAGE,
  //             INFLATION_RATE_PERCENTAGE,
  //             TOTAL_YEARS,
  //             solarInquiryData.electricityCost,
  //             annualEnergyUsage(
  //               solarInquiryData.electricityCost,
  //               solarInquiryData.monthlyEnergyCost
  //             )
  //           ) -
  //             netSolarSystemPrice * -1
  //         ),
  //         // savingOver25Years: parseInt(
  //         //   annualSavingOver25Years(
  //         //     solarInquiryData.electricityCost,
  //         //     annualEnergyUsage(solarInquiryData.electricityCost, solarInquiryData.monthlyEnergyCost),
  //         //     TOTAL_YEARS
  //         //   )
  //         // ),
  //         treesPlanted: parseInt(treePlanted),
  //         estSolarSystemSizekW,
  //         sizeSmoothing,
  //         systemPrice: systemPrice * -1,
  //         fedralITCAmount,
  //         netSolarSystemPrice: netSolarSystemPrice * -1
  //       });
  //     }
  //   }
  // }, [solarInquiryData.electricityCost, solarInquiryData.monthlyEnergyCost]);

  // useEffect(() => {
  //   if (!isExistingProposal) {
  //     setUpdatedCalculationBasedOnBattery(solarInquiryData);
  //   }
  // }, [solarInquiryData]);

  return (
    // <div className="sm:ml-[130px] ml-[25px] mr-[25px] sm:h-[60vh] h-auto">
    <div className="xl:ml-[130px] sm:ml-[50px] ml-[25px] mr-[25px] h-[60vh]">
      <fieldset>
        <div className="mb-[40px] ">
          <h3 className="sm:text-[32px] text-[24px] leading-[40px] text-primary_black font-forma-djr-text-regular mb-[20px]">
            What’s your email?
          </h3>

          <p className="text-primary_black text-[14px] text-left justify-start md:text-[18px] lg:text-[24px] lg:leading-[32px] leading-1 mb-[16px]">
            We&apos;ll use this to send over a copy of your quote.
          </p>

          <div className=" py-4 p-1 w-full items-center justify-center text-center">
            <InputField
              // label={'Username'}
              type="email"
              placeholder={'name@example.com'}
              className={{
                inputClasses: 'text-lg',
                labelClasses: 'font-forma-djr-mono-regular text-primary_black'
              }}
              name={'email'}
              variants={'static'}
              value={solarInquiryData.email}
              onChange={(e) =>
                setSolarInquiryData({
                  ...solarInquiryData,
                  email: e.currentTarget.value
                })
              }
            />
          </div>
        </div>
        <div className="text-left ml-[5px] my-8">
          <button
            className="flex items-center bg-primary font-forma-djr-text-regular py-3 px-14 text-secondary_white rounded-md text-lg border-primary cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
            disabled={
              !(
                validateEmail(solarInquiryData.email) &&
                !(
                  loading ||
                  solarProposalQueryLoading ||
                  initializeSolarProposalMutationLoading ||
                  updateSolarProposalMutationLoading
                )
              )
            }
            onClick={async () => {
              if (isProduction) {
                await accountCreatedGTM();
                await enterEmailEventPixel({ email: solarInquiryData.email });

                await klaviyoSocialLogin(
                  solarInquiryData.email,
                  'Simple User Email',
                  solarInquiryData.phoneNumber
                );
              }
              await addLeadStep({ email: solarInquiryData.email }, 'emailStep');
              await checkProposalIsExist(solarInquiryData.email);
              await addLeadStep({ solarInquiryData }, 'customDesignStep');
            }}>
            {(loading ||
              solarProposalQueryLoading ||
              initializeSolarProposalMutationLoading ||
              updateSolarProposalMutationLoading) && (
              <CgSpinner size={20} className="animate-spin mr-3 items-center" />
            )}{' '}
            Next
          </button>
        </div>
      </fieldset>
    </div>
  );
};

export default EmailStep;
