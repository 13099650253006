import React, { useContext } from 'react';
import ProposalNavBar from '../SolarProposal/components/ProposalNavBar';
import SolarPanelInstallation from '../../../../../resources/images/SolarPanelInstallation';
import { useSearchParams } from 'react-router-dom';
import { StepperContext } from '../../../../../providers/stepper.provider';

const Success = () => {
  const [searchParams] = useSearchParams();
  const sessionIdParam = searchParams.get('session_id');

  const { redirectToWebsite } = useContext(StepperContext);

  return (
    <div className="pb-[120px]">
      <div className="mx-[120px] my-[24px]">
        <ProposalNavBar />
      </div>
      {/* <div>
        <StatusBar />
      </div> */}
      <div className="flex flex-col max-w-[1178px] mx-auto mt-[60px] justify-between">
        <div className="w-full flex flex-col justify-center items-center">
          <div className=" sm:pt-[20px] pt-[10px] sm:pb-[10px] pb-[10px] m-auto xl:[&>svg]:w-[75%] lg:[&>svg]:w-[100%] [&>svg]:mx-auto [&>svg]:h-auto [&>svg]:w-[220px] h-auto">
            <SolarPanelInstallation />
          </div>

          <p className="xl:text-[40px] md:text-[24px] text-[18px] text-primary leading-[24px] mt-[30px]">
            Your Payment Successfully done.
          </p>
          {sessionIdParam && (
            <>
              <p className="flex flex-col xl:text-[24px] md:text-[18px] text-[10px] text-primary leading-[24px] mt-[30px] mx-[20px] justify-center">
                Your checkout session Id is :
              </p>
              <p className="flex flex-col xl:text-[24px] md:text-[18px] text-[10px] text-primary leading-[24px] mt-[30px] mx-[20px] justify-center">
                <b>{sessionIdParam}</b>
              </p>
            </>
          )}
          <div className="text-left ml-[5px] my-8">
            <button
              className="bg-[#F1D972] font-aeonik-bold py-3 px-14 text-primary rounded-md text-lg border-[#F1D972] cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
              onClick={async () => {
                await redirectToWebsite();
              }}>
              Go back to website
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
