export const FamilyType = {
  Single: 'Single',
  Multi: 'Multi'
};

export const LeadType = {
  SOLAR: 'SOLAR',
  SALES: 'SALES',
  MARKETING: 'MARKETING',
  ONBOARDING: 'ONBOARDING'
};

export const PropertyType = {
  Rent: 'Rent',
  Own: 'Own'
};

export const LeadTag = {
  HIGH_INTENT_SOLAR: 'High Intent Solar'
};
