import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { createContext, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { auth } from '../firebase.config';

export const PhoneNumberContext = createContext();

export const PhoneNumberProvider = ({ children }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isInvalidOtp, setIsInvalidOtp] = useState(false);
  const [countdown, setCountdown] = useState(20);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [sendOTPCounter, setSendOTPCounter] = useState(0);
  const timerIntervalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const recaptchaContainerRef = useRef(null);

  const startTimer = () => {
    setCountdown(20);
    const timerInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(timerInterval);
          setResendDisabled(false);
        }
        return prevCountdown - 1;
      });
    }, 1000);
    timerIntervalRef.current = timerInterval;
  };

  const onSignup = async () => {
    await destroyRecaptchaVerifier();
    if (phoneNumber.length >= 10) {
      setLoading(true);
      //   destroyRecaptchaVerifier();
      //   let appVerifier = recaptchaVerifier.current;
      const appVerifier = new RecaptchaVerifier(
        recaptchaContainerRef.current,
        {
          size: 'invisible',
          callback: (response) => {
            // reCAPTCHA verification success callback
            // console.log('reCAPTCHA verification success');
          },
          'expired-callback': () => {
            // reCAPTCHA verification expired callback
            console.log('reCAPTCHA verification expired');
          }
        },
        auth
      );

      console.log(phoneNumber);
      // const formatPh = '+' + phoneNumber;
      const formatPh = phoneNumber;

      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          // setVerificationId(confirmationResult.verificationId);
          setLoading(false);
          setIsOtpSent(true);
          setIsOtpVerified(false);
          appVerifier.clear();
          // toast.success("OTP sended successfully!");
          console.log('OTP sended successfully!');
          setSendOTPCounter(sendOTPCounter + 1);
          setResendDisabled(true);
          startTimer();
        })
        .catch(async (error) => {
          console.log('error', error.message);

          const logData = {
            url: 'https://firebase.com',
            method: 'GET',
            headers: [],
            payload: { phoneNumber: formatPh },
            response_status: 400,
            response_headers: '[]',
            response_body: error && error.message ? error.message : '',
            client: 'Web',
            client_os: navigator.platform ? navigator.platform : 'browser'
          };
          // createLog(logData);

          setLoading(false);
          await destroyRecaptchaVerifier();
          // recaptchaVerifier.current.render();

          switch (error.code) {
            case 'auth/quota-exceeded':
              toast.error('Exceeded Quota');
              // navigate(0);
              break;
            case 'auth/captcha-check-failed':
              toast.error('Captcha check failed');
              break;
            case 'auth/timeout':
              toast.error('TimeOut');
              break;
            case 'auth/internal-error':
              toast.error('Internal Error');
              // navigate(0);
              break;
            case 'auth/invalid-phone-number':
              toast.error('Invalid Phone Number');
              break;
            default:
          }
        });
    }
  };

  const destroyRecaptchaVerifier = () => {
    // Create a new empty container for Recaptcha
    const newRecaptchaContainer = document.createElement('div');
    newRecaptchaContainer.id = 'recaptcha-container';

    // Check if recaptchaContainerRef.current has a parent node before replacing it
    if (recaptchaContainerRef.current.parentNode) {
      recaptchaContainerRef.current.parentNode.insertBefore(
        newRecaptchaContainer,
        recaptchaContainerRef.current
      );
      // recaptchaContainerRef.current.parentNode.removeChild(recaptchaContainerRef.current);
      recaptchaContainerRef.current = newRecaptchaContainer;
    }
  };

  return (
    <PhoneNumberContext.Provider
      value={{
        isValidPhoneNumber,
        isOtpSent,
        isOtpVerified,
        isInvalidOtp,
        resendDisabled,
        countdown,
        sendOTPCounter,
        recaptchaContainerRef,
        phoneNumber,
        loading,
        setIsValidPhoneNumber,
        setIsOtpSent,
        setIsOtpVerified,
        setIsInvalidOtp,
        startTimer,
        setResendDisabled,
        setCountdown,
        setSendOTPCounter,
        setPhoneNumber,
        setLoading,
        onSignup,
        destroyRecaptchaVerifier
      }}>
      {children}
    </PhoneNumberContext.Provider>
  );
};
