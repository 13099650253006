import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SOLAR_PROPOSAL_BY_ID_QUERY } from '../../../../../GraphQL/Queries';
import { useLazyQuery } from '@apollo/client';
import { StepperContext } from '../../../../../providers/stepper.provider';
import Proposal from './Proposal';

const ViewProposal = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const proposalIdParam = params.get('id');

  const {
    setSolarInquiryData,
    setSolarProposalAdditionalDetails,
    setProposalId,
    setIsLoading,
    isLoading
  } = useContext(StepperContext);

  //   const [loading, setLoading] = useState(false);

  //   console.log('proposalIdParam :', proposalIdParam);

  const [
    fetchSolarProposalDataById,
    { loading: solarProposalByIdQueryLoading, data: solarProposalByIdQueryData }
  ] = useLazyQuery(SOLAR_PROPOSAL_BY_ID_QUERY);

  const fetchSolarInquiry = async (proposalId) => {
    try {
      setIsLoading(true);
      const solarInquiryRes = await fetchSolarProposalDataById({
        variables: {
          solarProposalId: proposalId
        }
      });
      if (solarInquiryRes) {
        const solarInquiryResData = { ...solarInquiryRes.data.solarProposalById };

        const updatedSolarInquiryData = {
          address: solarInquiryResData.customerAddress.address,
          city: solarInquiryResData.customerAddress.city,
          state: solarInquiryResData.customerAddress.state,
          zipCode: solarInquiryResData.customerAddress.zipCode,
          email: solarInquiryResData.customerAddress.email,
          electricityCost: parseFloat(solarInquiryResData.customerAddress.electricityCost),
          energyBillSaving: 1.4,
          monthlyEnergyCost: solarInquiryResData.customerAddress.avgMonthlyElectricityCost,
          earnCashbackUpTo: parseInt(solarInquiryResData.earnCashbackUpTo),
          treesPlanted: parseInt(solarInquiryResData.treesPlanted),
          wattCost: solarInquiryResData.wattCost,
          // savingOver25Years: parseInt(responseData.savingOver25Years),
          // estSolarSystemSizekW: parseFloat(responseData.estSolarSystemSizeKw),
          sizeSmoothing: parseFloat(solarInquiryResData.sizeSmoothing),
          systemPrice: parseInt(solarInquiryResData.systemCost),
          // fedralITCAmount: parseInt(responseData.federalItc),
          batteryQuantity: solarInquiryResData.batteryQuantity,
          addressLatLng: {
            lat: Number(solarInquiryResData.customerAddress.latitude),
            lng: Number(solarInquiryResData.customerAddress.longitude)
          },
          phoneNumber: solarInquiryResData.customerAddress.phoneNumber,
          progress: solarInquiryResData.customerAddress.progress,
          heatPump: solarInquiryResData.heatPump,
          energyEfficiency: solarInquiryResData.energyEfficiency,
          sunroofImageId: '',
          formatedAddress: ''
          // netSolarSystemPrice: responseData.netSystemCost
        };

        const updatedAdditionalValues = {
          batteryBrand: solarInquiryResData.batteryBrand,
          // futureAddOns: JSON.parse(responseData.futureAddOnsString),
          inverter: solarInquiryResData.inverter,
          solarOffset: parseInt(solarInquiryResData.solarOffset),
          systemCost: parseInt(solarInquiryResData.systemCost),
          federalItc: parseInt(solarInquiryResData.federalItc),
          netSystemCost: parseInt(solarInquiryResData.netSystemCost),
          wattCost: parseFloat(solarInquiryResData.wattCost),
          financeSystemCost: parseFloat(solarInquiryResData.financeSystemCost),
          monthlyInstallment: parseFloat(solarInquiryResData.monthlyInstallment),
          monthlyEnergyCost: parseFloat(solarInquiryResData.monthlyEnergyCost),
          energyBillingSaving: parseFloat(solarInquiryResData.energyBillingSaving),
          financeNetSystemCost: parseFloat(solarInquiryResData.financeNetSystemCost),
          financeWattCost: parseFloat(solarInquiryResData.financeWattCost),
          estimatedAnnualIncrease: parseInt(solarInquiryResData.estimatedAnnualIncrease),
          numberOfPanels: parseInt(solarInquiryResData.numberOfPanels),
          savingOver25Years: parseInt(solarInquiryResData.savingOver25Years),
          srecPreBuyDiscount: parseInt(solarInquiryResData.srecPreBuyDiscount),
          federalLocalStateIncentives: parseInt(solarInquiryResData.federalLocalStateIncentives)
        };

        setSolarInquiryData({ ...updatedSolarInquiryData });

        setProposalId({
          customerAddressId: solarInquiryResData.customerAddress.id,
          solarProposalId: solarInquiryResData.id
        });

        setSolarProposalAdditionalDetails({
          ...updatedAdditionalValues
        });
        setIsLoading(false);
      }
      return solarInquiryRes.data.solarProposalById;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      //  TODO: on 404 or 400 return to / url
      window.location.href = '/';
    }
  };

  useEffect(() => {
    if (!proposalIdParam) return;

    fetchSolarInquiry(proposalIdParam);
  }, [proposalIdParam]);

  return (
    <>
      {!isLoading && (
        <div>
          <Proposal viewOnly={true} />
        </div>
      )}
    </>
  );
};

export default ViewProposal;
