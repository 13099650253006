import React, { useContext, useEffect, useState } from 'react';
import ProposalNavBar from './components/ProposalNavBar';
import StatusBar from './components/StatusBar';
import dummyMap from '../../../../../assets/images/map-dummy2.png';
import SolarCell from '../../../../../resources/images/SolarCell';
import RadioButtonTab from '../../../../../components/RadioButton/RadioButtonTab';
import Toggle from '../../../../../components/Toggle/Toggle';
import Dropdown from '../../../../../components/Dropdown/Dropdown';
import Dropdown2 from '../../../../../components/Dropdown/Dropdown2';
import GoogleSunroofMap from '../../../../../components/GoogleSunroofMap/GoogleSunroofMap';
import { StepperContext } from '../../../../../providers/stepper.provider';
import { calculatePMT, calculateSavings } from '../../../../../utils/utils';
import {
  GET_PROPOSAL_BOOKING_PAYMENT_URL,
  UPDATE_SOLAR_PROPOSAL_MUTATION
} from '../../../../../GraphQL/Mutations';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../../providers/auth.provider';
import Counter from '../../../../../components/Counter/Counter';
import { StripeContext } from '../../../../../providers/stripe.provider';

const options = [
  { id: 1, value: '1', name: 'Premium Standard' }
  // { id: 2, value: '2', name: 'Premium Standard-2' }
];

const Proposal = ({ viewOnly = false }) => {
  const navigate = useNavigate();
  const [paymentOption, setPaymentOption] = useState('Cash');
  const [isChecked, setIsChecked] = useState(false);
  // const [updatedCalculationBasedOnBattery, setUpdatedCalculationBasedOnBattery] = useState(null);
  const { requestTokenResponse } = useContext(AuthContext);

  const {
    solarInquiryData,
    setSolarInquiryData,
    solarConfiguration,
    isExistingProposal,
    setProductQuantity,
    proposalId,
    updatedCalculationBasedOnBattery,
    setUpdatedCalculationBasedOnBattery,
    solarProposalAdditionalDetails,
    setSolarProposalAdditionalDetails
  } = useContext(StepperContext);

  const { bookingAmount } = useContext(StripeContext);

  // For monthly Rate
  const FINANCE_RATE = solarConfiguration && parseFloat(solarConfiguration.financeRate); // 2.99%;
  const TERM_IN_YEARS = solarConfiguration && parseInt(solarConfiguration.termInYears);
  const PER_BATTERY_COST =
    solarConfiguration && parseFloat(solarConfiguration.batteryCostPerBattery);
  const PER_BATTERY_PAYMENT =
    solarConfiguration && parseFloat(solarConfiguration.batteryPaymentPerBattery);
  const BATTERY_FEDERAL_INCENTIVE =
    solarConfiguration && parseFloat(solarConfiguration.batteryItcRate); // 30%
  const PER_BATTERY_CASHBACK_RATE =
    solarConfiguration && parseFloat(solarConfiguration.daylightCashbackRate); // 12.50%

  const INFLATION_RATE_PERCENTAGE =
    solarConfiguration && parseFloat(solarConfiguration.inflationRate); // increase by 3% per year
  const SOLAR_DEGRADATION_FACTOR_PERCENTAGE =
    solarConfiguration && parseFloat(solarConfiguration.solarDegradationFactor); // decrease 0.05% per year
  // const TOTAL_YEARS = parseInt(solarConfiguration.termInYears);
  const TOTAL_YEARS = 25;
  const DAYLIGHT_CASHBACK_PPW =
    solarConfiguration && parseFloat(solarConfiguration.daylightCashbackPpw);
  const SYSTEM_PRICE_RATE_PPW =
    solarConfiguration && parseFloat(solarConfiguration.systemPriceRate);
  const FEDERAL_ITC_RATE = solarConfiguration && parseFloat(solarConfiguration.federalItcRate); // 30%;

  const [updateSolarProposalMutation, { loading: updateSolarProposalMutationLoading }] =
    useMutation(UPDATE_SOLAR_PROPOSAL_MUTATION);
  const [getProposalBookingPaymentUrl, { loading: getProposalBookingPaymentUrlLoading }] =
    useMutation(GET_PROPOSAL_BOOKING_PAYMENT_URL);

  const handleCheckboxChange = (e) => {
    // console.log('e.target.checked :', e.target.checked);

    if (e.target.checked) {
      // console.log('titi');
      updateBatterySlider(1, requestTokenResponse.token);
    } else {
      // console.log('hhuhuhu');
      updateBatterySlider(0, requestTokenResponse.token);
    }
    setIsChecked(e.target.checked);
  };

  const handleBatteryQuantityCounter = async (quantity) => {
    // updateBatterySlider(quantity, requestTokenResponse.token);
    console.log('proposalId :', proposalId);
    await updateProposalNew({
      addressInput: {
        id: proposalId.customerAddressId
      },
      solarProposalInput: {
        id: proposalId.solarProposalId,
        batteryQuantity: quantity
      }
    });
  };

  const handleSolarPanelCounter = async (quantity) => {
    console.log('solarpanelquantity :', quantity);
    await updateProposalNew({
      addressInput: {
        id: proposalId.customerAddressId
      },
      solarProposalInput: {
        id: proposalId.solarProposalId,
        numberOfPanels: String(quantity)
      }
    });
  };

  const updateSolarProposalValues = async (existingSolarResponse) => {
    const existingSolarRes = existingSolarResponse.data.updateSolarProposal.success;
    const updatedSolarInquiryData = {
      address: existingSolarRes.customerAddress.address,
      city: existingSolarRes.customerAddress.city,
      state: existingSolarRes.customerAddress.state,
      zipCode: existingSolarRes.customerAddress.zipCode,
      email: existingSolarRes.customerAddress.email,
      electricityCost: parseFloat(existingSolarRes.customerAddress.electricityCost),
      energyBillSaving: 1.4,
      monthlyEnergyCost: existingSolarRes.customerAddress.avgMonthlyElectricityCost,
      earnCashbackUpTo: parseInt(existingSolarRes.earnCashbackUpTo),
      treesPlanted: parseInt(existingSolarRes.treesPlanted),
      wattCost: existingSolarRes.wattCost,
      // savingOver25Years: parseInt(responseData.savingOver25Years),
      // estSolarSystemSizekW: parseFloat(responseData.estSolarSystemSizeKw),
      sizeSmoothing: parseFloat(existingSolarRes.sizeSmoothing),
      systemPrice: parseInt(existingSolarRes.systemCost),
      // fedralITCAmount: parseInt(responseData.federalItc),
      batteryQuantity: existingSolarRes.batteryQuantity,
      addressLatLng: {
        lat: Number(existingSolarRes.customerAddress.latitude),
        lng: Number(existingSolarRes.customerAddress.longitude)
      },
      phoneNumber: existingSolarRes.customerAddress.phoneNumber,
      progress: existingSolarRes.customerAddress.progress,
      heatPump: existingSolarRes.heatPump,
      energyEfficiency: existingSolarRes.energyEfficiency,
      sunroofImageId: '',
      formatedAddress: solarInquiryData.formatedAddress
    };

    const updatedAdditionalValues = {
      batteryBrand: existingSolarRes.batteryBrand,
      // futureAddOns: JSON.parse(existingSolarRes.futureAddOnsString),
      inverter: existingSolarRes.inverter,
      solarOffset: parseInt(existingSolarRes.solarOffset),
      systemCost: parseInt(existingSolarRes.systemCost),
      federalItc: parseInt(existingSolarRes.federalItc),
      netSystemCost: parseInt(existingSolarRes.netSystemCost),
      wattCost: Number(existingSolarRes.wattCost),
      financeSystemCost: parseFloat(existingSolarRes.financeSystemCost),
      monthlyInstallment: parseFloat(existingSolarRes.monthlyInstallment),
      monthlyEnergyCost: parseFloat(existingSolarRes.monthlyEnergyCost),
      energyBillingSaving: parseFloat(existingSolarRes.energyBillingSaving),
      financeNetSystemCost: parseFloat(existingSolarRes.financeNetSystemCost),
      financeWattCost: parseFloat(existingSolarRes.financeWattCost),
      estimatedAnnualIncrease: parseInt(existingSolarRes.estimatedAnnualIncrease),
      numberOfPanels: parseInt(existingSolarRes.numberOfPanels),
      savingOver25Years: parseInt(existingSolarRes.savingOver25Years),
      srecPreBuyDiscount: parseInt(existingSolarRes.srecPreBuyDiscount),
      federalLocalStateIncentives: parseInt(existingSolarRes.federalLocalStateIncentives)
    };

    setSolarProposalAdditionalDetails({
      ...updatedAdditionalValues
    });

    setSolarInquiryData({ ...updatedSolarInquiryData });
  };

  const updateProposalNew = async (data) => {
    try {
      const solarInquiryResponse = await updateSolarProposalMutation({
        variables: data
      });

      console.log('solarInquiryResponse from update method');
      // await addLeadStep(
      //   { solarInquiryData: solarInquiryResponse.data.updateSolarProposal.success },
      //   'updateAddress'
      // );

      await updateSolarProposalValues(solarInquiryResponse);

      return solarInquiryResponse;
    } catch (error) {
      console.log(error);
    }
  };

  const annualEnergyUsage = (electricityRate, monthlyEnergyCost) => {
    const annualEnergyUsage = Math.ceil(monthlyEnergyCost / electricityRate) * 12;
    return annualEnergyUsage;
  };

  const calculateBatteryCost = (quantity) => {
    return quantity * PER_BATTERY_COST + quantity * PER_BATTERY_PAYMENT;
  };

  const calculateBatteryCashBack = (quantity) => {
    return quantity * ((PER_BATTERY_COST * PER_BATTERY_CASHBACK_RATE) / 100);
  };

  const updateBatterySlider = async (quantity, proposalIdObj = proposalId) => {
    // console.log('solarInquiryData :', solarInquiryData);
    const fedralIncentiveForBattery =
      (BATTERY_FEDERAL_INCENTIVE / 100) * quantity * PER_BATTERY_COST;

    setProductQuantity(quantity);
    setUpdatedCalculationBasedOnBattery({
      ...solarInquiryData,
      earnCashbackUpTo: solarInquiryData.earnCashbackUpTo + calculateBatteryCashBack(quantity),
      systemPrice: solarInquiryData.systemPrice + calculateBatteryCost(quantity),
      fedralITCAmount:
        solarInquiryData.fedralITCAmount +
        (BATTERY_FEDERAL_INCENTIVE / 100) * quantity * PER_BATTERY_COST,
      netSolarSystemPrice:
        solarInquiryData.netSolarSystemPrice +
        quantity * PER_BATTERY_COST -
        (BATTERY_FEDERAL_INCENTIVE / 100) * quantity * PER_BATTERY_COST +
        quantity * PER_BATTERY_PAYMENT -
        calculateBatteryCashBack(quantity),
      savingOver25Years: parseInt(
        (
          calculateSavings(
            SOLAR_DEGRADATION_FACTOR_PERCENTAGE,
            INFLATION_RATE_PERCENTAGE,
            TOTAL_YEARS,
            solarInquiryData.electricityCost,
            annualEnergyUsage(solarInquiryData.electricityCost, solarInquiryData.monthlyEnergyCost)
          ) -
          (solarInquiryData.netSolarSystemPrice +
            quantity * PER_BATTERY_COST -
            (BATTERY_FEDERAL_INCENTIVE / 100) * quantity * PER_BATTERY_COST +
            quantity * PER_BATTERY_PAYMENT -
            calculateBatteryCashBack(quantity))
        ).toFixed(0)
      ),
      batteryQuantity: quantity,
      progress: 'customDesignStep'
    });

    const updateProposal = async (data, proposalIdObj = proposalId) => {
      try {
        const solarInquiryResponse = await updateSolarProposalMutation({
          variables: {
            addressInput: {
              id: proposalIdObj.customerAddressId,
              // email: solarInquiryData.email,
              firstName: solarInquiryData.firstName,
              lastName: solarInquiryData.lastName,
              address: solarInquiryData.address,
              city: solarInquiryData.city,
              state: solarInquiryData.state,
              zipCode: solarInquiryData.zipCode,
              phoneNumber: solarInquiryData.phoneNumber,
              // familyType: FamilyType.Single,
              longitude: solarInquiryData.addressLatLng.lng,
              latitude: solarInquiryData.addressLatLng.lat,
              electricityCost: solarInquiryData.electricityCost,
              avgMonthlyElectricityCost: parseFloat(solarInquiryData.monthlyEnergyCost),
              progress: data.progress,
              onHome: solarInquiryData.onHome
            },
            solarProposalInput: {
              id: proposalIdObj.solarProposalId,
              earnCashbackUpTo: data.earnCashbackUpTo,
              estSolarSystemSizeKw: data.estSolarSystemSizekW,
              federalItc: parseFloat(data.fedralITCAmount.toFixed(2)),
              savingOver25Years: data.savingOver25Years,
              sizeSmoothing: data.sizeSmoothing,
              // systemSize: solarInquiryData.earnCashbackUpTo,
              treesPlanted: data.treesPlanted,
              wattCost: data.wattCost,
              systemCost: parseFloat(data.systemPrice.toFixed(2)),
              netSystemCost: parseFloat(data.netSolarSystemPrice.toFixed(2)),
              batteryQuantity: data.batteryQuantity
            }
          }
        });
        // await addLeadStep(
        //   { solarInquiryData: solarInquiryResponse.data.updateSolarProposal.success },
        //   'updateAddress'
        // );

        return solarInquiryResponse;
      } catch (error) {
        console.log(error);
      }
    };

    const updatedSolarInquiry = await updateProposal(
      {
        ...solarInquiryData,
        earnCashbackUpTo: solarInquiryData.earnCashbackUpTo + calculateBatteryCashBack(quantity),
        systemPrice: solarInquiryData.systemPrice + calculateBatteryCost(quantity),
        fedralITCAmount:
          solarInquiryData.fedralITCAmount +
          (BATTERY_FEDERAL_INCENTIVE / 100) * quantity * PER_BATTERY_COST,
        netSolarSystemPrice:
          solarInquiryData.netSolarSystemPrice +
          quantity * PER_BATTERY_COST -
          (BATTERY_FEDERAL_INCENTIVE / 100) * quantity * PER_BATTERY_COST +
          quantity * PER_BATTERY_PAYMENT -
          calculateBatteryCashBack(quantity),

        savingOver25Years: parseInt(
          (
            calculateSavings(
              SOLAR_DEGRADATION_FACTOR_PERCENTAGE,
              INFLATION_RATE_PERCENTAGE,
              TOTAL_YEARS,
              solarInquiryData.electricityCost,
              annualEnergyUsage(
                solarInquiryData.electricityCost,
                solarInquiryData.monthlyEnergyCost
              )
            ) -
            (solarInquiryData.netSolarSystemPrice +
              quantity * PER_BATTERY_COST -
              (BATTERY_FEDERAL_INCENTIVE / 100) * quantity * PER_BATTERY_COST +
              quantity * PER_BATTERY_PAYMENT -
              calculateBatteryCashBack(quantity))
          ).toFixed(0)
        ),
        batteryQuantity: quantity,
        progress: 'customDesignStep'
      },
      proposalIdObj
    );

    return updatedSolarInquiry;
  };

  const handleStripeCheckout = async () => {
    // console.log('ffff');
    try {
      const proposalBookingPaymentUrl = await getProposalBookingPaymentUrl({
        variables: {
          solarProposalId: proposalId.solarProposalId
        }
      });

      // console.log(
      //   'proposalBookingPaymentUrl :',
      //   proposalBookingPaymentUrl.data.getProposalBookingPaymentUrl.url
      // );
      // return (window.location.href =
      //   proposalBookingPaymentUrl.data.getProposalBookingPaymentUrl.url);
      const paymentUrl = proposalBookingPaymentUrl.data.getProposalBookingPaymentUrl.url;
      return window.open(paymentUrl, '_blank');
    } catch (error) {}
  };

  useEffect(() => {
    console.log('viewOnly :', viewOnly);
    if (!(solarInquiryData && solarInquiryData.address && proposalId && solarConfiguration)) {
      // navigate('/', { replace: true });
      if (!viewOnly) {
        window.location.href = '/';
      }
    }
  }, []);

  console.log('solarProposalAdditionalDetails :::::::: ', solarProposalAdditionalDetails);

  return (
    <div className="pb-[120px]">
      <div className="mx-[120px] my-[24px]">
        <ProposalNavBar />
      </div>
      <div>
        <StatusBar />
      </div>
      <div className="flex sm:flex-row flex-col max-w-[1178px] mx-auto mt-[60px] justify-between">
        <div className="sm:max-w-[40%] w-[96%] ml-[8px] xl:mr-[32px] mr-[8px]">
          <div className="flex flex-col gap-[10px] mb-[20px]">
            <p className="text-[32px] font-forma-djr-text-regular leading-[40px] text-primary_black">
              Daylight home power system initial estimate for {solarInquiryData.firstName}
            </p>
            <span className="text-[24px] font-forma-djr-mono-regular leading-[32px] text-primary_black opacity-30">
              {/* 115 East 122nd Street, New York, NY, 10035 */}
              {solarInquiryData.address &&
                solarInquiryData.address +
                  `, ` +
                  solarInquiryData.city +
                  `, ` +
                  solarInquiryData.state +
                  `, ` +
                  solarInquiryData.zipCode}
            </span>
          </div>

          <div className="max-w-[100%] h-[486px] mb-[20px]">
            {/* <img src={dummyMap} alt="dummyMap" width={486} height={486} className="rounded-[8px]" /> */}
            <GoogleSunroofMap
              lat={solarInquiryData.addressLatLng.lat}
              lng={solarInquiryData.addressLatLng.lng}
            />
          </div>

          <div className="flex flex-col justify-between items-center rounded-[8px] border-[1px] border-solid border-primary_black">
            {/* {paymentOption === 'Finance' && (
              <div className="text-[22px] leading-[28px] w-full text-primary bg-[#F0EEFC] rounded-t-[8px] px-[34px] py-[20px] mb-[20px] text-left border-b-[1px] border-solid border-primary">
                Sytem specifications
              </div>
            )} */}
            <ul className={`text-primary_black w-full  px-[34px] py-[23px]`}>
              <li className="flex flex-row justify-between items-center mb-[15px] ">
                <p className="text-[16px] leading-[24px] font-forma-djr-text-regular">
                  Estimated annual production
                </p>
                <span className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                  {solarProposalAdditionalDetails.estimatedAnnualIncrease.toLocaleString('en-US')}
                  kWh
                </span>
              </li>
              <li className="flex flex-row justify-between items-center mb-[15px] ">
                <p className="text-[16px] leading-[24px] font-forma-djr-text-regular">
                  Number of solar panels
                </p>
                <span className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                  {/* {solarProposalAdditionalDetails.numberOfPanels} */}

                  {!viewOnly ? (
                    <Counter
                      minValue={1}
                      onChange={handleSolarPanelCounter}
                      defaultValue={Number(solarProposalAdditionalDetails.numberOfPanels)}
                    />
                  ) : (
                    Number(solarProposalAdditionalDetails.numberOfPanels)
                  )}
                </span>
              </li>
              <li className="flex flex-row justify-between items-center mb-[15px]">
                <p className="text-[16px] leading-[24px] font-forma-djr-text-regular">
                  Energy consumption offset <br />
                  <span className="text-[14px] leading-[20px] font-forma-djr-text-regular text-primary_black opacity-30">
                    We can adjust this up or down based on your goals.
                  </span>
                </p>
                <span className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                  {solarProposalAdditionalDetails.solarOffset}%
                </span>
              </li>
              <li className="flex flex-row justify-between items-center mb-[15px]">
                <p className="text-[16px] leading-[24px] font-forma-djr-text-regular">
                  Equipment package
                </p>
              </li>
              <li className="flex flex-row justify-between items-center mb-[15px]">
                <Dropdown2
                  options={options}
                  defaultSelectedOption={{ id: 1, value: '1', name: 'Premium Standard' }}
                  onChange={(e) => undefined}
                />
              </li>
              <li className="flex flex-row justify-between items-center mb-[15px]">
                <p className="text-[16px] leading-[24px] font-forma-djr-text-regular">
                  Solar panels: Phono 400 watt
                  <br />
                  <span className="text-[14px] leading-[20px] font-forma-djr-text-regular text-primary_black opacity-30">
                    Tier 1, all black solar panels
                  </span>
                </p>
              </li>
              <li className="flex flex-row justify-between items-center mb-[15px]">
                <p className="text-[16px] leading-[24px] font-forma-djr-text-regular">
                  Inverter: {solarProposalAdditionalDetails.inverter}
                </p>
              </li>
              <li className="flex flex-row justify-between items-center mb-[15px]">
                <p className="text-[16px] leading-[24px] font-forma-djr-text-regular">
                  Add a backup battery <br />
                  <span className="text-[14px] leading-[20px] font-forma-djr-text-regular text-primary_black opacity-30">
                    {solarProposalAdditionalDetails.batteryBrand}
                  </span>
                </p>
                <span className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                  {!viewOnly ? (
                    <Counter
                      maxValue={10}
                      onChange={handleBatteryQuantityCounter}
                      defaultValue={solarInquiryData.batteryQuantity}
                    />
                  ) : (
                    Number(solarInquiryData.batteryQuantity)
                  )}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="sm:max-w-[60%] w-[96%] xl:ml-[32px] ml-[8px] mr-[8px]">
          <div className="mb-[20px] sm:mt-0 mt-[20px] flex flex-row justify-between items-center bg-tertiary xl:px-[65px] lg:px-[45px] md:px-[25px] sm:px-[25px] px-[15px] py-[23px] rounded-[8px] border-[1px] border-solid border-primary_black gap-[55px]">
            <p className="text-[57px] leading-[24px] text-primary_black">
              {/* {updatedCalculationBasedOnBattery.netSolarSystemPrice &&
              solarInquiryData.electricityCost &&
              solarInquiryData.monthlyEnergyCost
                ? `$` +
                  parseInt(
                    (
                      calculateSavings(
                        SOLAR_DEGRADATION_FACTOR_PERCENTAGE,
                        INFLATION_RATE_PERCENTAGE,
                        TOTAL_YEARS,
                        solarInquiryData.electricityCost,
                        annualEnergyUsage(
                          solarInquiryData.electricityCost,
                          solarInquiryData.monthlyEnergyCost
                        )
                      ) - updatedCalculationBasedOnBattery.netSolarSystemPrice
                    ).toFixed(0)
                  ).toLocaleString('en-US')
                : `-`} */}
              {/* {solarInquiryData.savingOver25Years} */}

              {/* {`$` + solarInquiryData.savingOver25Years.toLocaleString('en-US')} */}
              {`$` + solarProposalAdditionalDetails.savingOver25Years.toLocaleString('en-US')}
              {/* $53,000  */}

              {/* {updatedCalculationBasedOnBattery &&
              updatedCalculationBasedOnBattery.netSolarSystemPrice &&
              solarInquiryData.electricityCost &&
              solarInquiryData.monthlyEnergyCost
                ? `$` +
                  parseInt(
                    (
                      calculateSavings(
                        SOLAR_DEGRADATION_FACTOR_PERCENTAGE,
                        INFLATION_RATE_PERCENTAGE,
                        TOTAL_YEARS,
                        solarInquiryData.electricityCost,
                        annualEnergyUsage(
                          solarInquiryData.electricityCost,
                          solarInquiryData.monthlyEnergyCost
                        )
                      ) - updatedCalculationBasedOnBattery.netSolarSystemPrice
                    ).toFixed(0)
                  ).toLocaleString('en-US')
                : `-`} */}
              <br />
              <span className="text-[16px] leading-[24px] font-forma-djr-text-regular text-primary_black mt-[5px]">
                Total energy savings with solar
              </span>
            </p>
            <div className="[&>svg]:w-full">
              <SolarCell />
            </div>
          </div>

          <div className="mb-[20px] items-center rounded-[8px] border-[1px] border-solid border-primary_black gap-[55px]">
            <ul className="justify-center  items-start text-primary_black w-full px-[24px] pt-[15px]">
              <li className="font-forma-djr-text-regular  bg-[#F1F1F1] text-center lg:text-[28px] text-[20px] p-[4px] justify-center border-2 border-primary_black border-solid rounded-[8px] w-full">
                <ul className="grid w-full  md:grid-cols-2 ">
                  <li className="rounded-[8px]">
                    <RadioButtonTab
                      id="bordered-radio-2"
                      value="Cash"
                      onChange={(e) => setPaymentOption(e.currentTarget.value)}
                      name="paymentOption"
                      checked={paymentOption === 'Cash'}
                      label="Cash"
                    />
                  </li>
                  <li className="rounded-[8px]">
                    <RadioButtonTab
                      id="bordered-radio-1"
                      value="Finance"
                      onChange={(e) => setPaymentOption(e.currentTarget.value)}
                      name="paymentOption"
                      checked={paymentOption === 'Finance'}
                      label="Financing"
                    />
                  </li>
                </ul>
              </li>

              <li className="font-forma-djr-text-regular text-center lg:text-[28px] text-[20px] m-[3px] mt-[15px] border-b-[1px] border-tertiary border-solid pb-[15px]">
                <div className="flex flex-row justify-between items-center mb-[10px] py-[5px]">
                  <p className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                    {solarInquiryData.sizeSmoothing} kW solar system
                  </p>
                  <span className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                    {paymentOption === 'Cash'
                      ? `$` +
                        String(solarProposalAdditionalDetails.systemCost).toLocaleString('en-US')
                      : `$` +
                        String(solarProposalAdditionalDetails.financeSystemCost).toLocaleString(
                          'en-US'
                        )}
                    {/* $19,380 */}
                  </span>
                </div>
                <ul className="text-primary_black text-[16px] leading-2 list-disc opacity-[0.5] text-left ml-[50px]">
                  <li>Full system installation</li>
                  <li>25-year manufacturer warranty on equipment</li>
                  <li>Includes taxes and fees</li>
                </ul>
              </li>

              <li className="font-forma-djr-text-regular text-center lg:text-[28px] text-[20px] m-[3px] mt-[15px] border-b-[1px] border-[#F0EEFC] border-solid pb-[15px]">
                <div className="flex flex-row justify-between items-center mb-[10px] py-[5px]">
                  <p className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                    Daylight member services
                  </p>
                  <span className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                    Included
                  </span>
                </div>
                <ul className="text-primary_black text-[16px] leading-2 list-disc opacity-[0.5] text-left ml-[50px]">
                  <li>24/7 performance monitoring</li>
                  <li>Biannual deep cleaning</li>
                  <li>Daylight mobile app</li>
                  <li>Hassle-free repair & replacement management</li>
                  <li>10-year roof penetration warranty</li>
                </ul>
              </li>
              {paymentOption === 'Cash' && (
                <>
                  {!isNaN(solarProposalAdditionalDetails.srecPreBuyDiscount) &&
                    solarProposalAdditionalDetails.srecPreBuyDiscount !== 0 && (
                      <li className="font-forma-djr-text-regular text-center lg:text-[28px] text-[20px] m-[3px] mt-[15px]">
                        <div className="flex flex-row justify-between items-center mb-[10px] py-[5px]">
                          <p className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                            Daylight SREC discount
                          </p>
                          <span className="text-[18px] text-secondary_green leading-[18px] font-forma-djr-text-regular">
                            {`-$` +
                              String(
                                solarProposalAdditionalDetails.srecPreBuyDiscount
                              ).toLocaleString('en-US')}
                          </span>
                        </div>
                      </li>
                    )}

                  <li className="font-forma-djr-text-regular text-center lg:text-[28px] text-[20px] m-[3px] mt-[15px]">
                    <div className="flex flex-row justify-between items-center mb-[10px] py-[5px]">
                      <p className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                        Federal tax credit
                      </p>
                      <span className="text-[18px] text-secondary_green leading-[18px] font-forma-djr-text-regular">
                        {/* -$4,651 */}
                        {/* {`-$` + solarInquiryData.fedralITCAmount.toLocaleString('en-US')} */}
                        {`-$` +
                          String(solarProposalAdditionalDetails.federalItc).toLocaleString('en-US')}
                        {/* {updatedCalculationBasedOnBattery &&
                        updatedCalculationBasedOnBattery.fedralITCAmount
                          ? `-$` +
                            updatedCalculationBasedOnBattery.fedralITCAmount.toLocaleString('en-US')
                          : `-`} */}
                      </span>
                    </div>
                  </li>

                  {!isNaN(solarProposalAdditionalDetails.federalLocalStateIncentives) &&
                    solarProposalAdditionalDetails.federalLocalStateIncentives !== 0 && (
                      <li className="font-forma-djr-text-regular text-center lg:text-[28px] text-[20px] m-[3px] mt-[15px]">
                        <div className="flex flex-row justify-between items-center mb-[10px] py-[5px]">
                          <p className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                            Total State & Local incentives
                          </p>
                          <span className="text-[18px] text-secondary_green leading-[18px] font-forma-djr-text-regular">
                            {`-$` +
                              String(
                                solarProposalAdditionalDetails.federalLocalStateIncentives
                              ).toLocaleString('en-US')}
                          </span>
                        </div>
                      </li>
                    )}
                </>
              )}
              {paymentOption === 'Finance' && (
                <>
                  <li className="font-forma-djr-text-regular text-center lg:text-[28px] text-[20px] m-[3px] mt-[15px]">
                    <div className="flex flex-row justify-between items-center mb-[10px] py-[5px]">
                      <p className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                        Monthly payment
                      </p>
                      <span className="text-[18px] text-primary_black leading-[18px] font-forma-djr-text-regular">
                        {/* $102/mo */}
                        {/* {solarInquiryData.netSolarSystemPrice
                          ? `$` +
                            parseFloat(
                              (
                                calculatePMT(
                                  FINANCE_RATE / 100,
                                  TERM_IN_YEARS,
                                  -solarInquiryData.systemPrice * 0.7, // 70% of systemPrice
                                  0,
                                  0
                                ) / 12
                              ).toFixed(2)
                            ).toLocaleString('en-US') +
                            `/mo`
                          : `-`} */}
                        {/* $102/mo */}
                        {`$` + solarProposalAdditionalDetails.monthlyInstallment + `/mo`}
                        {/* {updatedCalculationBasedOnBattery &&
                        updatedCalculationBasedOnBattery.netSolarSystemPrice
                          ? `$` +
                            parseFloat(
                              (
                                calculatePMT(
                                  FINANCE_RATE / 100,
                                  TERM_IN_YEARS,
                                  -updatedCalculationBasedOnBattery.systemPrice * 0.7, // 70% of systemPrice
                                  0,
                                  0
                                ) / 12
                              ).toFixed(2)
                            ).toLocaleString('en-US') +
                            ` / mo`
                          : `-`} */}
                      </span>
                    </div>
                  </li>
                  <li className="font-forma-djr-text-regular text-center lg:text-[28px] text-[20px] m-[3px] mt-[15px]">
                    <div className="flex flex-row justify-between items-center mb-[10px] py-[5px]">
                      <p className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                        New monthly electric bill
                      </p>
                      <span className="text-[18px] text-primary_black leading-[18px] font-forma-djr-text-regular">
                        {/* $0 */}
                        {`$` + solarProposalAdditionalDetails.monthlyEnergyCost}
                      </span>
                    </div>
                  </li>
                  <li className="font-forma-djr-text-regular text-center lg:text-[28px] text-[20px] m-[3px] mt-[15px]">
                    <div className="flex flex-row justify-between items-center mb-[10px] py-[5px]">
                      <p className="text-[18px] leading-[18px] font-forma-djr-text-regular">
                        Monthly electric bill savings
                      </p>
                      <span className="text-[18px] text-primary_black leading-[18px] font-forma-djr-text-regular">
                        {/* $40 */}

                        {`$` + solarProposalAdditionalDetails.energyBillingSaving}
                      </span>
                    </div>
                  </li>
                </>
              )}
            </ul>
            {/* {paymentOption === 'Cash' && ( */}
            <div className="flex flex-row justify-between items-center mt-[25px] bg-primary rounded-b-[8px] pl-[54px] pr-[42px] py-[21px] text-right">
              {!viewOnly ? (
                <button
                  className="bg-secondary_white max-w-[207px] max-h-[50px] font-forma-djr-text-regular py-[17px] px-[35px] text-primary rounded-md text-[18px] leading-[16px] border-[#F1D972] cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
                  onClick={async () => handleStripeCheckout()}>
                  <span>
                    {paymentOption === 'Cash'
                      ? `Reserve for $` + parseInt(Number(bookingAmount.cash))
                      : `Reserve for $` + parseInt(Number(bookingAmount.finance))}
                  </span>
                </button>
              ) : (
                <span></span>
              )}

              <p className="text-[28px] leading-[14px] font-forma-djr-text-regular text-[#fff] max-w-[116px]">
                {paymentOption === 'Cash'
                  ? ` $` +
                    String(solarProposalAdditionalDetails.netSystemCost).toLocaleString('en-US')
                  : ` $` +
                    String(solarProposalAdditionalDetails.financeNetSystemCost).toLocaleString(
                      'en-US'
                    )}

                <br />
                <span className="text-[11px] leading-[0px] font-forma-djr-text-regular text-quaternary">
                  {paymentOption === 'Cash'
                    ? `($` + solarProposalAdditionalDetails.wattCost + `/watt)`
                    : `($` + solarProposalAdditionalDetails.financeWattCost + `/watt)`}
                  <br /> *After incentives
                </span>
              </p>
            </div>
            {/* )} */}
            {/* {paymentOption === 'Finance' && (
              <div className="flex flex-col border-t-[1px] border-[#F0EEFC] border-solid py-[15px] mx-[24px]">
                <div className="flex flex-row justify-between items-center w-full">
                  <span></span>
                  <p className="text-[28px] leading-[14px] font-forma-djr-text-regular text-primary max-w-[116px]">
                    $10,853*
                    <br />
                    <span className="text-[11px] leading-[0px] font-forma-djr-text-regular text-primary text-right">
                      ($1.43/watt) <br /> *After incentives
                    </span>
                  </p>
                </div>
                <div className="my-[14px]">
                  <p className="bg-[#F0EEFC] rounded-[4px] py-[17px] px-[114px] text-[18px] leading-[16px] font-forma-djr-text-regular text-center text-primary">
                    No-commitment Reservation: $99
                  </p>
                </div>
                <div className="flex flex-row justify-between">
                  <p className="text-[16px] leading-[24px] text-primary">Reservation includes:</p>
                  <ul className="text-tertiary text-[16px] leading-2 list-disc opacity-[0.8] text-left ">
                    <li>Site survey</li>
                    <li>Home energy monitor & installation</li>
                    <li>Price lock</li>
                  </ul>
                </div>
                <button
                  className="bg-[#F1D972] mt-[14px] w-full max-h-[50px] font-forma-djr-text-regular py-3 px-14 text-primary rounded-md text-lg border-[#F1D972] cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
                  onClick={async () => {}}>
                  Checkout
                </button>
              </div>
            )} */}
          </div>

          <div className="mb-[20px] bg-tertiary px-[40px] py-[23px] rounded-[8px] border-[1px] border-solid border-primary_black">
            <h4 className="text-primary_black text-[18px] font-forma-djr-text-bold leading-[18px] mb-[15px] text-left">
              What’s next?
            </h4>
            <ul
              className={`text-[16px] leading-[23px] text-primary_black opacity-[0.8] list-decimal ${
                paymentOption === 'Cash' ? `[&>*]:my-[10px]` : ''
              } `}>
              <li>
                Our team of energy experts will reach out to schedule a call. During this call we’ll
                answer any questions and finalize the project details.
              </li>
              <li>
                We will perform a site inspection of your home and install the equipment included in
                the reservation payment. This data will help us dial in your project and potentially
                identify ways to further reduce the total cost.
              </li>
              <li>We’ll submit all the necessary permits.</li>
              <li>We’ll schedule an installation day to get your system installed.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Proposal;
