import React, { useContext, useState } from 'react';
import { StepperContext } from '../../providers/stepper.provider';

const ProgressBar = () => {
  const { isStepperStart, activeStep, totalSteps, isGoogleSunroofLoading } =
    useContext(StepperContext);

  const ProgressBar = {
    width: `${isStepperStart ? ((activeStep + 1) / totalSteps) * 100 + '%' : '100%'}`
  };
  return (
    <div className="flex h-[20px] w-full bg-gray-200 relative">
      <div
        className="h-full bg-gradient-to-r from-[#FDAB2B] to-[#FF5900] md:from-[#FDAB2B] md:to-[#FF5900] ease-in-out duration-[2000ms] transition-all"
        style={ProgressBar}></div>
      {/* {isStepperStart && (
        <span className="w-full h-full absolute text-primary items-center flex justify-center">

          {activeStep + 1 + ` OF ` + totalSteps}
        </span>
      )} */}
    </div>
  );
};

export default ProgressBar;
