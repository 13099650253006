import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import PageNotFound from './pages/PageNotFound';
import { Toaster } from 'react-hot-toast';
import { useContext, useEffect, useState } from 'react';
import { StepperContext } from './providers/stepper.provider';
import { AuthContext } from './providers/auth.provider';
import Loader from './components/Loader/Loader';
import 'react-tooltip/dist/react-tooltip.css';
import Popover from './components/Popup/Popover';
import { useCalendlyEventListener } from 'react-calendly';
import { klaviyoClickOnChatWithOurConciergeButton } from './utils/klaviyo';
import { viewsInitialProposalOrSchedulesCallGTM } from './utils/googleTagManager';
import { viewsInitialProposalOrSchedulesCallEventPixel } from './utils/facebookPixelEvents';
import { LeadContext } from './providers/lead.provider';
import Layout from './pages/Home/Components/SolarFlowV3/Components/Layout/Layout';
import Proposal from './pages/Home/Components/SolarFlowV3/SolarProposal/Proposal';
import Checkout from './pages/Home/Components/SolarFlowV3/SolarProposal/Checkout';
import '@stripe/stripe-js';
import Success from './pages/Home/Components/SolarFlowV3/Payment/Success';
import ViewProposal from './pages/Home/Components/SolarFlowV3/SolarProposal/ViewProposal';
import WelcomeStep from './pages/Home/Components/SolarFlowV3/Components/Layout/Steps/WelcomeStep';
import SolarPanelInstallation from './resources/images/SolarPanelInstallation';
import AddressVerification from './pages/Home/Components/SolarFlowV3/Components/Layout/Steps/AddressVerification';
import WomenCombat from './resources/images/WomenCombat';
import CustomerNameStep from './pages/Home/Components/SolarFlowV3/Components/Layout/Steps/CustomerNameStep';
import WomanWithLaptop from './resources/images/WomanWithLaptop';
import PropertyValidation from './pages/Home/Components/SolarFlowV3/Components/Layout/Steps/PropertyValidation';
import SolarHome from './resources/images/SolarHome';
import MonthlyElectricCost from './pages/Home/Components/SolarFlowV3/Components/Layout/Steps/MonthlyElectricCost';
import PhoneNumberValidation from './pages/Home/Components/SolarFlowV3/Components/Layout/Steps/PhoneNumberValidation';
import FreeTime from './resources/images/FreeTime';
import EmailStep from './pages/Home/Components/SolarFlowV3/Components/Layout/Steps/EmailStep';
import BusyMan from './resources/images/BusyMan';
import { PropertyType } from './GraphQL/Types';
import { decryptionAES } from './utils/utils';

const CURRENT_ENV = process.env.REACT_APP_NODE_ENV;

function App() {
  const location = useLocation();

  const isProduction = CURRENT_ENV === 'production';

  // Contexts
  const {
    setSolarInquiryData,
    setActiveStep,
    setIsStepperStart,
    isLoading,
    solarConfigLoading,
    multiStapperSteps,
    solarInquiryData,
    amountForGTMandPixel,
    setSteps
  } = useContext(StepperContext);

  const { setIsManualSignUp, setIsFirebasePasswordLessSignInLinkClicked } = useContext(AuthContext);
  const { addLeadStep, updateUserLead } = useContext(LeadContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const stateData = JSON.parse(urlParams.get('state'));
    // const stateData = JSON.parse(decryptionAES(urlParams.get('state')).data);

    console.log('stateData :', stateData);

    if (stateData && stateData.is_redirected_from_website) {
      setIsStepperStart(false);
      setSolarInquiryData(stateData.solarInquiryData);
      setActiveStep(multiStapperSteps.indexOf('addressStep'));
    } else if (stateData && stateData.is_redirected_from_app) {
      const defaultDataFromState = {
        firstName: '',
        lastName: '',
        address: stateData.solarInquiryData.address || '',
        city: stateData.solarInquiryData.city || '',
        state: stateData.solarInquiryData.state || '',
        zipCode: stateData.solarInquiryData.zipCode || '',
        email: '',
        electricityCost: stateData.solarInquiryData.electricityCost || '',
        energyBillSaving: 1.4,
        monthlyEnergyCost: 50,
        earnCashbackUpTo: '',
        treesPlanted: '',
        wattCost: '',
        estSolarSystemSizekW: '',
        sizeSmoothing: '',
        systemPrice: '',
        fedralITCAmount: '',
        netSolarSystemPrice: '',
        batteryQuantity: 0,
        sunroofImageId: '',
        formatedAddress: stateData.solarInquiryData.formatedAddress || '',
        addressLatLng: stateData.solarInquiryData.addressLatLng || '',
        phoneNumber: stateData.solarInquiryData.phoneNumber || '',
        progress: '',
        onHome: PropertyType.Own,
        heatPump: false,
        energyEfficiency: false
      };

      const updatedSteps = [
        {
          primaryStep: true,
          stepName: 'WelcomeStep',
          component: WelcomeStep,
          content: `9 out of 10 homebuyers prefer homes with energy efficient upgrades than homes that cost less.`,
          sourceText: `EnergyLogic`,
          image: <SolarPanelInstallation />
        },
        {
          primaryStep: true,
          stepName: 'CustomerNameStep',
          component: CustomerNameStep,
          content: `Solar panels increase the value of your home by 4.1%, on average.`,
          sourceText: `Zillow`,
          image: <WomanWithLaptop />
        },
        // {
        //   primaryStep: true,
        //   stepName: 'PropertyValidation',
        //   component: PropertyValidation,
        //   content: `On average, homes lose 20% of their heated and cooled air to duct leakage.`,
        //   sourceText: `U.S. Department of Energy`,
        //   image: <SolarHome />
        // },
        {
          primaryStep: true,
          stepName: 'MonthlyElectricCost',
          component: MonthlyElectricCost,
          content: `Water heating can make up as much as 25% of your total energy cost.`,
          sourceText: `U.S. Department of Energy`,
          image: <SolarHome />
        },
        // {
        //   primaryStep: true,
        //   stepName: 'PhoneNumberValidation',
        //   component: PhoneNumberValidation,
        //   content: `The price of electricity increased 14% in 2022.`,
        //   sourceText: `UtilityDive`,
        //   image: <FreeTime />
        // },
        {
          primaryStep: true,
          stepName: 'EmailStep',
          component: EmailStep,
          content: `Currently, five percent of homes in the U.S. have rooftop solar.`,
          sourceText: `PV Magazine`,
          image: <BusyMan />
        }
      ];

      const isBlank = (value) => {
        return value === undefined || value === null || value === '';
      };

      if (
        isBlank(stateData.solarInquiryData.formatedAddress) ||
        isBlank(stateData.solarInquiryData.electricityCost) ||
        isBlank(stateData.solarInquiryData.addressLatLng) ||
        !stateData.solarInquiryData.addressLatLng.lat ||
        !stateData.solarInquiryData.addressLatLng.lng
      ) {
        defaultDataFromState.formatedAddress = '';
        const additionalStep = {
          primaryStep: true,
          stepName: 'AddressVerification',
          component: AddressVerification,
          content: `9 out of 10 homebuyers prefer homes with energy efficient upgrades than homes that cost less.`,
          sourceText: `EnergyLogic`,
          image: <WomenCombat />
        };

        updatedSteps.splice(1, 0, additionalStep);
      }

      setSteps(updatedSteps);
      setSolarInquiryData(defaultDataFromState);
    } else {
      if (stateData && stateData.solarInquiryData) {
        setSolarInquiryData(stateData.solarInquiryData);
        setIsStepperStart(false);
        setActiveStep(multiStapperSteps.indexOf('emailStep'));
        setIsManualSignUp(true);
        setIsFirebasePasswordLessSignInLinkClicked(true);
      }

      if (stateData && stateData.updateCred) {
        setIsStepperStart(false);
        setActiveStep(multiStapperSteps.indexOf('urjanetStep'));
      }
    }
  }, []);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log('onProfilePageViewed'),
    onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
    onEventTypeViewed: () => console.log('onEventTypeViewed'),
    onEventScheduled: async (e) => {
      if (isProduction) {
        await klaviyoClickOnChatWithOurConciergeButton(
          solarInquiryData.email,
          e.data.payload,
          solarInquiryData.phoneNumber
        );
        await viewsInitialProposalOrSchedulesCallGTM(amountForGTMandPixel);
        await viewsInitialProposalOrSchedulesCallEventPixel({
          value: parseInt(amountForGTMandPixel),
          currency: 'USD'
        });
      }
      await addLeadStep({ payload: e.data.payload, type: 'call Scheduled' }, 'CallScheduled');
      // await updateUserLead(true);
    }
  });

  // const handleEmailLinkClick = () => {
  //   const urlParams = new URLSearchParams(location.search);
  //   const stateData = JSON.parse(urlParams.get('state'));
  //   if (stateData && stateData.is_redirected_from_website) {
  //     setIsStepperStart(true);
  //     setSolarInquiryData(stateData.solarInquiryData);
  //     setIsStepperStart(true);
  //     setActiveStep(0);
  //   } else {
  //     if (stateData && stateData.solarInquiryData) {
  //       setSolarInquiryData(stateData.solarInquiryData);
  //       setIsStepperStart(true);
  //       setActiveStep(1);
  //       setIsManualSignUp(true);
  //       setIsFirebasePasswordLessSignInLinkClicked(true);
  //     }

  //     if (stateData && stateData.updateCred) {
  //       setIsStepperStart(true);
  //       setActiveStep(2);
  //     }
  //   }
  // };

  return (
    <>
      <Toaster toastOptions={{ duration: 4000 }} />

      <div className="relative">
        {isLoading && (
          <div className="absolute top-0 left-0 right-0 z-20 bottom-0 bg-white bg-opacity-90 h-screen">
            <Loader showLoaderOnly={solarConfigLoading} />
          </div>
        )}
        <Popover />
        <div className="flex item-center justify-center h-screen bg-neutral ">
          <div className="m-auto bg-white w-full h-screen items-center">
            <Routes>
              {/* <Route path="/:state?" element={<Home />} /> */}
              <Route path="/" element={<Layout />} />
              <Route path="/proposal" element={<Proposal />} />
              <Route path="/view-proposal" element={<ViewProposal />} />
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Proposal />} />

              {/* <Route path="/checkout" element={<Checkout />} /> */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
