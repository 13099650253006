import React from 'react';

const FreeTime = () => {
  return (
    <svg
      width="599"
      //   height="501"
      height="450"
      viewBox="0 0 599 501"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M88.9115 398.521C94.7843 413.179 102.743 436.563 153.772 441.935C273.919 454.584 443.164 447.746 487.198 434.414C531.233 421.083 458.61 357.548 458.61 357.548C458.61 357.548 402.372 230.587 371.445 185.559C328.552 123.11 226.173 103.511 163.706 123.11C101.239 142.708 83.8653 152.963 72.0708 215.005C60.2761 277.047 71.7796 355.762 88.9115 398.521Z"
        fill="#EBFBD6"
      />
      <path
        d="M428.064 313.106L423.968 312.816C424.749 301.737 424.649 287.732 423.708 276.267C422.471 261.193 420.283 255.992 419.298 255.347C415.939 253.147 414.296 254.649 414 254.973L414.03 254.938L410.869 252.315C412.243 250.655 416.242 248.433 421.546 251.906C422.665 252.639 426.072 254.87 427.8 275.93C428.757 287.589 428.858 301.833 428.064 313.106Z"
        fill="#342B7A"
      />
      <path
        d="M433.823 317.215L429.719 317.091C430.227 300.131 435.885 282.752 440.542 271.159C445.574 258.631 450.416 250.208 450.62 249.856L454.174 251.915C453.98 252.251 434.761 285.876 433.823 317.215Z"
        fill="#342B7A"
      />
      <path
        d="M426.61 317.275L422.511 317.032C422.543 316.492 425.711 262.946 426.828 250.699C427.969 238.188 435.039 220.399 435.339 219.648L439.151 221.176C439.079 221.355 432.003 239.163 430.917 251.072C429.806 263.254 426.642 316.736 426.61 317.275Z"
        fill="#342B7A"
      />
      <path
        d="M446.916 244.03C446.916 244.03 443.269 249.19 438.96 250.983C434.651 252.776 427.73 258.006 426.749 268.003C425.769 278.001 430.587 279.679 432.146 278.37C433.705 277.061 441.512 260.319 445.253 258.646C448.994 256.974 434.212 279.207 435.101 282.791C435.99 286.375 441.697 294.903 444.154 294.188C446.611 293.473 450.834 268.915 453.904 266.967C456.974 265.02 448.73 293.64 450.571 296.606C452.412 299.572 460.429 302.185 462.369 300.684C464.309 299.182 460.175 273.844 463.993 272.459C467.81 271.074 465.697 298.381 470.039 300.37C474.382 302.358 479.839 301.585 480.54 298.194C481.24 294.803 474.299 283.707 476.212 282.336C478.125 280.965 484.6 295.635 487.285 295.784C489.971 295.932 498.302 294.395 499.506 289.884C500.71 285.374 500.144 280.224 499.287 279.774C498.429 279.325 477.654 277.331 479.424 274.025C481.194 270.72 496.965 275.893 498.025 273.393C499.085 270.892 499.046 263.199 496.003 262.807C492.96 262.414 471.745 267.809 471.22 264.436C470.694 261.063 493.19 259.334 493.59 258.057C493.99 256.779 491.084 250.465 489.715 248.549C488.345 246.633 464.102 258.132 463.835 256.139C463.568 254.146 488.242 246.475 487.097 242.158C485.952 237.84 480.869 233.523 477.972 233.738C475.075 233.954 462.261 247.89 461.57 243.395C460.879 238.9 472.417 233.675 471.263 230.716C470.109 227.756 460.804 226.78 455.54 229.905C450.276 233.03 446.916 244.03 446.916 244.03Z"
        fill="#342B7A"
      />
      <path
        d="M392.653 227.66C389.517 228.348 400.018 244.425 397.39 245.005C394.439 245.656 388.364 227.888 387.19 228.073C384.674 228.47 382.506 229.099 381.297 229.917C377.814 232.273 393.394 248.376 392.869 251.68C392.345 254.983 378.042 237.361 376.18 237.351C374.317 237.342 365.8 247.302 364.686 250.009C363.571 252.717 385.539 261.477 385.275 263.256C385.012 265.034 366.189 254.002 364.153 254.924C362.116 255.846 358.687 264.137 358.587 267.189C358.487 270.24 378.391 268.477 378.712 271.962C379.033 275.447 359.666 271.433 357.802 271.855C355.937 272.277 354.081 287.513 355.378 290.232C356.675 292.952 372.659 296.849 374.948 296.352C377.236 295.855 385.075 269.359 385.551 275.635C386.027 281.911 378.421 295.945 379.196 297.475C379.971 299.005 390.511 299.144 392.13 297.033C393.749 294.922 392.989 289.323 394.456 283.735C395.924 278.147 398.22 277.353 399.711 283.719C401.203 290.085 395.439 295.694 397.213 296.466C398.987 297.238 408.49 293.132 409.088 291.948C409.687 290.764 407.933 286.008 407.791 280.751C407.648 275.494 412.002 268.479 413.832 274.084C415.662 279.689 411.294 291.112 412.388 292.219C413.484 293.327 415.945 292.153 417.555 291.822C419.165 291.491 423.181 284.221 424.436 270.662C425.69 257.104 417.199 260.318 413.094 248.225C411.818 244.467 403.859 229.64 401.03 228.577C399.788 228.11 394.492 227.256 392.653 227.66ZM368.247 290.467C366.6 289.569 369.483 284.497 369.483 284.497C369.483 284.497 371.216 281.009 374.39 281.088C377.564 281.168 369.893 291.366 368.247 290.467ZM370.416 277.26C372.807 279.352 362.433 279.795 361.572 278.256C360.711 276.718 365.158 275.895 365.158 275.895C365.158 275.895 368.025 275.168 370.416 277.26Z"
        fill="#342B7A"
      />
      <path
        d="M531.438 238.785C529.836 234.932 526.633 231.591 525.78 231.741C524.928 231.89 509.121 241.815 508.576 238.508C508.031 235.2 522.023 230.258 521.413 227.907C520.804 225.557 516.589 220.127 514.219 221.509C511.849 222.892 499.751 238.294 497.544 236.19C495.336 234.087 510.335 220.584 509.924 219.461C509.512 218.337 504.017 215.448 502.003 214.838C499.99 214.228 489.069 235.607 487.795 234.341C486.521 233.074 499.831 214.173 496.67 211.738C493.509 209.303 487.557 209.018 485.622 210.752C483.687 212.485 482.191 229.355 479.255 226.546C476.318 223.738 481.651 213.739 479.222 212.272C476.794 210.804 469.669 215.191 467.639 220.278C465.61 225.365 469.216 234.994 469.216 234.994C469.216 234.994 469.44 240.641 467.362 244.263C465.284 247.886 463.226 255.369 467.972 262.988C472.719 270.607 477.046 269.168 477.439 267.389C477.831 265.611 474.251 249.486 475.992 246.259C477.732 243.032 479.358 266.854 481.939 268.909C484.519 270.964 493.204 273.891 494.556 272.044C495.908 270.197 485.535 250.49 486.651 247.434C487.766 244.378 497.501 269.159 500.42 270.256C503.338 271.352 510.441 268.829 510.999 266.706C511.556 264.583 494.837 248.884 496.788 245.819C498.74 242.755 512.104 263.258 516.263 262.297C520.423 261.336 523.868 257.81 522.52 255.025C521.171 252.239 510.213 248.166 510.822 246.15C511.432 244.134 524.004 250.996 525.988 249.636C527.973 248.275 533.039 242.639 531.438 238.785ZM488.122 222.101C485.791 223.118 488.365 214.708 490.426 213.607C492.486 212.505 491.356 217.253 491.356 217.253C491.356 217.253 490.453 221.083 488.122 222.101ZM497.922 226.452C495.5 227.322 500.118 220.059 501.765 219.217C503.413 218.375 502.842 221.958 502.842 221.958C502.15 224.728 500.345 225.582 497.922 226.452ZM509.569 230.034C512.028 226.316 516.202 226.983 516.202 226.983C516.682 228.636 513.892 229.921 511.857 230.986C509.822 232.05 507.11 233.751 509.569 230.034ZM517.996 240.27C517.996 240.27 524.756 236.124 526.424 239.648C528.092 243.172 513.952 243.022 517.996 240.27Z"
        fill="#342B7A"
      />
      <path
        d="M460.85 237.357C463.2 235.197 446.161 226.644 448.128 224.831C450.338 222.796 464.42 235.015 465.335 234.269C467.298 232.669 468.846 231.044 469.477 229.737C471.292 225.971 449.879 219.937 448.681 216.838C447.484 213.738 468.558 221.716 470.162 220.792C471.766 219.868 474.111 207.054 473.717 204.171C473.324 201.289 450.089 204.757 449.427 203.097C448.765 201.438 470.434 201.495 471.723 199.684C473.012 197.873 471.82 189.038 470.384 186.367C468.947 183.697 452.734 195.169 450.72 192.337C448.705 189.505 467.34 183.262 468.73 181.967C470.121 180.672 464.114 166.659 461.643 164.973C459.172 163.286 443.502 167.937 441.785 169.508C440.067 171.08 446.554 197.756 443.014 192.604C439.474 187.453 439.004 171.595 437.575 170.669C436.147 169.743 427.026 174.897 426.689 177.52C426.352 180.143 432.874 192.516 434.401 198.049C435.929 203.582 435.509 206.739 431.052 202.018C426.595 197.297 424.516 180.326 422.607 180.55C420.699 180.774 414.586 189.055 414.663 190.371C414.739 191.687 418.618 194.894 421.363 199.338C424.109 203.781 423.87 211.983 419.501 208.085C415.133 204.188 413.186 192.192 411.693 191.789C410.2 191.386 408.672 193.625 407.455 194.715C406.237 195.805 406.415 204.058 412.102 216.329C417.79 228.6 431.399 226.788 435.279 226.109C439.158 225.43 450.239 241.263 453.199 240.76C454.499 240.54 459.472 238.624 460.85 237.357ZM450.474 171.209C452.337 171.157 452.391 176.955 452.391 176.955C452.391 176.955 452.644 180.818 449.878 182.337C447.112 183.857 448.612 171.261 450.474 171.209ZM455.201 183.637C452.104 183.037 460.792 177.465 462.298 178.356C463.805 179.246 460.397 182.177 460.397 182.177C460.397 182.177 458.298 184.237 455.201 183.637Z"
        fill="#423699"
      />
      <path d="M470.091 372.054H398.603V385.31H470.091V372.054Z" fill="#DDC55F" />
      <path d="M418.044 385.31H406.693V417.094H418.044V385.31Z" fill="#DDC55F" />
      <path d="M450.649 417.095H462V385.311H450.649V417.095Z" fill="#DDC55F" />
      <path
        d="M433.863 309.264H404.371C402.684 309.264 401.347 310.688 401.45 312.374L405.123 372.054H433.863H462.604L466.276 312.374C466.38 310.688 465.042 309.264 463.355 309.264H433.863Z"
        fill="#F1ECA5"
      />
      <path
        d="M280.33 318.186L270.598 417.155C270.493 418.221 271.33 419.145 272.399 419.145H278.854C279.713 419.145 280.454 418.54 280.627 417.697L301.012 318.186H280.33Z"
        fill="#464359"
      />
      <path
        d="M426.453 318.186L436.185 417.155C436.29 418.221 435.454 419.145 434.384 419.145H427.93C427.07 419.145 426.33 418.54 426.157 417.697L405.771 318.186H426.453Z"
        fill="#464359"
      />
      <path
        d="M151.865 318.186L142.133 417.155C142.028 418.221 142.864 419.145 143.934 419.145H150.389C151.248 419.145 151.989 418.54 152.161 417.697L172.547 318.186H151.865Z"
        fill="#615D7F"
      />
      <path
        d="M297.988 318.186L307.72 417.155C307.825 418.221 306.989 419.145 305.919 419.145H299.464C298.605 419.145 297.864 418.54 297.692 417.697L277.306 318.186H297.988Z"
        fill="#615D7F"
      />
      <path
        d="M428.186 281.137C426.303 274.313 421.844 268.742 416.037 265.356C409.012 253.762 396.339 246.345 382.316 246.345H325.858L293.806 192.296C283.202 174.413 263.97 163.451 243.2 163.451H115.254L202.085 301.428L290.66 322.622H425.208C432.463 322.622 437.723 315.701 435.791 308.699L428.186 281.137Z"
        fill="#2B2556"
      />
      <path
        d="M250.2 100.943C250.2 100.943 249.458 97.6051 244.651 97.4167C239.846 97.2283 237.025 101.096 235.815 102.394C234.604 103.692 229.838 111.345 229.834 118.538C229.825 131.82 235.758 133.167 241.905 135.13C248.052 137.094 255.559 133.524 257.168 131.037C262.484 122.819 256.798 114.232 256.179 112.173C255.334 109.366 257.389 102.894 250.2 100.943Z"
        fill="#5346AF"
      />
      <path
        d="M251.852 131.279L301.065 134.154C298.785 140.466 294.32 153.799 293.999 162.196C276.589 163.5 256.474 163.943 252.915 157.652C248.11 149.16 251.852 131.279 251.852 131.279Z"
        fill="#D4B328"
      />
      <path
        d="M362.778 366.5C364.197 384.339 364.859 398.418 364.013 400.573C361.636 406.627 356.361 408.47 351.846 402.424C350.168 400.177 345.888 387.606 341.181 371.775C351.521 366.757 358.149 365.843 362.778 366.5Z"
        fill="#FFE67A"
      />
      <path
        d="M364.467 400.292C364.467 400.292 370.903 416.98 374.31 421.136C377.718 425.292 378.798 426.333 376.816 428.334C374.835 430.335 367.242 432.44 362.594 430.353C357.945 428.267 356.294 416.951 354.303 414.046C352.311 411.141 350.933 410.313 350.597 407.157C350.262 404.002 352.652 402.731 352.366 398.467C352.08 394.203 358.953 389.066 364.467 400.292Z"
        fill="#F0EEFC"
      />
      <path
        d="M365.41 404.749C365.41 404.749 364.246 389.232 364.166 387.553C364.085 385.875 357.66 387.254 351.679 388.947C348.479 389.853 346.797 391.236 347.034 391.909C347.271 392.581 351.802 403.173 352.335 404.854C352.867 406.534 364.191 406.037 365.41 404.749Z"
        fill="#F0EEFC"
      />
      <path
        d="M203.596 211.799C196.718 199.28 204.033 174.619 207.822 159.695C212.78 140.164 230.051 123.61 255.417 132.202C270.878 137.44 273.248 148.699 275.52 174.791C276.039 180.746 280.555 183.111 278.743 191.408C274.112 194.625 264.562 200.889 258.959 201.969C252.707 203.175 218.84 210.528 203.596 211.799Z"
        fill="#FFDB43"
      />
      <path
        d="M258.959 201.969C264.563 200.889 274.112 194.625 278.744 191.408C283.587 207.63 291.735 218.464 291.735 218.464C291.735 218.464 280.509 236.374 271.416 244.712C264.747 250.827 236.463 271.668 220.533 273.787C210.214 253.492 205.086 232.033 203.597 211.799C218.84 210.528 252.707 203.175 258.959 201.969Z"
        fill="#FFE67A"
      />
      <path
        d="M254.731 153.018C254.634 153.018 254.548 152.947 254.533 152.848C254.52 152.763 253.232 144.345 251.126 138.874C249.004 133.363 247.381 123.243 247.365 123.141C247.347 123.032 247.422 122.929 247.531 122.911C247.642 122.894 247.743 122.968 247.76 123.078C247.777 123.179 249.394 133.258 251.5 138.73C253.621 144.242 254.916 152.703 254.929 152.788C254.946 152.897 254.871 152.999 254.761 153.016C254.751 153.018 254.741 153.018 254.731 153.018Z"
        fill="#F0EEFC"
      />
      <path
        d="M219.4 272.284C219.4 272.284 210.134 253.924 207.308 237.311L282.573 201.919L287.403 210.206C287.403 210.206 286.83 236.547 267.013 249.84C247.196 263.134 219.4 272.284 219.4 272.284Z"
        fill="#F1ECA5"
      />
      <path
        d="M269.009 244.9C269.009 244.9 298.388 147.732 321.33 146.93C344.272 146.128 343.537 205.349 343.537 205.349C343.537 205.349 325.301 273.125 298.272 285.971C268.475 300.132 242.963 269.727 240.944 261.668C260.622 252.861 269.009 244.9 269.009 244.9Z"
        fill="#F1ECA5"
      />
      <path
        d="M378.019 273.777C378.216 283.345 370.536 283.073 367.014 276.397C365.015 272.607 355.75 257.057 345.591 239.208C355.365 234.44 362.145 227.737 365.673 223.658C372.852 248.545 377.957 270.753 378.019 273.777Z"
        fill="#F2DB79"
      />
      <path
        d="M317.726 147.804C317.726 147.804 328.16 142.967 336.397 151.401C345.779 161.007 362.403 200.72 370.898 230.17C367.37 234.248 354.372 241.404 344.598 246.171C331.272 222.754 317.401 188.414 315.385 178.436C311.833 160.851 311.613 150.709 317.726 147.804Z"
        fill="#F1ECA5"
      />
      <path
        d="M264.334 248.516C264.334 248.516 333.335 237.647 344.291 258.495C351.305 271.84 351.757 291.239 306.487 295.173C270.095 298.336 247.88 297.733 234.45 289.545C222.791 282.438 218.064 270.574 213.704 258.281C236.115 249.053 264.334 248.516 264.334 248.516Z"
        fill="#F1ECA5"
      />
      <path
        d="M378.636 272.75C378.636 272.75 390.5 286.123 395.146 288.821C399.791 291.518 392.478 301.888 387.395 301.564C382.311 301.24 376.807 291.22 373.926 289.197C371.044 287.175 369.464 286.882 368.046 284.045C366.627 281.206 365.156 273.073 365.156 273.073L378.636 272.75Z"
        fill="#F0EEFC"
      />
      <path
        d="M238.128 116.701L235.725 132.25C235.232 134.814 240.463 141.317 245.134 138.812C246.501 138.079 245.582 134.263 244.947 131.391L243.437 119.898L238.128 116.701Z"
        fill="#FFE67A"
      />
      <path
        d="M238.776 119.29C238.776 119.29 241.162 119.891 243.522 120.546L244.36 126.929C240.532 125.863 238.776 119.29 238.776 119.29Z"
        fill="#C6B467"
      />
      <path
        d="M240.469 108.389C240.469 108.389 239.353 110.621 238.308 113.367C236.768 117.418 238.69 120.368 242.508 122.451C246.229 124.482 247.907 124.172 249.823 121.803C250.299 121.216 250.9 120.291 251.259 119.363C251.854 117.82 252.275 114.998 251.845 112.88C251.649 111.914 251.796 110.546 251.75 109.828C251.477 105.535 249.235 104.368 245.651 106.396C244.867 106.84 244.627 107.422 240.469 108.389Z"
        fill="#FFE67A"
      />
      <path
        d="M244.893 108.738C245.768 108.345 246.515 108.067 247.42 108.73C247.673 108.915 247.583 109.355 247.211 109.309C246.185 109.181 245.865 109.112 244.949 109.348C244.541 109.454 244.384 108.967 244.893 108.738Z"
        fill="#464359"
      />
      <path
        d="M251.886 109.968C251.52 109.554 250.945 109.482 250.442 109.444C250.106 109.419 249.821 109.738 250.201 109.937C251.246 110.483 251.272 110.335 251.733 110.681C252.059 110.926 252.304 110.441 251.886 109.968Z"
        fill="#464359"
      />
      <path
        d="M248.309 116.17C248.178 116.117 248.097 115.976 248.125 115.832C248.157 115.667 248.315 115.559 248.48 115.591C249.499 115.786 249.684 115.062 249.691 115.031C249.73 114.868 249.893 114.767 250.056 114.806C250.22 114.844 250.319 115.008 250.28 115.171C250.172 115.629 249.632 116.429 248.366 116.186C248.346 116.183 248.327 116.177 248.309 116.17Z"
        fill="#C6B467"
      />
      <path
        d="M377.756 277.573L378.636 272.75L375.952 262.256L360.378 265.715L367.014 276.397L377.756 277.573Z"
        fill="#F0EEFC"
      />
      <path
        d="M335.603 230.655C335.147 230.655 334.711 230.392 334.512 229.949C321.668 201.351 314.322 179.031 314.249 178.809C314.044 178.181 314.386 177.505 315.013 177.299C315.638 177.093 316.315 177.435 316.52 178.063C316.593 178.284 323.903 200.492 336.692 228.967C336.963 229.569 336.694 230.278 336.092 230.549C335.933 230.62 335.767 230.655 335.603 230.655Z"
        fill="#CEC084"
      />
      <path
        d="M335.696 373.937C327.733 347.161 315.369 297.183 315.483 285.909C315.664 267.97 325.113 257.73 333.959 257.488C333.959 257.488 342.079 254.661 348.736 269.953C351.792 276.975 363.123 333.067 368.741 365.986C365.095 367.365 345.715 372.688 335.696 373.937Z"
        fill="#F1ECA5"
      />
      <path
        d="M336.56 252.65C336.477 252.65 336.393 252.641 336.309 252.623C335.999 252.557 305.145 245.949 294.112 244.023C282.727 242.036 275.767 240.058 271.162 238.75C269.582 238.301 268.334 237.946 267.276 237.695C266.634 237.542 266.237 236.898 266.39 236.255C266.541 235.611 267.185 235.214 267.828 235.367C268.936 235.63 270.206 235.991 271.814 236.448C276.372 237.743 283.26 239.7 294.522 241.666C305.601 243.6 336.498 250.217 336.809 250.284C337.455 250.422 337.866 251.058 337.728 251.704C337.607 252.265 337.111 252.65 336.56 252.65Z"
        fill="#CEC084"
      />
      <path
        d="M315.97 295.224C315.439 295.224 314.955 294.867 314.814 294.329C312.567 285.713 312.025 276.368 312.002 275.974C311.965 275.314 312.469 274.75 313.128 274.713C313.803 274.673 314.351 275.181 314.388 275.84C314.393 275.934 314.942 285.346 317.126 293.725C317.293 294.364 316.911 295.018 316.272 295.185C316.171 295.211 316.069 295.224 315.97 295.224Z"
        fill="#CEC084"
      />
      <path
        d="M264.103 197.82C264.103 197.82 275.756 214.274 272.418 213.946C269.081 213.618 262.249 204.093 262.249 204.093C262.249 204.093 263.636 220.861 258.712 220.354C253.787 219.847 254.192 202.404 255.477 198.998C256.762 195.593 264.103 197.82 264.103 197.82Z"
        fill="#FFDB43"
      />
      <path
        d="M250.795 113.307C250.802 113.603 250.956 113.84 251.138 113.835C251.321 113.831 251.463 113.587 251.455 113.291C251.448 112.995 251.294 112.758 251.112 112.763C250.929 112.767 250.787 113.011 250.795 113.307Z"
        fill="#464359"
      />
      <path
        d="M246.766 112.959C246.774 113.255 246.927 113.492 247.11 113.487C247.292 113.483 247.434 113.239 247.427 112.943C247.42 112.646 247.266 112.41 247.084 112.414C246.901 112.419 246.759 112.663 246.766 112.959Z"
        fill="#464359"
      />
      <path
        d="M248.342 112.665C248.371 112.671 248.403 112.666 248.432 112.649C249.135 112.242 249.617 112.847 249.638 112.873C249.689 112.939 249.78 112.943 249.841 112.88C249.901 112.818 249.909 112.714 249.858 112.647C249.64 112.363 249.037 111.935 248.313 112.355C248.241 112.397 248.209 112.497 248.241 112.578C248.261 112.626 248.299 112.657 248.342 112.665Z"
        fill="#464359"
      />
      <path
        d="M245.957 114.894C244.882 114.61 244.146 113.41 244.292 112.12C244.437 110.833 245.49 110.014 246.74 110.214C247.323 110.309 247.821 110.612 248.149 111.073C248.486 111.546 248.625 112.144 248.551 112.802C248.477 113.456 248.19 114.038 247.742 114.44C247.285 114.85 246.717 115.025 246.142 114.933C246.079 114.923 246.017 114.91 245.957 114.894ZM246.876 110.66C246.817 110.644 246.756 110.632 246.694 110.622C245.807 110.48 244.837 110.971 244.699 112.185C244.57 113.323 245.238 114.373 246.188 114.526C246.642 114.598 247.094 114.457 247.462 114.126C247.839 113.788 248.081 113.295 248.144 112.737C248.206 112.184 248.095 111.688 247.82 111.303C247.595 110.987 247.264 110.763 246.876 110.66Z"
        fill="#464359"
      />
      <path
        d="M251.024 115.13C250.126 114.98 249.481 113.908 249.568 112.666C249.654 111.431 250.451 110.586 251.462 110.658C251.948 110.693 252.378 110.936 252.67 111.342C252.967 111.755 253.101 112.298 253.058 112.914C253.015 113.53 252.801 114.099 252.457 114.516C252.095 114.956 251.63 115.179 251.148 115.145C251.106 115.142 251.065 115.137 251.024 115.13ZM251.548 111.06C251.511 111.054 251.473 111.049 251.435 111.047C250.649 110.991 250.049 111.669 249.978 112.695C249.902 113.78 250.442 114.707 251.175 114.757C251.516 114.781 251.856 114.61 252.133 114.275C252.427 113.917 252.61 113.424 252.648 112.885C252.685 112.357 252.575 111.899 252.332 111.56C252.135 111.287 251.86 111.112 251.548 111.06Z"
        fill="#464359"
      />
      <path
        d="M244.77 111.378L238.42 109.263L238.568 109.649L244.548 111.755L244.77 111.378Z"
        fill="#464359"
      />
      <path
        d="M264.709 191.495C264.256 191.495 263.865 191.386 263.53 191.167C262.219 190.309 262.046 187.951 261.846 185.219C261.701 183.242 261.551 181.198 260.968 179.44C259.219 174.173 254.582 153.073 254.535 152.861C254.512 152.753 254.58 152.646 254.688 152.622C254.796 152.598 254.903 152.666 254.927 152.775C254.973 152.987 259.605 174.066 261.348 179.314C261.948 181.119 262.106 183.282 262.245 185.19C262.438 187.815 262.604 190.082 263.749 190.831C264.286 191.182 265.027 191.182 266.015 190.83C269.798 189.486 267.479 164.929 266.233 151.737C265.773 146.874 265.411 143.032 265.455 141.701C265.498 140.413 265.315 138.572 265.103 136.44C264.463 130.01 263.666 122.006 268.186 121.34C268.699 121.266 269.173 121.393 269.593 121.72C271.048 122.855 271.388 126.046 271.401 126.181C271.413 126.291 271.333 126.39 271.223 126.401C271.112 126.412 271.014 126.332 271.003 126.222C271 126.19 270.667 123.066 269.346 122.037C269.011 121.776 268.651 121.677 268.244 121.738C264.102 122.347 264.911 130.47 265.501 136.401C265.715 138.547 265.899 140.4 265.855 141.714C265.812 143.02 266.174 146.85 266.631 151.699C268.45 170.957 269.911 189.871 266.149 191.208C265.611 191.4 265.132 191.495 264.709 191.495Z"
        fill="#F0EEFC"
      />
      <path
        d="M239.019 111.648C238.898 111.202 238.846 110.742 238.822 110.28C238.777 109.401 238.505 108.106 237.228 108.814C235.37 109.845 235.868 114.512 238.07 114.757C239.704 114.939 239.323 112.774 239.019 111.648Z"
        fill="#FFE67A"
      />
      <path
        d="M250.341 117.649C250.341 117.649 247.186 117.391 245.548 117.074C245.548 117.074 246.378 119.066 247.525 119.374C248.671 119.681 249.335 119.419 249.578 119.323C249.822 119.228 250.341 117.649 250.341 117.649Z"
        fill="#F0EEFC"
      />
      <path
        d="M238.267 111.21C238.267 111.21 237.897 110.9 237.319 110.988C236.74 111.076 236.375 111.456 236.349 112.223C236.323 112.989 236.892 115.523 237.145 115.517C237.398 115.511 237.815 115.5 237.792 115.311C237.768 115.121 237.441 113.473 237.439 113.344C237.437 113.216 238.162 113.092 238.233 112.974C238.304 112.856 238.267 111.21 238.267 111.21Z"
        fill="#F0EEFC"
      />
      <path
        d="M263.565 127.007L273.091 126.209C274.231 126.113 275.242 126.969 275.338 128.111L276.978 147.728C277.074 148.871 276.219 149.883 275.078 149.979L265.552 150.778C264.412 150.873 263.4 150.017 263.305 148.875L261.665 129.258C261.569 128.116 262.424 127.103 263.565 127.007Z"
        fill="#786CD0"
      />
      <path
        d="M263.494 128.159L266.457 127.911C266.908 127.873 267.307 128.211 267.345 128.662C267.382 129.113 267.045 129.513 266.595 129.55L263.631 129.799C263.181 129.836 262.781 129.498 262.744 129.048C262.706 128.597 263.043 128.197 263.494 128.159Z"
        fill="#464359"
      />
      <path
        d="M263.271 129.025C263.294 129.303 263.538 129.509 263.815 129.486C264.092 129.462 264.298 129.218 264.275 128.941C264.252 128.663 264.008 128.457 263.731 128.48C263.454 128.503 263.248 128.747 263.271 129.025Z"
        fill="#F0EEFC"
      />
      <path
        d="M267.596 133.702C267.657 134.43 268.295 134.97 269.022 134.909C269.748 134.848 270.288 134.209 270.227 133.482C270.166 132.754 269.528 132.214 268.802 132.275C268.075 132.336 267.536 132.975 267.596 133.702Z"
        fill="#464359"
      />
      <path
        d="M261.386 148.523C261.386 148.523 262.004 143.934 261.937 142.314C261.869 140.694 261.098 136.877 263.228 138.989C265.357 141.101 264.265 143.706 266.472 143.34C268.679 142.975 271.754 140.155 273.316 138.491C276.507 135.093 277.334 138.843 276.646 143.753C275.958 148.664 271.959 153.847 265.801 153.416C259.644 152.984 261.386 148.523 261.386 148.523Z"
        fill="#FFE67A"
      />
      <path
        d="M311.247 134.623C311.247 134.623 391.892 139.052 391.832 150.722C391.772 162.391 328.214 159.159 310.454 160.763C305.85 161.179 300.09 161.74 293.999 162.196C294.32 153.799 298.785 140.466 301.065 134.154L311.247 134.623Z"
        fill="#F2DB79"
      />
      <path
        d="M280.321 213.157C280.177 213.157 280.031 213.131 279.89 213.076C279.274 212.837 278.969 212.144 279.207 211.528L282.33 203.449C282.568 202.833 283.261 202.527 283.875 202.766C284.491 203.004 284.796 203.697 284.558 204.313L281.435 212.392C281.252 212.866 280.8 213.157 280.321 213.157Z"
        fill="#CEC084"
      />
      <path
        d="M230.94 274.387C230.668 274.387 230.395 274.295 230.171 274.107C229.666 273.681 229.601 272.927 230.026 272.421C235.182 266.282 235.967 249.879 235.974 249.714C236.003 249.054 236.562 248.542 237.221 248.572C237.88 248.601 238.391 249.16 238.362 249.82C238.33 250.527 237.527 267.207 231.855 273.961C231.618 274.242 231.28 274.387 230.94 274.387Z"
        fill="#CEC084"
      />
      <path
        d="M238.411 178.406C239.392 182.687 240 185.559 240 185.559C240 185.559 257.806 153.254 261.407 149.281C265.008 145.307 270.137 148.802 269.545 156.601C268.953 164.401 265.328 218.38 241.005 217.697C227.796 217.326 220.146 201.178 215.816 185.473C228.772 184.895 236.094 180.181 238.411 178.406Z"
        fill="#FFE67A"
      />
      <path
        d="M381.998 155.788C382.397 157.129 385.643 159.685 387.516 160.756C391.095 162.804 392.354 165.218 392.822 166.879C393.29 168.54 395.31 166.176 394.336 163.84C393.362 161.505 390.154 158.42 392.327 158.852C394.501 159.284 398.833 171.158 400.281 166.356C404.613 151.995 393.666 146.74 386.56 146.529C386.56 146.529 379.126 146.134 381.998 155.788Z"
        fill="#F2DB79"
      />
      <path
        d="M305.395 149.029C304.942 149.029 304.565 148.673 304.543 148.215C304.418 145.633 307.039 135.422 307.338 134.266C307.456 133.809 307.924 133.536 308.378 133.654C308.834 133.772 309.108 134.238 308.99 134.695C308.195 137.766 306.153 146.167 306.248 148.133C306.271 148.604 305.908 149.005 305.437 149.028C305.422 149.029 305.409 149.029 305.395 149.029Z"
        fill="#C6B467"
      />
      <path
        d="M235.463 204.866C235.45 204.866 235.437 204.866 235.424 204.866C234.954 204.845 234.589 204.445 234.61 203.974C235.072 193.53 239.069 185.509 239.239 185.173C239.451 184.752 239.965 184.584 240.385 184.796C240.806 185.009 240.974 185.523 240.762 185.944C240.722 186.023 236.76 193.989 236.315 204.05C236.294 204.508 235.917 204.866 235.463 204.866Z"
        fill="#C6B467"
      />
      <path
        d="M216.592 188.17C210.403 176.33 211.234 162.543 210.758 157.954C209.48 145.632 222.387 136.496 227.02 141.671C230.535 145.597 238.712 167.993 238.984 180.929C236.667 182.705 229.548 187.592 216.592 188.17Z"
        fill="#FFDB43"
      />
      <path
        d="M239.177 179.377C238.759 179.377 238.394 179.069 238.334 178.643C238.319 178.538 236.806 168.037 234.464 161.248C232.525 155.623 230.36 149.327 229.606 147.134C229.452 146.687 229.69 146.204 230.136 146.05C230.581 145.896 231.066 146.131 231.219 146.577C231.974 148.771 234.138 155.066 236.078 160.69C238.473 167.636 239.961 177.965 240.023 178.402C240.09 178.869 239.765 179.302 239.299 179.368C239.258 179.374 239.217 179.377 239.177 179.377Z"
        fill="#D4B328"
      />
      <path
        d="M216.593 189.024C216.256 189.024 215.938 188.824 215.803 188.493C215.76 188.389 211.543 177.985 210.207 169.046L208.87 160.105C208.8 159.638 209.121 159.203 209.588 159.133C210.054 159.064 210.488 159.385 210.558 159.852L211.895 168.793C213.201 177.526 217.341 187.744 217.383 187.846C217.561 188.283 217.352 188.782 216.916 188.961C216.81 189.004 216.7 189.024 216.593 189.024Z"
        fill="#D4B328"
      />
      <path
        d="M254.731 153.018C254.709 153.018 254.685 153.015 254.662 153.006C250.364 151.425 240.448 132.507 239.775 130.491C239.007 128.192 237.191 115.524 237.114 114.986C237.099 114.876 237.175 114.775 237.284 114.759C237.394 114.743 237.495 114.82 237.511 114.929C237.53 115.061 239.399 128.1 240.155 130.364C241.02 132.954 250.746 151.139 254.801 152.63C254.905 152.668 254.958 152.783 254.92 152.888C254.89 152.968 254.813 153.018 254.731 153.018Z"
        fill="#F0EEFC"
      />
      <path
        d="M105.69 177.04L132.385 298.134C135.539 312.438 148.199 322.622 162.827 322.622H300.691C308.001 322.622 313.269 315.604 311.239 308.573L301.475 274.764C296.557 257.921 281.134 246.345 263.611 246.345H207.153L175.101 192.296C164.496 174.413 145.264 163.451 124.495 163.451H116.591C109.455 163.451 104.152 170.063 105.69 177.04Z"
        fill="#372F70"
      />
      <path
        d="M197.409 324.161C196.58 324.161 195.897 323.499 195.874 322.663C195.819 320.564 194.521 271.145 195.892 262.223C197.327 252.881 205.791 245.489 206.151 245.179C206.794 244.625 207.763 244.697 208.317 245.341C208.87 245.984 208.798 246.955 208.156 247.51C208.077 247.578 200.189 254.486 198.928 262.691C197.599 271.343 198.932 322.07 198.945 322.581C198.968 323.431 198.299 324.138 197.451 324.16C197.437 324.16 197.423 324.161 197.409 324.161Z"
        fill="#2B2556"
      />
      <path
        d="M198.38 315.61H115.53C113.552 315.61 111.933 313.989 111.933 312.008V308.886C111.933 306.905 113.552 305.284 115.53 305.284H198.38C200.359 305.284 201.977 306.905 201.977 308.886V312.008C201.977 313.989 200.359 315.61 198.38 315.61Z"
        fill="#F1D972"
      />
      <path
        d="M116.717 423.248C116.618 423.248 116.52 423.244 116.42 423.237C114.163 423.075 112.465 421.112 112.626 418.853L120.137 313.791C120.298 311.532 122.258 309.833 124.515 309.993C126.771 310.154 128.47 312.117 128.308 314.377L120.798 419.438C120.644 421.598 118.846 423.248 116.717 423.248Z"
        fill="#F1D972"
      />
      <path
        d="M197.194 423.248C195.064 423.248 193.267 421.598 193.112 419.438L185.602 314.377C185.44 312.117 187.139 310.154 189.395 309.993C191.652 309.831 193.612 311.531 193.774 313.791L201.284 418.853C201.445 421.113 199.747 423.075 197.49 423.237C197.391 423.244 197.292 423.248 197.194 423.248Z"
        fill="#F1D972"
      />
      <path
        d="M156.955 423.248C154.693 423.248 152.859 421.411 152.859 419.145V310.447C152.859 308.181 154.693 306.345 156.955 306.345C159.218 306.345 161.052 308.181 161.052 310.447V419.145C161.052 421.411 159.218 423.248 156.955 423.248Z"
        fill="#F1D972"
      />
      <path
        d="M157.783 271.315H142.933V277.336C143.018 292.12 149.504 305.284 149.504 305.284H157.783H166.061C166.061 305.284 172.547 292.12 172.632 277.336V271.315H157.783Z"
        fill="#F0EEFC"
      />
      <path
        d="M157.783 272.132C165.984 272.132 172.632 271.767 172.632 271.315C172.632 270.864 165.984 270.499 157.783 270.499C149.582 270.499 142.933 270.864 142.933 271.315C142.933 271.767 149.582 272.132 157.783 272.132Z"
        fill="#615D7F"
      />
      <path
        d="M171.78 295.977C169.212 295.977 167.399 294.634 166.624 293.64L169.851 291.114L169.822 291.078C170 291.284 170.989 292.284 172.964 291.685C174.802 291.129 176.861 287.986 177.653 284.53C178.203 282.125 177.824 280.871 177.624 280.686C175.612 279.516 172.61 281.731 172.58 281.753C171.684 282.438 170.399 282.27 169.712 281.375C169.024 280.48 169.185 279.2 170.076 278.508C170.285 278.345 175.248 274.558 179.699 277.151C181.52 278.211 182.318 280.547 181.945 283.728C181.465 287.828 178.729 294.226 174.15 295.612C173.303 295.868 172.511 295.977 171.78 295.977Z"
        fill="#F0EEFC"
      />
    </svg>
  );
};

export default FreeTime;
