import React from 'react';
import { BsCheck2 } from 'react-icons/bs';
import { twMerge } from 'tailwind-merge';

const RadioButtonTab = ({
  id,
  value,
  onChange,
  name,
  checked,
  label,
  className,
  showIcon = false,
  disable = false,
  index,
  labelPrefix = false
}) => {
  const labelPrefixValue = String.fromCharCode(65 + index);
  return (
    <>
      <input
        id={id}
        type="radio"
        value={value}
        onChange={onChange}
        name={name}
        checked={checked}
        className="hidden peer"
        disabled={disable}
      />
      <label
        htmlFor={id}
        className={twMerge(
          `flex items-center text-center justify-center py-[8px] px-[10px] text-primary_black bg-tertiary ${
            disable ? 'cursor-not-allowed' : 'cursor-pointer'
          } dark:hover:text-gray-400 dark:peer-checked:text-secondary_white peer-checked:text-secondary_white peer-checked:bg-primary hover:text-gray-100 hover:bg-secondary_white dark:text-gray-400 dark:bg-[#F1F1F1] dark:hover:bg-[#F1F1F1] border-solid border-primary rounded-[8px]`,
          className
        )}>
        <div className="text-[16px] font-forma-djr-text-regular">
          {labelPrefix && (
            <span
              className={`border-[1px] border-solid border-primary px-1 mr-[6px] font-forma-djr-text-regular ${
                checked ? 'bg-secondary_white' : ''
              }`}>
              {labelPrefixValue}
            </span>
          )}
          <span>{label}</span>
          {checked && showIcon && (
            <span className="ml-2 absolute right-[15px] top-[15px]">
              <BsCheck2 className="stroke-1" />
            </span>
          )}
        </div>
      </label>
    </>
  );
};

export default RadioButtonTab;
