import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Dropdown1 = ({ values, design, arrowStyle, arrowPosition }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={toggleDropdown}
        className={`inline-flex justify-between w-full rounded-md px-4 py-2 text-sm font-medium focus:outline-none focus:ring ${
          design === 'solid'
            ? 'bg-blue-500 text-white'
            : design === 'plain'
            ? 'bg-white text-gray-700 border border-gray-300'
            : 'bg-transparent text-blue-500 border border-blue-500'
        }`}>
        Dropdown
        <span className={`ml-2 ${arrowPosition === 'right' ? 'order-reverse' : ''}`}>
          {isOpen ? (
            arrowStyle === 'partition' ? (
              <span>&#x25B2;</span>
            ) : (
              <i className={`fas fa-chevron-${arrowPosition === 'right' ? 'left' : 'down'}`}></i>
            )
          ) : arrowStyle === 'partition' ? (
            <span>&#x25BC;</span>
          ) : (
            <i className={`fas fa-chevron-${arrowPosition === 'right' ? 'right' : 'down'}`}></i>
          )}
        </span>
      </button>
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <ul className="py-2">
            {values.map((value, index) => (
              <li key={index} className="px-4 py-2 cursor-pointer hover:bg-gray-100">
                {value}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

Dropdown1.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  design: PropTypes.oneOf(['solid', 'plain', 'outline']),
  arrowStyle: PropTypes.oneOf(['plain', 'partition']),
  arrowPosition: PropTypes.oneOf(['left', 'right'])
};

Dropdown1.defaultProps = {
  values: [],
  design: 'solid',
  arrowStyle: 'plain',
  arrowPosition: 'right'
};

export default Dropdown1;
