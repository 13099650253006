import React, { useContext, useState } from 'react';
import InputField from '../../../../../../../components/InputFields/InputField';
import { StepperContext } from '../../../../../../../providers/stepper.provider';
import { formatPhoneNumber } from '../../../../../../../utils/utils';
import { PhoneNumberContext } from '../../../../../../../providers/phoneNumber.provider';
import { AuthContext } from '../../../../../../../providers/auth.provider';
import { useMutation } from '@apollo/client';
import { REQUEST_TOKEN } from '../../../../../../../GraphQL/Mutations';
import { CgSpinner } from 'react-icons/cg';
import { LeadContext } from '../../../../../../../providers/lead.provider';
import { leadEventPixel } from '../../../../../../../utils/facebookPixelEvents';

const OtpVerificationStep = ({ onNext, onPrevious }) => {
  const [OTP, setOTP] = useState('');
  const [isInvalidOtp, setIsInvalidOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);

  const {
    setIsOtpSent,
    setResendDisabled,
    resendDisabled,
    startTimer,
    countdown,
    setCountdown,
    setSendOTPCounter,
    sendOTPCounter,
    setPhoneNumber,
    phoneNumber,
    recaptchaContainerRef,
    destroyRecaptchaVerifier,
    onSignup,
    setIsOtpVerified
  } = useContext(PhoneNumberContext);
  const { setUser } = useContext(AuthContext);
  const { addLeadStep } = useContext(LeadContext);

  const { solarInquiryData, setSolarInquiryData, isProduction } = useContext(StepperContext);

  const [requestAuthToken] = useMutation(REQUEST_TOKEN);

  const handleResendOTP = async () => {
    setResendDisabled(true);
    setIsInvalidOtp(false);
    setCountdown(20);
    await destroyRecaptchaVerifier();
    onSignup();
  };

  const onOTPVerify = (e) => {
    e.preventDefault();
    if (OTP.length === 6) {
      setVerificationLoading(true);
      window.confirmationResult
        .confirm(OTP)
        .then(async (res) => {
          console.log(res);
          setUser(res.user);

          // updateUserDetail({ userPhoneNumber: '+' + ph, userPhoneOTP: otp });
          // logOut();

          if (res && res.user) {
            console.log('res :', res);
            try {
              const authTokenResponse = await requestAuthToken({
                variables: {
                  input: {
                    firebase: {
                      idToken: res._tokenResponse.idToken
                    }
                  }
                }
              });

              if (authTokenResponse) {
                // console.log("authTokenResponse :", authTokenResponse);
                // setScreenType("firebaseSocialLogin");
                // setIsUserRegisterd(true);
                setVerificationLoading(false);

                await addLeadStep({ phoneNumber }, 'verifiedPhoneNumberStep');
                if (isProduction) {
                  await leadEventPixel({ customerData: solarInquiryData.formatedAddress });
                }
                setLoading(false);
                onNext();
                setIsOtpVerified(true);
              }
            } catch (error) {
              console.log(error);
              setLoading(false);
              // toast.error(error.message);
              // firebaseLogout();
              // setScreenType(null);
              // setIsUserRegisterd(false);
              // setLoginProviderName(null);
              setVerificationLoading(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.code);
          setLoading(false);
          const logData = {
            url: 'https://firebase.com',
            method: 'GET',
            headers: [],
            payload: { phoneNumber: '+' + phoneNumber, userPhoneOTP: OTP },
            response_status: 400,
            response_headers: '[]',
            response_body: err && err.message ? err.message : '',
            client: 'Web',
            client_os: navigator.platform ? navigator.platform : 'browser'
          };
          // createLog(logData);
          setVerificationLoading(false);
          switch (err.code) {
            case 'auth/invalid-verification-code':
              // toast.error("Invalid verification code");
              setIsInvalidOtp(true);
              break;
            default:
          }
        });
    }
  };

  return (
    // <div className="sm:ml-[130px] ml-[25px] mr-[25px] sm:h-[60vh] h-auto">
    <div className="xl:ml-[130px] sm:ml-[50px] ml-[25px] mr-[25px] h-[60vh]">
      <fieldset>
        <div className="mb-[40px] ">
          <h3 className="sm:text-[32px] text-[24px] leading-[40px] text-primary_black font-forma-djr-text-regular mb-[20px]">
            Enter the verification code
          </h3>

          <p className="text-primary_black text-[14px] text-left justify-start md:text-[18px] lg:text-[24px] lg:leading-[32px] leading-1 mb-[16px]">
            We want to verify it’s you and not some robot. An OTP code has been sent to{' '}
            {solarInquiryData.phoneNumber &&
              formatPhoneNumber(solarInquiryData.phoneNumber.replace('+', ''))}
            , please kindly enter it below.
          </p>

          <div className="mt-8 p-2  w-full items-center justify-center text-center">
            <div>
              <InputField
                type="text"
                placeholder={'Enter OTP'}
                className={{
                  inputClasses:
                    'text-lg !border-tertiary focus:!border-tertiary [appearance:textfield]',
                  labelClasses: 'font-forma-djr-text-regular'
                }}
                name={'otp'}
                variants={'static'}
                max={6}
                positiveInteger={true}
                value={OTP}
                onChange={(e) => {
                  setOTP(e.currentTarget.value);
                  setIsInvalidOtp(false);
                }}
              />
            </div>

            {isInvalidOtp && (
              <p className="mt-[10px]  sm:text-[16px] font-aeonik-bold text-[11px] leading-[16px] tracking-[0.1px] text-[#FF6161]">
                Wrong code, please try again.
              </p>
            )}
          </div>
        </div>
        <div className="text-left ml-[5px] my-8">
          <button
            className="flex items-center bg-primary font-aeonik-bold py-3 px-14 text-secondary_white rounded-md text-lg border-primary cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
            onClick={onOTPVerify}
            disabled={!(OTP && OTP.length === 6 && !verificationLoading)}>
            {verificationLoading && resendDisabled && (
              <CgSpinner size={20} className="mr-3 animate-spin items-center" />
            )}
            Next
          </button>
        </div>
        <div className="sm:mr-4 sm:mt-[40px] mt-[18px] sm:text-left text-center">
          <span className="ml-2 sm:text-[16px] text-[12px] leading-[16px] tracking-[0.1px] text-primary forma-djr-mono-regular">
            Didn’t receive the code?{' '}
            {resendDisabled ? (
              <span className="text-primary">Resend OTP in {countdown} seconds</span>
            ) : (
              <button
                disabled={!sendOTPCounter}
                onClick={handleResendOTP}
                className="text-primary font-aeonik-bold underline text-left disabled:opacity-75 disabled:cursor-not-allowed">
                Resend OTP
              </button>
            )}
          </span>
          <br />
          {/* <span className="ml-2 sm:text-[16px] text-[12px] leading-[16px] tracking-[0.1px] text-primary font-aeonik-bold ">
            Use another <u onClick={() => setIsOtpSent(false)}>Phone number?</u>
          </span> */}
        </div>
      </fieldset>
    </div>
  );
};

export default OtpVerificationStep;
