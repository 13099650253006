import React from 'react';
import RedirectToAppStoreAndPlayStore from '../../../../../../../components/RedirectToAppStoreAndPlayStore/RedirectToAppStoreAndPlayStore';
import testFlightQR from '../../../../../../../assets/images/daylight-testflight-qr.png';
import liveAppQR from '../../../../../../../assets/images/daylight-app-qr.png';

const CURRENT_ENV = process.env.REACT_APP_NODE_ENV;

const AppStoreAndPlayStoreStep = () => {
  const isProduction = CURRENT_ENV === 'production';
  return (
    <div className="xl:ml-[130px] sm:ml-[50px] ml-[25px] mr-[25px] h-[60vh]">
      <div className="mb-[40px] sm:w-[90%] w-full ">
        <h3 className="sm:text-[32px] text-[24px] leading-[40px] text-primary_black font-forma-djr-text-regular mb-[20px]">
          Our team will reach out directly to schedule your energy inspection.
        </h3>
        <p className="text-primary text-[18px] sm:text-[24px] leading-1 font-forma-djr-text-regular sm:leading-[32px] py-0 mb-[14px] ml-[5px]">
          In the meantime, download the Daylight app to unlock energy insights and tips.
        </p>
      </div>
      <div className="flex justify-start items-center m-auto mt-8 p-2">
        <div className="bg-primary rounded-md max-w-[126px] p-2">
          <img src={isProduction ? liveAppQR : testFlightQR} alt="" />
        </div>

        {/* <div className="ml-[19px]">
          <RedirectToAppStoreAndPlayStore />
        </div> */}
      </div>
    </div>
  );
};

export default AppStoreAndPlayStoreStep;
