import React, { useContext, useState } from 'react';
import InputField from '../../../../../../../components/InputFields/InputField';
import { StepperContext } from '../../../../../../../providers/stepper.provider';
import { validateEmail } from '../../../../../../../utils/utils';
import { LeadContext } from '../../../../../../../providers/lead.provider';

const ServiceNotAvailableStep = () => {
  const [leadEmail, setLeadEmail] = useState('');
  const { setShowAppPage } = useContext(StepperContext);
  const { addLeadStep } = useContext(LeadContext);

  return (
    <div className="sm:ml-[130px] ml-[25px] mr-[25px]">
      <fieldset>
        <div className="mb-[40px] ">
          <h3 className="sm:text-[32px] text-[24px] leading-[40px] text-primary_black font-forma-djr-text-regular mb-[20px]">
            We&apos;re sorry, but Daylight is only available in New Jersey, Florida, Pennsylvania,
            New York and Texas markets at this time.
          </h3>

          <p className="text-primary_black text-[14px] text-left justify-start md:text-[18px] lg:text-[24px] lg:leading-[32px] leading-1 mb-[16px]">
            We&apos;re rapidly expanding to new states. To find out when Daylight is available in
            your area please enter your email below:
          </p>

          <div className=" py-2 p-1 w-full items-center justify-center text-center">
            <InputField
              type="email"
              placeholder={'name@example.com'}
              className={{ inputClasses: 'text-lg', labelClasses: 'font-forma-djr-text-regular' }}
              name={'leadEmail'}
              variants={'static'}
              value={leadEmail}
              onChange={(e) => setLeadEmail(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className="text-left ml-[5px] my-8">
          <button
            className="bg-primary font-forma-djr-text-regular py-3 px-14 text-secondary_white rounded-md text-lg border-primary cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
            disabled={!validateEmail(leadEmail)}
            onClick={async () => {
              const leadRes = await addLeadStep(
                { email: leadEmail },
                'notBlongsToActiveServiceStatesStep'
              );

              if (leadRes) {
                // setTimeout(() => {
                //   redirectToWebsite();
                // }, 2000);
                setShowAppPage(true);
              }
            }}>
            Next
          </button>
        </div>
      </fieldset>
    </div>
  );
};

export default ServiceNotAvailableStep;
