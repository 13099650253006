import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StepperProvider } from './providers/stepper.provider';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { AuthProvider } from './providers/auth.provider';
import TagManager from 'react-gtm-module';
import { PopoverProvider } from './providers/popover.provider';
import { LeadProvider } from './providers/lead.provider';
import { PhoneNumberProvider } from './providers/phoneNumber.provider';
import { StripeProvider } from './providers/stripe.provider';

const CURRENT_ENV = process.env.REACT_APP_NODE_ENV;

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPH_QL_BASE_URL,
  cache: new InMemoryCache()
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  dataLayerName: 'PageDataLayer'
};
const isProduction = CURRENT_ENV === 'production';
if (isProduction) {
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AuthProvider>
          <StepperProvider>
            <PopoverProvider>
              <LeadProvider>
                <PhoneNumberProvider>
                  <StripeProvider>
                    <App />
                  </StripeProvider>
                </PhoneNumberProvider>
              </LeadProvider>
            </PopoverProvider>
          </StepperProvider>
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
