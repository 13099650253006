import { klaviyoApi } from '../klaviyo/klaviyo';

const publicKey = process.env.REACT_APP_KLAVIYO_PUBLIC_KEY;

export const klaviyoSkipUtilityAccount = async (email, phoneNumber) => {
  const eventData = {
    token: publicKey,
    event: 'SolarFlow_Skip_Utility_Account',
    customer_properties: { $email: email },
    properties: { phoneNumber }
  };

  klaviyoApi
    .post('/track', new URLSearchParams({ data: JSON.stringify(eventData) }))
    .then((response) => {
      console.log('SolarFlow_Skip_Utility_Account event tracked successfully:', response.data);
    })
    .catch((error) => {
      console.error('Error tracking event:', error);
    });
};

export const klaviyoUtilityAccountConnected = async (email, phoneNumber) => {
  const eventData = {
    token: publicKey,
    event: 'SolarFlow_Utility_Account_Connected',
    customer_properties: { $email: email },
    properties: { phoneNumber }
  };

  klaviyoApi
    .post('/track', new URLSearchParams({ data: JSON.stringify(eventData) }))
    .then((response) => {
      console.log('SolarFlow_Utility_Account_Connected event tracked successfully:', response.data);
    })
    .catch((error) => {
      console.error('Error tracking event:', error);
    });
};

export const klaviyoSocialLogin = async (email, provider, phoneNumber) => {
  const eventData = {
    token: publicKey,
    event: 'Social Login',
    customer_properties: { $email: email },
    properties: { provider, phoneNumber }
  };

  klaviyoApi
    .post('/track', new URLSearchParams({ data: JSON.stringify(eventData) }))
    .then((response) => {
      console.log('Event tracked successfully:', response.data);
    })
    .catch((error) => {
      console.error('Error tracking event:', error);
    });
};

export const klaviyoClickOnChatWithOurConciergeButton = (email, payload, phoneNumber) => {
  const eventData = {
    token: publicKey,
    event: 'SolarFlow_Scheduled_With_Our_Concierge',
    customer_properties: { $email: email },
    properties: { payload, phoneNumber }
  };

  klaviyoApi
    .post('/track', new URLSearchParams({ data: JSON.stringify(eventData) }))
    .then((response) => {
      console.log(
        'SolarFlow_Scheduled_With_Our_Concierge event tracked successfully:',
        response.data
      );
    })
    .catch((error) => {
      console.error('Error tracking event:', error);
    });
};
