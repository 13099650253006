import React, { useContext } from 'react';
import RadioButtonTab from '../../../../../../../components/RadioButton/RadioButtonTab';
import { StepperContext } from '../../../../../../../providers/stepper.provider';
import { PropertyType } from '../../../../../../../GraphQL/Types';
import { LeadContext } from '../../../../../../../providers/lead.provider';
import { enterPropertyTypeEventPixel } from '../../../../../../../utils/facebookPixelEvents';

const HomeOwnerStep = ({ onNext, onPrevious }) => {
  const { solarInquiryData, setSolarInquiryData, setIsRentedProperty, isProduction } =
    useContext(StepperContext);
  const { addLeadStep } = useContext(LeadContext);

  return (
    // <div className="sm:ml-[130px] ml-[25px] mr-[25px]  h-[60vh]">
    <div className="xl:ml-[130px] sm:ml-[50px] ml-[25px] mr-[25px] h-[60vh]">
      <fieldset>
        <div className="mb-[40px] ">
          <h3 className="sm:text-[32px] text-[24px] leading-[40px] text-primary_black font-forma-djr-text-regular mb-[20px]">
            Do you own or rent your home?
          </h3>

          <div className="p-1 max-w-[188px]">
            <div className="p-2">
              <ul className="">
                <li className="my-5">
                  <RadioButtonTab
                    id="property-type-own"
                    value={PropertyType.Own}
                    onChange={(e) =>
                      setSolarInquiryData({
                        ...solarInquiryData,
                        onHome: e.currentTarget.value
                      })
                    }
                    name="propertyType"
                    checked={solarInquiryData.onHome === PropertyType.Own}
                    label="Own"
                    className={
                      'h-[50px] justify-start peer-checked:border-solid !rounded-none border-[1px] dark:peer-checked:text-primary text-primary bg-white peer-checked:bg-secondary_white dark:bg-white dark:text-primary relative'
                    }
                    showIcon={true}
                    index={0}
                    labelPrefix={true}
                  />
                </li>
                <li className="my-5">
                  <RadioButtonTab
                    id="property-type-rent"
                    value={PropertyType.Rent}
                    onChange={(e) =>
                      setSolarInquiryData({
                        ...solarInquiryData,
                        onHome: e.currentTarget.value
                      })
                    }
                    name="propertyType"
                    checked={solarInquiryData.onHome === PropertyType.Rent}
                    label="Rent"
                    className={
                      'h-[50px] justify-start peer-checked:border-solid border-[1px] !rounded-none dark:peer-checked:text-primary text-primary bg-white peer-checked:bg-secondary_white dark:bg-white dark:text-primary  relative'
                    }
                    showIcon={true}
                    index={1}
                    labelPrefix={true}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-left ml-[5px] my-8">
          <button
            className="bg-primary font-forma-djr-text-regular py-3 px-14 text-secondary_white rounded-md text-lg border-primary cursor-pointer disabled:cursor-not-allowed disabled:bg-neutral disabled:text-gray-500 hover:opacity-80"
            disabled={!solarInquiryData.onHome}
            onClick={async () => {
              if (isProduction) {
                await enterPropertyTypeEventPixel({
                  propertyType: solarInquiryData.onHome
                });
              }
              await addLeadStep({ propertyType: solarInquiryData.onHome }, 'propertyTypeStep');

              if (solarInquiryData.onHome === PropertyType.Rent) {
                setIsRentedProperty(true);
              } else {
                onNext();
              }
            }}>
            Next
          </button>
        </div>
      </fieldset>
    </div>
  );
};

export default HomeOwnerStep;
