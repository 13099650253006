import { useMutation } from '@apollo/client';
import { createContext, useEffect, useState } from 'react';
import {
  ADD_LEAD_STEP_MUTATION,
  CREATE_LEAD_MUTATION,
  USER_LEAD_UPDATE_MUTATION
} from '../GraphQL/Mutations';
import { LeadSource, LeadTag, LeadType } from '../GraphQL/Types';
import { load, getAgent } from '@fingerprintjs/fingerprintjs';
import axios from 'axios';

const CURRENT_ENV = process.env.REACT_APP_NODE_ENV;

export const LeadContext = createContext();

export const LeadProvider = ({ children }) => {
  const isProduction = CURRENT_ENV === 'production';
  const urlParams = new URLSearchParams(location.search);
  const stateData = JSON.parse(urlParams.get('state'));

  const [leadId, setLeadId] = useState(null);
  const [userIpAddress, setUserIpAddress] = useState('');

  // Mutations
  const [createLeadMutaion] = useMutation(CREATE_LEAD_MUTATION);
  const [addLeadStepMutaion] = useMutation(ADD_LEAD_STEP_MUTATION);
  const [updateUserLeadMutaion] = useMutation(USER_LEAD_UPDATE_MUTATION);

  const addLeadStep = async (jsonData, step, leadIdd = leadId) => {
    if (leadIdd) {
      //   console.log('addlead input :', { leadId, jsonData, step });
      try {
        const addLeadStepRes = await addLeadStepMutaion({
          variables: {
            input: {
              leadId: leadIdd,
              step,
              data: JSON.stringify(jsonData || '')
            }
          }
        });
        if (addLeadStepRes) {
          console.log('add lead :', addLeadStepRes);
          return addLeadStepRes;
        }
        return false;
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log('leadId is undefined');
    }
  };

  const updateUserLead = async (authToken, markComplete = false) => {
    if (leadId) {
      try {
        const updateUserLeadRes = await updateUserLeadMutaion({
          context: {
            headers: {
              Authorization: `Bearer ${authToken.token}`
            }
          },
          variables: {
            input: {
              leadId,
              markComplete
            }
          }
        });

        if (updateUserLeadRes) {
          //   console.log('add lead :', updateUserLeadRes);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log('leadId is undefined');
    }
  };

  const createLead = async (ipAddress, browserFingerprint, referrer) => {
    console.log('lead Input :', { ipAddress, browserFingerprint, referrer });
    if (!stateData || !stateData.leadId) {
      console.log('create call');
      try {
        const createLeadRes = await createLeadMutaion({
          variables: {
            input: {
              leadType: LeadType.SOLAR,
              browserFingerprint,
              referrer,
              ipAddress,
              tag: LeadTag.HIGH_INTENT_SOLAR
            }
          }
        });
        if (createLeadRes) {
          // console.log('leadId :', createLeadRes);
          setLeadId(createLeadRes.data.createLead.lead.id);
        }
        return createLeadRes;
      } catch (error) {
        console.log(error);
      }
    } else {
      stateData.leadId && setLeadId(stateData.leadId);
      return { data: { createLead: { lead: { id: stateData.leadId } } } };
    }
  };

  const generateLeadId = async () => {
    const referrer = document.referrer;
    let browserFingerprint;
    let ipAddress;

    // eslint-disable-next-line no-async-promise-executor
    const captureFingerprintPromise = new Promise(async (resolve) => {
      try {
        const agent = await load();
        const result = await agent.get();

        browserFingerprint = result.visitorId;
        resolve();
      } catch (error) {
        console.error(error);
        resolve(); // Resolve the promise even if there's an error
      }
    });

    const captureIpAddressPromise = new Promise((resolve, reject) => {
      axios
        .get('https://api.ipify.org?format=json')
        .then((response) => {
          setUserIpAddress(response.data.ip);
          ipAddress = response.data.ip;
          resolve();
        })
        .catch((error) => {
          console.error('Error fetching IP address:', error);
          resolve();
        });
    });

    return Promise.all([captureIpAddressPromise, captureFingerprintPromise])
      .then(() => {
        // Both requests are completed, call createLead
        return createLead(ipAddress, browserFingerprint, referrer);
      })
      .catch(() => {
        console.error('Error capturing IP address or fingerprint');
        // Call createLead even if there's an error
        return createLead(ipAddress, browserFingerprint, referrer);
      });
  };

  // useEffect(() => {
  //   // let isMounted = true;
  //   // console.log('stateData :', stateData);
  //   const referrer = document.referrer;
  //   let browserFingerprint;
  //   let ipAddress;

  //   const createLead = async (ipAddress, browserFingerprint, referrer) => {
  //     console.log('lead Input :', { ipAddress, browserFingerprint, referrer });
  //     if (!stateData || !stateData.leadId) {
  //       console.log('create call');
  //       try {
  //         const createLeadRes = await createLeadMutaion({
  //           variables: {
  //             input: {
  //               leadType: LeadType.SOLAR,
  //               browserFingerprint,
  //               referrer,
  //               ipAddress
  //             }
  //           }
  //         });
  //         if (createLeadRes) {
  //           // console.log('leadId :', createLeadRes);
  //           setLeadId(createLeadRes.data.createLead.lead.id);
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     } else {
  //       // console.log('dddooo');
  //       stateData.leadId && setLeadId(stateData.leadId);
  //     }
  //   };

  //   // eslint-disable-next-line no-async-promise-executor
  //   const captureFingerprintPromise = new Promise(async (resolve) => {
  //     try {
  //       const agent = await load();
  //       const result = await agent.get();

  //       browserFingerprint = result.visitorId;
  //       resolve();
  //     } catch (error) {
  //       console.error(error);
  //       resolve(); // Resolve the promise even if there's an error
  //     }
  //   });

  //   const captureIpAddressPromise = new Promise((resolve, reject) => {
  //     axios
  //       .get('https://api.ipify.org?format=json')
  //       .then((response) => {
  //         setUserIpAddress(response.data.ip);
  //         ipAddress = response.data.ip;
  //         resolve();
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching IP address:', error);
  //         resolve();
  //       });
  //   });

  //   Promise.all([captureIpAddressPromise, captureFingerprintPromise])
  //     .then(() => {
  //       // Both requests are completed, call createLead
  //       createLead(ipAddress, browserFingerprint, referrer);
  //     })
  //     .catch(() => {
  //       console.error('Error capturing IP address or fingerprint');
  //       // Call createLead even if there's an error
  //       createLead(ipAddress, browserFingerprint, referrer);
  //     });

  //   return () => {
  //     //   isMounted = false;
  //     setLeadId(null);
  //   };
  // }, []);

  //   useEffect(() => {
  //     const referrer = document.referrer;
  //     if (leadId) {
  //       const captureFingerprint = async () => {
  //         try {
  //           const agent = await load();
  //           const result = await agent.get();

  //           const browserFingerprint = result.visitorId;
  //           //   console.log('Browser Fingerprint:', browserFingerprint);

  //           await addLeadStep({ browserFingerprint, referrer }, 'initialStep');
  //         } catch (error) {
  //           console.error(error);
  //         }
  //       };
  //       // if (isProduction) {
  //       captureFingerprint();
  //       // }
  //     }
  //   }, [leadId]);

  return (
    <LeadContext.Provider
      value={{
        setLeadId,
        leadId,
        addLeadStep,
        updateUserLead,
        generateLeadId
        // createLead
      }}>
      {children}
    </LeadContext.Provider>
  );
};
